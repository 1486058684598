import React from 'react';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';

import user from '@/api/user';

import { selectSignInState } from '@/store/selectors/auth';


export const withAuth = (
  fallback: JSX.Element | null = <Redirect to="/login" />,
) =>
  <P extends object>(
    Component: React.ComponentType<P>,
  ): React.FC<P> => (props) => {
    const isLoggedIn = user.isLoggedIn();
    // @ts-ignore DO NOT DELETE, WE NEED THIS LINE FOR RERENDER DEPENDENT COMPONENTS AFTER SIGN IN / OUT
    const signInState = useSelector(selectSignInState);
    if (isLoggedIn) return <Component {...props} />;

    return fallback;
  };

withAuth(null);

