import styled from 'styled-components';

import { Button } from '@zero5/ui';

const ButtonStatus = styled(Button)<{ textColor: string; background: string; }>`
  color: ${({ textColor }) => textColor};
  position: relative;
  padding-left: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: none;
  display: flex;
  min-width: 85px;
  min-height: 35px;
  align-items: center;
  margin-right: 10px;
  background: ${({ background }) => background};
  font-style: normal;
  .MuiButton-label {
    color: ${({ textColor }) => textColor};
}
`;

export default ButtonStatus;
