// /* eslint-disable max-len */

import { useMutation, UseMutationOptions } from 'react-query';

import {
  getAggregatedTransactionsDetailsCsvReport,
} from '@zero5/payment-api';


type Response = string;
type Params = {
  from: number;
  until: number;
  aggregationPeriod?: 'daily' | 'weekly';
};

const useAggregatedTransactionsDetailsReportCsvMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  return useMutation(
    'getAggregatedTransactionsDetailsReportCsv',
    async (params) => {
      const response = await getAggregatedTransactionsDetailsCsvReport(params);

      return response.report;
    },
    options,
  );
};

export default useAggregatedTransactionsDetailsReportCsvMutation;
