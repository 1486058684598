import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { updatePermitType, UpdatePermitTypeArgs } from '@zero5/permit-api';

import { handleError } from '@/utils/handleError';

import { mapPermitType } from '../permit/mappings';

import { queryKey as permitListQuery } from './useGetPermitTypeListQuery';

type Response = ReturnType<typeof mapPermitType>;
type Params = {
  id: number;
  permitType: UpdatePermitTypeArgs;
};

export const queryKey = 'createPermitType';

const useUpdatePermitTypeMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    queryKey,
    async ({ id, permitType }) => {
      const response = await updatePermitType(id, permitType);

      return mapPermitType(response.permitType);
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(permitListQuery);
      },
      onError: (error) => handleError(error),
    },
  );
};

export default useUpdatePermitTypeMutation;
