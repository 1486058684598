import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import useToggle from '@zero5/ui/lib/utils/hooks/useToggle';
import { Button } from '@zero5/ui';

import { selectAcceptingCallId, selectCallsQueue, selectIsAccepting } from '@controlCenter/store/selectors/call';
import { acceptCall } from '@controlCenter/store/actions/call';
import Badge from '@controlCenter/components/common/Badge';
import PhoneImage from '@controlCenter/assets/icons/phone.svg';
import { callSound } from '@/controlCenter/utils/callSound';

interface IncomingCallProps {
  text: string;
  onAccept: () => void;
  isLoading?: boolean;
  disabled?: boolean;
}

const IncomingCall: React.FC<IncomingCallProps> = ({
  text,
  onAccept,
  isLoading = false,
  disabled = false,
}) => {
  return (
    <IncomingCallWrapper>
      <IncomingCallText>
        {text}
      </IncomingCallText>
      <AcceptButton
        color="primary"
        variant="contained"
        onClick={onAccept}
        loading={isLoading}
        disabled={disabled}
      >
        answer
      </AcceptButton>
    </IncomingCallWrapper>
  );
};

const IncomingCallWrapper = styled.li`
  display: flex;
  align-items: center;
  padding: 10px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #ffffff;
  }
`;

const IncomingCallText = styled.div`
  flex-grow: 1;
  max-width: 230px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  overflow-x: hidden;
  text-transform: uppercase;
  text-align: center;
  text-overflow: ellipsis;
`;

interface Props {
  className?: string;
}

const CallsQueue: React.FC<Props> = ({ className, ...props }) => {
  const dispatch = useDispatch();

  const [isOpen, , , toggle] = useToggle(false);

  const callsQueue = useSelector(selectCallsQueue);
  const isAccepting = useSelector(selectIsAccepting);
  const acceptingCallId = useSelector(selectAcceptingCallId);

  const acceptCallHandler = React.useCallback(
    async (idx: number) => {
      await dispatch(acceptCall(callsQueue[idx]));
    },
    [dispatch, callsQueue],
  );

  React.useEffect(() => {
    callSound.play();

    return () => {
      callSound.stop();
    };
  }, []);

  return (
    <Wrapper className={className} {...props}>
      <InnerWrapper>
        <Badge badgeContent={callsQueue.length} color="secondary">
          <StyledPhoneImage src={PhoneImage} alt="phone" />
        </Badge>
        <IncomingCallsTitle>
          INCOMING CALLS
        </IncomingCallsTitle>
        <ToggleButton color="inherit" onClick={toggle}>
          {isOpen ? 'HIDE' : 'SEE MORE'}
        </ToggleButton>
      </InnerWrapper>
      {isOpen && (
        <Queue>
          {callsQueue.map(
            ({ requestId, deviceId }, idx) => (
              <IncomingCall
                text={deviceId || 'Integrated App'}
                onAccept={() => acceptCallHandler(idx)}
                isLoading={isAccepting && acceptingCallId === requestId}
                disabled={isAccepting && acceptingCallId !== requestId}
                key={requestId}
              />
            ),
          )}
        </Queue>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  border: 3px solid #FF0000;
  border-radius: 4px;
  background-color: #6C6C6C;
  color: #ffffff;
  z-index: 10000;
  cursor: move;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  height: 100%;
  margin: 0 auto;
  padding: 10px 20px;
`;

const StyledPhoneImage = styled.img`
  user-select: none;
  pointer-events: none;
`;

const IncomingCallsTitle = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
`;

const ToggleButton = styled(Button)`
  width: 110px;
  color: #ffffff;
  text-decoration: underline;
  font-size: 15px;
  line-height: 20px;
`;

const Queue = styled.ul`
  max-height: 394px;
  padding: 0 10px;
  margin: 0;
  background-color: #C4C4C4;
  overflow-y: auto;
`;

const AcceptButton = styled(Button)`
  width: 120px;
`;

export default CallsQueue;
