import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { updateEntryActivity } from '@zero5/client-admin-api';

import { loadImage } from '@/utils/image';

import { queryKey as unresolvedCountQueryKey } from '@/controlCenter/api/activity/useGetUnresolvedIssues';

import { Activity } from './models';
import { mapUpdatedIssueActivity } from './mappings';
import { queryKey as getIssueActivitiesQueryKey } from './useGetIssueActivitiesQuery';


type Response = ReturnType<typeof mapUpdatedIssueActivity>;
type Params = {
  activityId: string;
  data: Omit<Partial<Activity>, 'activityId'>;
};

const useUpdateEntryActivityMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'updateEntryActivity',
    async (params) => {
      const response = await updateEntryActivity(params);

      const mappedActivity = mapUpdatedIssueActivity(response.entryActivity);

      loadImage(mappedActivity.frontImg || '');
      loadImage(mappedActivity.rearImg || '');

      return mappedActivity;
    },
    {
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries(getIssueActivitiesQueryKey);
        queryClient.invalidateQueries(unresolvedCountQueryKey);

        options?.onSuccess?.(...params);
      },
    },
  );
};

export default useUpdateEntryActivityMutation;
