import React from 'react';
import styled from 'styled-components';

import { Button, Input, InputProps } from '@zero5/ui';

import { ValidationType } from './ValidationContent';

interface Props{
  validationType: ValidationType;
  searchInput: string;
  setSearchInputHandler: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleEnterClick: () => void;
  emailError: boolean;
}

const ValidationInputContent: React.FC<Props> = ({
  validationType,
  searchInput,
  emailError,
  setSearchInputHandler,
  handleEnterClick,
}) => {
  const text = validationType === 'Parking'
    ? (
      <Text>
        Enter the license plate number or the email address to send a QR code
      </Text>
    )
    : (
      <Text>
        Enter the license plate number, key tag number,
        or a 6 digit code that was generated with a QR code.
        <br />
        If the guest doesn’t have the information,
        please enter the email address to send a QR code
      </Text>
    );

  return (
    <>
      {text}
      <ValidationInput
        errorBorderColor={emailError}
        error={emailError}
        type="text"
        helperText="Email must be valid or put license plate number"
        placeholder="Type here"
        onChange={(event) => setSearchInputHandler(event)}
      />
      <EnterButton
        variant="contained"
        color="primary"
        disabled={searchInput === ''}
        onClick={handleEnterClick}
      >

        Enter
      </EnterButton>
    </>
  );
};

type ValidationInputProps = { errorBorderColor: boolean; };

const ValidationInput = styled(Input)<ValidationInputProps>`
input {
  ${({ errorBorderColor }) => !errorBorderColor && 'border:none;'};;
  background: #F8F9FA;
  width: 335px;
  height:50px;
  text-align: center;
}
 ::placeholder {
    color: #ACB3BF;
  }
  margin-bottom: 35px;
` as React.FC<InputProps & ValidationInputProps>;

const EnterButton = styled(Button)`
  width: 335px;
  height: 50px;
`;

const Text = styled.div<{ size?: string; }>`
  font-weight: 300;
  font-size: ${({ size }) => size || '14px'};
  line-height: 1.375;
  color: #181818;
  margin: 33px auto 30px;
`;

export default ValidationInputContent;
