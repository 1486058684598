import styled from 'styled-components';

import { Row, Table } from '@zero5/ui';

export const TopLevelTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`;

export const MediumLevelTitle = styled.div`
  font-weight: normal;
`;

export const BigText = styled.div`
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
`;

export const RowWithOffset = styled(Row)`
  margin-bottom: 20px;
`;

export const GarageNameRow = styled(RowWithOffset)`
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

export const SummarySection = styled.div`
  padding: 15px 20px;
  background: #E7E5E5;
  border-radius: 5px;
`;

export const TableHiddenScroll = styled.div`
  width: 100%;
  overflow-x: scroll;
  &::-webkit-scrollbar { width: 0; };
`;

export const TransactionsTable = styled(Table)`
  & thead.MuiTableHead-root span {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }
  
  & thead tr {
    background: #E7E5E5;
  }
  
  & tr:nth-of-type(2n) {
    background: #E7E5E5;
  }
`;
