/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getPermitTypes } from '@zero5/permit-api';

import { sortByWeekdays } from '@/utils/date/sortByWeekday';

import { mapPermitTypes } from '../permit/mappings';

type Response = ReturnType<typeof mapPermitTypes>;

export const queryKey: QueryKey = 'permitTypeList';

const usePermitTypeListQuery = (
  options?: UseQueryOptions <Response, unknown, Response, typeof queryKey>,
) => {
  return useQuery<Response, unknown, Response, typeof queryKey>(
    queryKey,
    async () => {
      const response = await getPermitTypes();

      return mapPermitTypes(response.permitTypes).map((permitType) => ({
        ...permitType,
        permitPolicies: sortByWeekdays(permitType.schedule, (item) => item.day),
      }));
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default usePermitTypeListQuery;
