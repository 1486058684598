import React from 'react';

import TimePickerLib, { Props } from '@zero5/ui/lib/SelectTimePicker';

import useDateModule from '@/utils/date/useDateModule';

const SelectTimePicker: React.FC<Props> = (props) => {
  const { z5DateModule } = useDateModule();

  return (
    <TimePickerLib
      timezone={z5DateModule.getTimezone()}
      {...props}
    />
  );
};

export default SelectTimePicker;
