import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Input, Button, LoadingIndicator, InputPhone,
} from '@zero5/ui';
// import schemas from '@zero5/ui/lib/utils/validation/schemas';

import useSelfUserQuery from '@/api/queries/useSelfUserQuery';
import useCompanyUsersQuery from '@/api/queries/useCompanyUsersQuery';
import useUpdateCurrentUserMutation from '@/api/mutations/useUpdateCurrentUserMutation';

import ChangePasswordModal from '@/components/modals/changePasswordModal';

import { regexPhoneNumber, regexWords } from '@/utils/regex';
import { handleError } from '@/utils/handleError';

import TableHead from './common/TableHead';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required()
    .label('First Name')
    .min(3, 'Too Short!')
    .matches(regexWords, 'Please enter valid first name')
    .max(35, 'Too Long!'),
  // firstName: schemas.nameSchema.label('First Name'),
  lastName: Yup.string()
    .required()
    .label('Last Name')
    .min(3, 'Too Short!')
    .matches(regexWords, 'Please enter valid last name')
    .max(35, 'Too Long!'),
  // lastName: schemas.nameSchema.label('Last Name'),
  phone: Yup.string()
    .required()
    .label('Phone')
    .matches(regexPhoneNumber, 'Phone number should contain 10 digits and plus sign')
    .max(20, 'Too Long!'),
  // phone: schemas.phoneSchema,
});

const MyAccountSection: React.FC = () => {
  const [isPassChangeModalOpen, setIsPassChangeModalOpen] = useState<boolean>(false);

  const companyUsers = useCompanyUsersQuery();
  const {
    data: meData, isLoading: isUserLoading,
  } = useSelfUserQuery();

  const userData = useMemo(() => {
    const myUser = companyUsers.data?.find((user) => user.userId === meData?.user.userId);
    return myUser;
  }, [companyUsers.data, meData]);

  const updateUserMutation = useUpdateCurrentUserMutation({
    onError: (error) => handleError(error, 'Error while updating user!'),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      email: userData?.email,
      phone: userData?.phone,
    },
    onSubmit: async ({
      firstName,
      lastName,
      email,
      phone,
    }) => {
      if (userData) {
        await updateUserMutation.mutateAsync({
          firstName,
          lastName,
          email,
          phone,
        });
      }
    },
    validationSchema,
  });

  const transformedInputChangeHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => {
      formik.setFieldValue(e.target.name, value);
    }, [formik],
  );

  return (
    <>
      {(isUserLoading || companyUsers.isLoading) ? (
        <StyledLoadingIndicator />
      ) : (
        <WrapperContainer>
          <StyledTableHead title="Account Information" />
          {/* <Grid>
            {qr !== '' ? (<QRCode size={100} value={qr} />) : <Skeleton width={100} height={100} variant="rect" />}
          </Grid> */}
          <Grid>
            <StyledInput
              columns={6}
              label="First Name"
              name="firstName"
              value={formik.values.firstName}
              onChange={transformedInputChangeHandler}
              transform="capitalize"
              error={Boolean(formik.touched.firstName && formik.errors.firstName)}
              helperText={formik.errors.firstName}
              data-test="my-account-first-name"
            />
            <StyledInput
              columns={6}
              label="Last Name"
              name="lastName"
              value={formik.values.lastName}
              onChange={transformedInputChangeHandler}
              transform="capitalize"
              error={Boolean(formik.touched.lastName && formik.errors.lastName)}
              helperText={formik.errors.lastName}
              data-test="my-account-last-name"
            />
            <StyledInput
              columns={6}
              label="Email"
              name="email"
              value={formik.values.email}
              onChange={transformedInputChangeHandler}
              disabled
              transform="lowercase"
              data-test="my-account-first-email"
            />
            <StyledInputPhone
              columns={6}
              label="Phone Number"
              name="phoneNumber"
              value={formik.values.phone}
              onChangeValue={(newPhone) => formik.setFieldValue('phone', newPhone)}
              error={Boolean(formik.touched.phone && formik.errors.phone)}
              helperText={formik.errors.phone}
              data-test="my-account-phone"
            />
          </Grid>
          <StyledFooterDiv>
            <StyledFooterSpan
              onClick={() => setIsPassChangeModalOpen(true)}
              data-test="my-account-change-password"
            >
              Change Password
            </StyledFooterSpan>
          </StyledFooterDiv>
          <StyledFooterDiv>
            {formik.dirty && (
              <StyledFooterButton
                onClick={formik.submitForm}
                color="primary"
                variant="contained"
                loading={companyUsers.isFetching || updateUserMutation.isLoading}
                data-test="my-account-save-btn"
              >
                Save Changes
              </StyledFooterButton>
            )}
          </StyledFooterDiv>
          <ChangePasswordModal
            open={isPassChangeModalOpen}
            onClose={() => setIsPassChangeModalOpen(false)}
            email={userData?.email || ''}
          />
        </WrapperContainer>
      )}
    </>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;
`;

const columnStyles = css<{ columns: number; }>`
  grid-column: span ${({ columns }) => columns};
  grid-row: span 1;

  @media (max-width: 520px) {
    grid-column: span 12;
  }
`;

const StyledInput = styled(Input)<{ columns: number; }>`
  ${columnStyles}
`;

const StyledInputPhone = styled(InputPhone)<{ columns: number; }>`
  ${columnStyles}
`;

const StyledFooterDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledFooterSpan = styled.span`
  color: #30cd9a;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  align-items: center;
  text-decoration-line: underline;
  cursor: pointer;
  justify-content: center;
`;

const StyledFooterButton = styled(Button)`
  margin-bottom: 30px;
  margin-left: 42px;
  margin-top: 52px;
  width: 100%;
  max-width: 140px;
  justify-content: center;
`;

const WrapperContainer = styled.div`
  padding: 16px 16px;
  border: 1px solid #e7e5e5;
  border-radius: 5px;
`;

const StyledTableHead = styled(TableHead)`
  margin-bottom: 10px;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

export default MyAccountSection;
