/* eslint-disable max-len */
import { UseQueryOptions, useQuery } from 'react-query';

import { getRevenueTrend } from '@zero5/payment-api';

import { mapRevenueTrendsResponse } from './mappings';
import { revenueQueryKeys } from './queryKeys';
import { RevenueTrendAggregationPeriod } from './models';

type Response = ReturnType<typeof mapRevenueTrendsResponse>;
type Key = ReturnType<typeof revenueQueryKeys.revenueTrend>;

const useRevenueTrendQuery = (
  params: {
    from: number;
    until: number;
    aggregationPeriod?: RevenueTrendAggregationPeriod;
  },
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  const { from, until, aggregationPeriod } = params;

  return useQuery<Response, unknown, Response, Key>(
    revenueQueryKeys.revenueTrend({ from, until, aggregationPeriod }),
    async () => {
      const response = await getRevenueTrend({
        from,
        until,
        aggregationPeriod,
      });

      return mapRevenueTrendsResponse(response.trend);
    },
    {
      ...options,
    },
  );
};

export default useRevenueTrendQuery;
