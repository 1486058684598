import { createActionCreators } from 'immer-reducer';

import * as clientAdminApi from '@zero5/client-admin-api';
import { AcceptCallResultType, getIncomingCalls } from '@zero5/client-admin-api';
import toast from '@zero5/ui/lib/utils/toast';

import { IncomingCall } from '@/controlCenter/api/call/models';
import { mapCall } from '@/controlCenter/api/call/mappings';

import { AppThunk } from '..';
import { CallReducer, CurrentCall } from '../reducers/call';

export const callActions = createActionCreators(CallReducer);

export const acceptCall = (call: IncomingCall): AppThunk => async (
  dispatch,
  getState,
) => {
  const state = getState();
  if (state.call.isAccepting) {
    toast('error', 'Other call is accepting');
    return;
  }

  try {
    const { requestId } = call;
    dispatch(callActions.setIsAccepting(true));
    dispatch(callActions.setAcceptingCallId(requestId));

    const response = await clientAdminApi.acceptCall({ requestId });
    if (response.type === AcceptCallResultType.HasAlreadyAnswered) {
      toast('info', 'Call has already answered.');
      dispatch(callActions.removeCall(requestId));
    } else {
      const currentCall: CurrentCall = {
        meetingCredentials: response.payload,
        incomingCall: call,
      };

      dispatch(callActions.setCurrentCall(currentCall));
      dispatch(callActions.removeCall(requestId));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(callActions.setIsAccepting(false));
    dispatch(callActions.setAcceptingCallId(null));
  }
};

export const endCall = (requestId: string): AppThunk => async (dispatch, getState) => {
  if (getState().call.currentCall?.incomingCall.requestId !== requestId) {
    if (getState().call.acceptingCallId === requestId) {
      dispatch(callActions.setAcceptingCallId(null));
    }
    return;
  }
  try {
    dispatch(callActions.setIsEnding(true));
    // todo replace with real api
    dispatch(handleEndedCall());
    await clientAdminApi.endCall({ requestId });
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(callActions.setIsEnding(false));
  }
};

export const handleEndedCall = (): AppThunk => async (dispatch) => {
  dispatch(callActions.setCurrentCall(null));
  dispatch(getCallsQueue());
};

export const getCallsQueue = (): AppThunk => async (dispatch) => {
  const response = await getIncomingCalls();
  const incomingCalls: Array<IncomingCall> = response.callRequests.map(mapCall);

  dispatch(callActions.replaceQueue(incomingCalls));
};
