import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { listTenantCompanyEmails } from '@zero5/user-api';

import { tenantQueryKeys } from './queryKeys';
// import { queryGroupName } from './queryGroupName';

type Response = Array<string>;
type Key = ReturnType<typeof tenantQueryKeys.companyEmails> ;
export const queryKey: QueryKey = 'garageTenantsQuery';
// type Key = [typeof queryGroupName, typeof queryKey, number];

const useTenantCompanyEmailsQuery = (
  tenantCompanyId: number,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    tenantQueryKeys.companyEmails(tenantCompanyId),
    // [queryGroupName, queryKey, tenantCompanyId],
    async () => {
      const response = await listTenantCompanyEmails(tenantCompanyId);

      return response.emails;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useTenantCompanyEmailsQuery;
