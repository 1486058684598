import React from 'react';
import styled from 'styled-components';

import { Button } from '@zero5/ui';
import { Props as ButtonProps } from '@zero5/ui/lib/Button';

import ExportSvg from '@/assets/icons/export.inline.svg';

interface Props extends ButtonProps {
  isExporting?: boolean;
}

const ExportButton: React.FC<Props> = ({ isExporting, disabled, ...props }) => {
// const ExportButton: React.FC<Props> = ({ isExporting, ...props }) => {
  return (
    <StyledExportButton color="primary" disabled={disabled || isExporting} {...props}>
      <ExportSvg />
      {' '}
      {isExporting ? 'Exporting...' : 'Export'}
    </StyledExportButton>
  );
};

const StyledExportButton = styled(Button)`
  text-transform: none;
  font-size: 14px;
  font-width: 300;
  padding-right: none;
  & svg {
    margin-right: 10px;
  }
`;

export default ExportButton;