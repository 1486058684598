import React from 'react';
import styled from 'styled-components';

import {
  Modal,
  ModalProps,
} from '@zero5/ui';

import Content from './Content';

interface Props extends Omit<ModalProps, 'children'> {
  email: string;
}

const ChangePasswordModal: React.FC<Props> = ({ onClose, email, ...props }) => {
  return (
    <StyledModal
      onClose={onClose}
      fullScreenSize="600px"
      title="Change Password"
      data-test="my-account-change-password-modal"
      {...props}
    >
      <Content onClose={onClose} email={email} />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

export default ChangePasswordModal;
