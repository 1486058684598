import { useMutation, UseMutationOptions } from 'react-query';

import { getActivitiesAggregatedCount } from '@zero5/activity-api';
// eslint-disable-next-line max-len
import { DateRange } from '@zero5/ui/lib/Chart';

import { queryKey as getActivitiesQuery } from '@/api/queries/useActivitiesQuery';

import useDateModule from '@/utils/date/useDateModule';

import { activitiesAggregatedCountFromBm } from '../mappings/activity';
import { Trend } from '../models/Trend';

type Response = Trend;

export const queryKey = [getActivitiesQuery, 'getActivitiesAggregatedCount'];

const useGetActivitiesAggregatedCount = (
  options?: UseMutationOptions<Response, unknown, DateRange, string>,
) => {
  const { convertDateRangeToTimeZoneWithStartEndOfDay } = useDateModule();

  return useMutation<Response, unknown, DateRange, string>(
    queryKey,
    async (args) => {
      const range = convertDateRangeToTimeZoneWithStartEndOfDay(args);
      const response = await getActivitiesAggregatedCount({
        fromDate: range.from,
        toDate: range.until,
      });
      return activitiesAggregatedCountFromBm(response.activityStatistic);
    },
    {
      ...options,
    },
  );
};

export default useGetActivitiesAggregatedCount;
