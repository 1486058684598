import * as yup from 'yup';

import schemas from '@zero5/ui/lib/utils/validation/schemas';

export const confirmPasswordSchema = yup.object({
  newPassword: schemas.password.label('New Password'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'Passwords must match')
    .required('Confirm Password is required'),
});

export const timeConsecutionValidation = yup
  .string()
  .test(
    'valid-time',
    'Start time must be before the end time',
    (value, context) => {
      return Number(context.parent.startTime)
      + Number(context.parent.startDate)
      < Number(context.parent.endTime)
      + Number(context.parent.endDate);
    },
  );
