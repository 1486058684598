/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import {
  getTransactionsDetails,
} from '@zero5/payment-api';

import { queryGroupName } from './queryGroupName';
import { mapRevenueReportDetails } from './mappings';

type Response = ReturnType<typeof mapRevenueReportDetails>;
type Params = {
  page?: number;
  take?: number;
  from: number;
  until: number;
};

export const queryKey: QueryKey = 'transactionsDetails';

type Key = [typeof queryGroupName, typeof queryKey, Params];

const useTransactionsDetailsQuery = (
  params: Params,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    [queryGroupName, queryKey, params],
    async () => {
      const response = await getTransactionsDetails(params);

      return mapRevenueReportDetails(response);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      keepPreviousData: true,
      ...options,
    },
  );
};

export default useTransactionsDetailsQuery;
