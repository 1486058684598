import { createSelector } from 'reselect';

import { State } from '@/store';

const selectSettings = (state: State) => state.settingsReducer;

export const selectHomeSettings = createSelector(
  selectSettings,
  (state) => state.home,
);

export const selectEventsSettings = createSelector(
  selectSettings,
  (state) => state.events,
);

export const selectTenantsSettings = createSelector(
  selectSettings,
  (state) => state.tenants,
);

export const selectUsersSettings = createSelector(
  selectSettings,
  (state) => state.users,
);

export const selectPermitSettings = createSelector(
  selectSettings,
  (state) => state.permit,
);

export const selectRevenueSettings = createSelector(
  selectSettings,
  (state) => state.revenue,
);

export const selectTrafficSettings = createSelector(
  selectSettings,
  (state) => state.traffic,
);

export const selectValetSettings = createSelector(
  selectSettings,
  (state) => state.valet,
);

export const selectValidationHistorySettings = createSelector(
  selectSettings,
  (state) => state.validationHistory,
);
