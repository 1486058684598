// /* eslint-disable max-len */
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { createCompanyPermit } from '@zero5/permit-api';

import { handleError } from '@/utils/handleError';

import { mapPermit } from './mappings';
import { queryGroupName } from './queryGroupName';

enum PermitPaymentType {
  PREPAID = 'PREPAID',
  INVOICE = 'INVOICE',
}

type Response = ReturnType<typeof mapPermit>;
type Params = {
  garageId?: string;
  permitType: string;
  paymentType: PermitPaymentType;
  paymentId?: number;
  startTime: number;
  driverId?: number;
  lpNum: string;
  lpState: string;
  vehicleColor: string;
  vehicleMake: string;
  vehicleModel: string;
  isAutoRenewEnabled: boolean;
};

const useCreatePermitMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'createPermit',
    async (params) => {
      const response = await createCompanyPermit(params);

      return mapPermit(response.permit);
    },
    {
      ...options,
      onSuccess: (...params) => {
        options?.onSuccess?.(...params);

        queryClient.invalidateQueries(queryGroupName);
      },
      onError: (e) => handleError(e),
    },
  );
};

export default useCreatePermitMutation;
