import { useQuery, UseQueryOptions } from 'react-query';

import { getGarage } from '@zero5/garage-api';

import { GARAGE_QUERY, GET_GARAGE_QUERY } from '@/api/garage/constants';

import { mapGarage } from './mappings';

type Response = ReturnType<typeof mapGarage>;

const useGetGarageQuery = (
  options?: UseQueryOptions<Response, unknown, Response>,
) => {
  return useQuery<Response, unknown, Response>(
    [GARAGE_QUERY, GET_GARAGE_QUERY],
    async () => {
      const response = await getGarage();

      return mapGarage(response.garage);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      ...options,
    },
  );
};

export default useGetGarageQuery;
