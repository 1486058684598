function getArrayWithNumberBaseEnumItems<T>(
  numberEnum: T,
): Array<{ label: keyof T; value: T[keyof T]; }> {
  const arrayWithEnumItems = [];
  for (const item in numberEnum) {
    if (Number.isNaN(Number(item))) {
      arrayWithEnumItems.push({
        label: (numberEnum[
          (numberEnum[item] as unknown) as Extract<keyof T, string>
        ] as unknown) as keyof T,
        value: numberEnum[item],
      });
    }
  }
  return arrayWithEnumItems;
}
  
export function getEnumLabelValuePairs<T>(enumeration: T) {
  const results = getArrayWithNumberBaseEnumItems(enumeration);
  const formattedResults = results.map(result=> {
    return { 
      value: result.value,
      label: result.label.toString().replace('_', ' '),
    };
  });
    
  return formattedResults;
}