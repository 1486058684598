import { createSelector, Selector } from 'reselect';

import { State } from '@/store';
import { WorkspaceState } from '@/store/reducers/workspace';

const selectWorkspace = (state: State) => state.workspaceReducer;

// eslint-disable-next-line import/prefer-default-export
export const selectCurrentGarage: Selector<State, WorkspaceState['currentGarage']> = createSelector(
  selectWorkspace,
  (state) => state.currentGarage,
);
