import toast from '@zero5/ui/lib/utils/toast';
import { notifyAboutUiError } from '@zero5/client-admin-api';

export const handleError = (error: unknown, customErrorDescription: string = 'Unexpected error!') => {
  if (error instanceof Error) {
    toast('error', error.message);
  } else {
    toast('error', customErrorDescription);
    console.error(error);
  }

  notifyAboutUiError({
    error: `${error}`,
  })
    .catch((sendError: unknown) => console.error(sendError));
};
