import React from 'react';

import { Role as RoleUi, RoleProps, useFindCurrentAction as useFindCurrentActionUi } from '@zero5/ui';

import useGarageRolesQuery from '@/api/queries/useUserGarageRoleQuery';

const Role: React.FC<Omit<RoleProps, 'uiPermissions'>> = (props) => {
  const uiPermissionsQuery = useGarageRolesQuery();
  return <RoleUi uiPermissions={uiPermissionsQuery.data?.role?.uiPermissions} {...props} />;
};

export const useFindCurrentAction = () => {
  const uiPermissionsQuery = useGarageRolesQuery();

  return useFindCurrentActionUi(uiPermissionsQuery.data?.role?.uiPermissions);
};

export default Role;
