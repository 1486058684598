import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getRevenueTrend } from '@zero5/payment-api';

import useDateModule from '@/utils/date/useDateModule';

import { mapRevenueTodayResponse } from './mappings';
import { queryGroupName } from './queryGroupName';

type Response = ReturnType<typeof mapRevenueTodayResponse>;

export const queryKey: QueryKey = 'TotalRevenueToday';

const useTotalRevenueTodayQuery = (
  options?: UseQueryOptions<Response, unknown, Response, [typeof queryGroupName, typeof queryKey]>,
) => {
  const { getTimestampOfTodayStart, getTimestampOfTodayEnd } = useDateModule();

  return useQuery<Response, unknown, Response, [typeof queryGroupName, typeof queryKey]>(
    [queryGroupName, queryKey],
    async () => {
      const from = getTimestampOfTodayStart();
      const until = getTimestampOfTodayEnd();

      const response = await getRevenueTrend({ from, until });

      return mapRevenueTodayResponse(response.trend);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useTotalRevenueTodayQuery;
