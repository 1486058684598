/* eslint-disable max-len */
import { Response as RevenueHistoryResponse } from '@zero5/payment-api/lib/src/functions/getRevenueHistory/types';
import { Response as RevenueInformationResponse } from '@zero5/payment-api/lib/src/functions/getRevenueInformation/types';
import { Response as RevenueDetailsResponse } from '@zero5/payment-api/lib/src/functions/getRevenueDetails/types';
import { Response as RevenueTrendResponse } from '@zero5/payment-api/lib/src/functions/getRevenueTrend/types';
import { Response as RevenueForecastedResponse } from '@zero5/payment-api/lib/src/functions/getForecastedRevenue/types';
import { Response as RevenueReportResponse } from '@zero5/payment-api/lib/src/functions/getRevenueReports/types';
import { Response as RevenueInvoicesResponse } from '@zero5/payment-api/lib/src/functions/getInvoicesReports/types';
import { Response as TransactionsSummaryResponse } from '@zero5/payment-api/lib/src/functions/getTransactionsSummary/types';
import { Response as TransactionsDetailsResponse } from '@zero5/payment-api/lib/src/functions/getTransactionsDetails/types';
import { Response as ForecastedRevenueDetailsResponse } from '@zero5/payment-api/lib/src/functions/getForecastedRevenueDetails/types';
import { Response as AggregatedTransactionsDetailsResponse } from '@zero5/payment-api/lib/src/functions/getAggregatedTransactionsDetails/types';
import { Response as InvoiceSummaryResponse } from '@zero5/payment-api/lib/src/functions/getInvoiceSummary/types';
import { Response as InvoiceReportDetailsResponse } from '@zero5/payment-api/lib/src/functions/getInvoiceReportDetails/types';
import { Response as ForecastedRevenueSummaryResponse } from '@zero5/payment-api/lib/src/functions/getForecastedRevenueSummary/types';

import { ArrayElement } from '@/utils/types';

import { AggregatedTransactionDetail, Invoice, InvoiceReportSummary, RevenueForecastedReport, RevenueHistory, RevenueInformation, RevenueReport, RevenueTrend, RevenueDetail, InvoiceStatus, InvoiceReportDetail, ForecastedRevenueDetail, TransactionStats } from './models';
// import { AggregatedTransactionDetail, Invoice, InvoiceReportSummary, InvoiceReportDetail, RevenueForecastedReport, RevenueHistory, RevenueInformation, RevenueReport, RevenueTrend, RevenueDetail, TransactionStats, InvoiceStatus } from './models';

export const mapRevenueHistory = (response: RevenueHistoryResponse['history'][0]): RevenueHistory => ({
  total: response.total,
  detached: response.detached,
  events: response.events,
  permits: response.permits,
  date: response.date,
});

export const mapRevenueHistories = (
  response: RevenueHistoryResponse['history'],
): Array<RevenueHistory> => response.map(mapRevenueHistory);

export const mapRevenueForecastedReport = (response: ArrayElement<RevenueForecastedResponse['forecastedRevenue']['revenueItems']>): RevenueForecastedReport => ({
  date: response.timestamp,
  transient: response.transient,
  permit: response.permit,
  event: response.event,
  validation: response.validation,
  dispute: response.disput,
  total: response.total,
});

export const mapRevenueReport = (response: ArrayElement<RevenueReportResponse['reports']>): RevenueReport => ({
  date: response.date,
  transient: response.transient,
  permit: response.permit,
  event: response.event,
  validation: response.validation,
  total: response.total,
  dispute: response.dispute,
  pending: response.pending,
});

export const mapRevenueForecastedReports = (response: RevenueForecastedResponse) => ({
  reports: response.forecastedRevenue.revenueItems.map(mapRevenueForecastedReport),
  pagesCount: response.forecastedRevenue.pageCount,
  entitiesCount: response.forecastedRevenue.entriesCount,
});

export const mapRevenueReports = (response: RevenueReportResponse) => ({
  pagesCount: response.pagesCount,
  reports: response.reports.map(mapRevenueReport),
});

export const mapRevenueInformation = (response: RevenueInformationResponse['revenue']): RevenueInformation => ({
  total: {
    value: response.total.value,
    percentage: response.total.percentage,
  },
  detached: {
    value: response.detached.value,
    percentage: response.detached.percentage,
  },
  permits: {
    value: response.permits.value,
    percentage: response.permits.percentage,
  },
  events: {
    value: response.events.value,
    percentage: response.events.percentage,
  },
});

export const mapRevenueDetails = (response: RevenueDetailsResponse) => ({
  event: response.event,
  permit: response.permit,
  transient: response.transient,
  validation: response.validation,
});

export const mapRevenueTrendResponse = (response: RevenueTrendResponse['trend'][0]): RevenueTrend => ({
  value: response.value,
  date: response.date,
});

export const mapRevenueTrendsResponse = (
  response: RevenueTrendResponse['trend'],
): Array<RevenueTrend> => response.map(mapRevenueTrendResponse);

export const mapRevenueTodayResponse = (
  response: RevenueTrendResponse['trend'],
): number => response[0].value;

export const mapRevenueInvoice = (response: ArrayElement<RevenueInvoicesResponse['reports']>): Invoice => ({
  transactionId: response.transactionId,
  tenantName: response.tenantName,
  permit: response.permit,
  validation: response.validation,
  event: response.event,
  transient: response.transient,
  total: response.total,
  status: response.status as InvoiceStatus,
  isSuccessful: response.isSuccessful,
  isPending: response.isPending,
});

export const mapRevenueInvoices = (response: RevenueInvoicesResponse) => ({
  reports: response.reports.map(mapRevenueInvoice),
  pagesCount: response.pagesCount,
  entriesCount: response.entriesCount,
  firstTransactionTimestamp: response.firstTransactionTimestamp,
});

const mapSummaryStats = (stats: InvoiceSummaryResponse['summary']['event']) => ({
  amount: stats.amount,
  count: stats.count,
});

const mapInvoicePaymentDetails = (details: InvoiceSummaryResponse['paymentDetails']) => ({
  date: details.date,
  method: details.method,
  cycle: details.cycle,
  period: details.period,
  status: details.status,
});

export const mapInvoiceSummary = (response: InvoiceSummaryResponse): InvoiceReportSummary => ({
  summary: {
    permit: mapSummaryStats(response.summary.permit),
    validation: mapSummaryStats(response.summary.validation),
    event: mapSummaryStats(response.summary.event),
  },
  date: response.date,
  tenantName: response.tenantName,
  totalAmount: response.totalAmount,
  paymentDetails: mapInvoicePaymentDetails(response.paymentDetails),
});
  
export const mapInvoiceReportDetail = (response: ArrayElement<InvoiceReportDetailsResponse['detailedStats']>): InvoiceReportDetail => ({
  transactionType: response.transactionType,
  date: response.date,
  total: response.total,
  account: response.account,
  note: response.note,
  amount: response.amount,
});

export const mapInvoiceReportDetails = (response: InvoiceReportDetailsResponse) => ({
  detailedStats: response.detailedStats.map(mapInvoiceReportDetail),
  entriesCount: response.entriesCount,
  pagesCount: response.pagesCount,
});

const mapStatsItem = (item: ForecastedRevenueSummaryResponse['summary']['event']): TransactionStats => ({
  total: item.total,
  count: item.count,
  average: item.average,
});

export const mapRevenueSummary = (response: TransactionsSummaryResponse) => ({
  transient: mapStatsItem(response.summary.transient),
  permit: mapStatsItem(response.summary.permit),
  event: mapStatsItem(response.summary.event),
  validation: mapStatsItem(response.summary.validation),
  dispute: mapStatsItem(response.summary.dispute),
  pending: mapStatsItem(response.summary.pending),
  total: response.summary.total,
});

export const mapForecastedRevenueSummary = (response: ForecastedRevenueSummaryResponse) => ({
  transient: mapStatsItem(response.summary.transient),
  permit: mapStatsItem(response.summary.permit),
  event: mapStatsItem(response.summary.event),
  validation: mapStatsItem(response.summary.validation),
  dispute: mapStatsItem(response.summary.dispute),
  total: response.summary.total,
});

const mapRevenueDetail = (item: ArrayElement<TransactionsDetailsResponse['detailedStats']>): RevenueDetail => ({
  date: item.date,
  service: item.service,
  transactionType: item.transactionType,
  amount: item.amount,
  total: item.total,
});

export const mapRevenueReportDetails = (response: TransactionsDetailsResponse) => ({
  detailedStats: response.detailedStats.map(mapRevenueDetail),
  entriesCount: response.entriesCount,
  pagesCount: response.pagesCount,
});

const mapForecastedRevenueDetail = (item: ArrayElement<ForecastedRevenueDetailsResponse['reports']>): ForecastedRevenueDetail => ({
  date: item.date,
  service: item.service,
  paymentMethod: item.paymentMethod,
  note: item.note,
  amount: item.amount,
  total: item.total,
});

export const mapForecastedRevenueReportDetails = (response: ForecastedRevenueDetailsResponse) => ({
  reports: response.reports.map(mapForecastedRevenueDetail),
  entriesCount: response.entriesCount,
  pagesCount: response.pagesCount,
});

const mapAggregatedTransactionDetail = (item: ArrayElement<AggregatedTransactionsDetailsResponse['detailedStats']>): AggregatedTransactionDetail => ({
  date: item.date,
  transient: item.transient,
  permit: item.permit,
  event: item.event,
  validation: item.validation,
  total: item.total,
});

export const mapAggregatedTransactionsDetails = (response: AggregatedTransactionsDetailsResponse) => ({
  detailedStats: response.detailedStats.map(mapAggregatedTransactionDetail),
  entriesCount: response.entriesCount,
  pagesCount: response.pagesCount,
});
