/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import {
  getPermitsAggregatedCount,
} from '@zero5/permit-api';

import { handleError } from '@/utils/handleError';

import { queryGroupName } from './queryGroupName';
import { mapAggregatedCount } from './mappings';

type Response = ReturnType<typeof mapAggregatedCount>;
type Params = {
  from: Date;
  to: Date;
  aggregationPeriod: 'daily' | 'weekly' | 'monthly';
};

export const queryKey: QueryKey = 'getAggregatedCount';

type Key = [typeof queryGroupName, typeof queryKey, Params];

const useGetAggregatedCount = (
  params: Params,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    [queryGroupName, queryKey, params],
    async () => {
      const response = await getPermitsAggregatedCount({
        fromDate: params.from.getTime(),
        toDate: params.to.getTime(),
        aggregationPeriod: params.aggregationPeriod,
      });

      return mapAggregatedCount(response);
    },
    {
      ...options,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      staleTime: 10 * 60 * 1000,
      onError: (e) => handleError(e),
    },
  );
};

export default useGetAggregatedCount;
