import React from 'react';
import { useMediaQuery } from '@material-ui/core';

import { SimpleRowsMap } from '@zero5/ui';
import vars from '@zero5/ui/lib/styles/vars';
import { getZone } from '@zero5/ui/lib/utils/formatters/formatGate';

import { emptyFieldStub } from '@/utils/emptyFieldStub';

import { Activity } from '@/controlCenter/api/activity/models';

import EditableText from '../common/EditableText';
import Select from '../common/Select';

import { StyledDataCard, StyledSimpleTable, ApplyButton, CardContent, Span } from './styled';

type AccessInfo = {
  entryActivity?: Activity | null;
  exitActivity?: Activity;
};

interface Props {
  info: AccessInfo;
  activityUpdating: boolean;
  onApply: (data: {
    exitLp?: string | null;
  }) => void;
  /**
   * Screen size from which to place in the column
   */
  columnFrom: string;
  className?: string;
}

const ActivityAccessInfo: React.FC<Props> = ({
  info,
  activityUpdating,
  onApply,
  columnFrom,
  className,
}) => {
  const isColumn = useMediaQuery(`(max-width: ${columnFrom})`, { noSsr: true });

  const [editedExitLP, setEditedExitLP] = React.useState(info.exitActivity?.mainLp);

  const accessInfoRowsMap: SimpleRowsMap<AccessInfo> = [
    {
      title: 'Vehicle Status',
      data: ({ exitActivity }) => exitActivity?.status || emptyFieldStub,
    },
    {
      title: () => <Span color={vars.palette.primary}>Entry LP (Conf.)</Span>,
      data: ({ entryActivity }) => entryActivity?.mainLp || emptyFieldStub,
    },
    {
      title: 'Exit Gate',
      data: ({ exitActivity }) => exitActivity
        ? getZone(exitActivity.deviceId)
        : emptyFieldStub,
    },
    {
      title: () => <Span color={'#FF0000'}>Exit LP (Conf.)</Span>,
      data: ({ exitActivity }) => (
        <Span color={'#FF0000'}>
          {exitActivity ? (
            <EditableText
              value={editedExitLP || ''}
              onChange={(e) => setEditedExitLP(e.target.value)}
            />
          ) : emptyFieldStub}
        </Span>
      ),
    },
    {
      title: '',
      data: () => <Select />,
    },
    {
      title: 'Exit _ Front LP',
      data: ({ exitActivity }) => exitActivity?.frontLpNum || emptyFieldStub,
    },
    {
      title: 'Exit _ Rear LP',
      data: ({ exitActivity }) => exitActivity?.rearLpNum || emptyFieldStub,
    },
  ];

  return (
    <StyledDataCard
      label="Access Info"
      direction={isColumn ? 'column' : 'row'}
      className={className}
    >
      <CardContent>
        <StyledSimpleTable
          rowsMap={accessInfoRowsMap}
          item={info}
          align="left"
        />
        <ApplyButton
          variant="contained"
          color="primary"
          onClick={() => onApply({ exitLp: editedExitLP })}
          disabled={info.exitActivity?.mainLp === editedExitLP}
          loading={activityUpdating}
        >
          Apply
        </ApplyButton>
      </CardContent>
    </StyledDataCard>
  );
};

export default ActivityAccessInfo;