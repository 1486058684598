// /* eslint-disable max-len */
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { deactivatePermit } from '@zero5/permit-api';

import { handleError } from '@/utils/handleError';

import { mapPermit } from './mappings';
import { queryGroupName } from './queryGroupName';

type Response = ReturnType<typeof mapPermit>;
type Params = {
  permitId: number;
};

const useDeactivatePermitMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'deactivatePermit',
    async (params) => {
      const response = await deactivatePermit(params);

      return mapPermit(response.permit);
    },
    {
      ...options,
      onSuccess: (...params) => {
        options?.onSuccess?.(...params);

        queryClient.invalidateQueries(queryGroupName);
      },
      onError: (e) => handleError(e),
    },
  );
};

export default useDeactivatePermitMutation;
