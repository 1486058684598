import React from 'react';
import styled from 'styled-components';
import { ClickAwayListener } from '@material-ui/core';

import useToggle from '@zero5/ui/lib/utils/hooks/useToggle';

import CreateIcon from '@controlCenter/assets/icons/create.inline.svg';

interface Props {
  value?: string;
  onChange?: React.InputHTMLAttributes<HTMLInputElement>['onChange'];
}

const EditableText: React.FC<Props> = ({ value, onChange, ...props }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [isInput, closeInput, , toggleInput] = useToggle(false);

  React.useEffect(() => {
    if (isInput) {
      inputRef.current?.focus();
    }
  }, [isInput]);

  return (
    <ClickAwayListener onClickAway={closeInput}>
      <Wrapper {...props}>
        {isInput ? (
          <StyledInput value={value} onChange={onChange} ref={inputRef} />
        ) : (
          <Text>{value}</Text>
        )}
        <TransparentButton onClick={toggleInput}>
          <CreateIcon />
        </TransparentButton>
      </Wrapper>
    </ClickAwayListener>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.div`
  margin: 1px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 0;
  margin-right: 10px;
  border: 1px solid #E7E5E5;
  border-radius: 2px;

  &:focus {
    outline: none;
  }
`;

const TransparentButton = styled.button`
  padding: 0;
  background: transparent;
  border: none;

  &:focus {
    outline: none;
  }
`;

export default EditableText;
