import { useQuery, UseQueryOptions } from 'react-query';

import { getUnresolvedIssues } from '@zero5/client-admin-api';

import { ACTIVITY_QUERY, GET_UNRESOLVED_ISSUES_QUERY } from './constants';

type Response = number;

export const queryKey = [ACTIVITY_QUERY, GET_UNRESOLVED_ISSUES_QUERY] as const;

type Key = typeof queryKey;

const useGetUnresolvedIssues = (
  fromDate: number,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    queryKey,
    async () => {
      const response = await getUnresolvedIssues({ fromDate });

      return response.count;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useGetUnresolvedIssues;
