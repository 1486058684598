import { setHours, setMinutes } from '@/utils/date/helpers';

export const  setHoursAndMinutes = (str: string) => {
    
  const hours = Number(str.slice(0, 2));
  const minutes = Number(str.slice(2, 4));
    
  const dateWithHours = setHours(new Date(), hours);
  const dateWithMinutes = setMinutes(dateWithHours, minutes);    
    
  return new Date(dateWithMinutes);
};