import React, { useEffect, useState, ChangeEvent } from 'react';
import styled, { css } from 'styled-components';
import { FormikProps } from 'formik';

import { Input, Select, Button } from '@zero5/ui';
import { Props as SelectProps } from '@zero5/ui/lib/Select';
import { capitalize } from '@zero5/ui/lib/utils/formatters/capitalize';

import { GatewayType, SearchFormikState } from '@/api/models/activities';
import useGetLayoutsQuery from '@/api/device/useGetLayoutsQuery';
import { Option } from '@/api/models/activities';

import DatePicker from '@/components/common/DatePicker';
import TimePicker from '@/components/common/SelectTimePicker';

import { getEnumLabelValuePairs } from '@/utils/getEnumLabelValuePairs';
import { regexNumbersAlphabets } from '@/utils/regex';

// import Button from '../common/Button';


interface Props {
  formik: FormikProps<SearchFormikState>;
  isLoading: boolean;
}

const gatewayOptions = getEnumLabelValuePairs(GatewayType).map(option=> {
  return {
    ...option,
    label: capitalize(option.label),
  };
});

const ActivitySearchRow: React.FC<Props> = ({ formik, isLoading }) => {
  const { handleSubmit, values, setFieldValue } = formik;
  const { data:layouts } = useGetLayoutsQuery();
  const [locationList, setLocationList] = useState<Option[]>([]);

  useEffect(() => {
    if (layouts){
      setLocationList([...layouts]);
    }
  }, [layouts]);

  const licensePlateChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    
    if (regexNumbersAlphabets.test(value)) {
      const licensePlate = value.toLocaleUpperCase().replace('O', '0');
      formik.setFieldValue(name, licensePlate);
    }
  };

  const isDisabledEndTimeHandler = ()=>{
    const { startDate, endDate } = values;
    return startDate && endDate
    && startDate.getFullYear() === endDate.getFullYear() 
    && startDate.getMonth() === endDate.getMonth()
    && startDate.getDate() === endDate.getDate();
  };

  return (
    <SearchRowWrapper onSubmit={handleSubmit}>
      <SearchInput
        label="Search"
        name="mainLp"
        placeholder="License Plate"
        value={values.mainLp || ''}
        onChange={licensePlateChangeHandler}
        columns={4}
      />
      <SearchSelect
        label="Entry / Exit"
        options={gatewayOptions}
        onChange={(value) => setFieldValue('gatewayType', value?.value)}
        value={gatewayOptions.find(
          (option) => option.value === values.gatewayType,
        )}
        columns={4}
      />
      <SearchSelect
        label="Location"
        options={locationList}
        onChange={(value) => setFieldValue('location', value?.value)}
        value={locationList.find((option) => option.value === values.location)}
        columns={4}
      />
      <SearchDatePicker
        columns={5}
        label="Date"
        pickerProps={{
          selectsRange: true,
          dateFormat: 'MM/dd/yy',
          startDate: formik.values.startDate,
          endDate: formik.values.endDate,
          placeholderText: 'mm/dd/yy - mm/dd/yy',
          onChange: (selectedDate: [Date, Date]) => {
            formik.setFieldValue('startDate', selectedDate[0]);
            formik.setFieldValue('endDate', selectedDate[1]);
          },
        }}
      />
      <SearchTimePicker
        columns={6}
        startTime={values.startTime}
        endTime={values.endTime}
        isDisableEndTime={isDisabledEndTimeHandler()}
        onChangeStartTime={(hours) => setFieldValue('startTime', hours)}
        onChangeEndTime={(hours) => setFieldValue('endTime', hours)}
      />
      <SearchButton
        disabled={!(values.endDate && values.startDate)}
        columns={1}
        color="primary"
        variant="contained"
        type="submit"
        loading={isLoading}
      >
        Search
      </SearchButton>
    </SearchRowWrapper>
  );
};

const SearchRowWrapper = styled.form`
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  margin-bottom: 30px;
  margin-top: 30px;
  align-items: end;
  width: 100%;
  @media (max-width: 730px) {
    grid-column-gap: 0px;
  }
`;

type ColumnProps = { columns: number; };

const columnStyles = css<ColumnProps>`
  grid-column: span ${({ columns }) => columns};
  grid-row: span 1;


  @media (max-width: 830px) {
    grid-column: span 12;
  }
  @media (max-width: 730px) {
    grid-column: span 24;
  }
`;


const SearchInput = styled(Input)`
 ${columnStyles} 
`;

const SearchSelect = styled(Select)`
 ${columnStyles}
` as React.FC<SelectProps<Option> & ColumnProps>;

const SearchDatePicker = styled(DatePicker)`
 ${columnStyles}
`;

const SearchTimePicker = styled(TimePicker)`
 ${columnStyles}
`;

const SearchButton = styled(Button)`
${columnStyles}
height:36px;
min-width: 100px;
`;

export default ActivitySearchRow;
