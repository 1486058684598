import React from 'react';
import styled from 'styled-components';

import vars from '@zero5/ui/lib/styles/vars';

import SuccessValidateSvg from '@/assets/successful-validate.inline.svg';
import EmailSvg from '@/assets/email.inline.svg';

interface Props{
  searchInput: string;
  handleGoBackClick: () => void;
}

const ValidationSuccess: React.FC<Props> = ({ searchInput, handleGoBackClick }) => {
  return (
    <>
      {searchInput.includes('@') ? (
        <>
          <Text size="25px" weight="600">
            Email has been sent with a QR code.
            <Text size="16px">
              Please inform the guest to scan the QR code at the exit.
            </Text>
          </Text>

          <SvgWrapper><EmailSvg width="90%" height="90%" /></SvgWrapper>
        </>
      ) : (
        <>
          <Text size="25px">
            Parking for
            {' '}
            <span style={{ fontWeight: 600 }}>{searchInput}</span>
            {' '}
            has been validated!
          </Text>
          <SvgWrapper><SuccessValidateSvg width="90%" height="90%" /></SvgWrapper>
        </>
      )}
      <StyledLink onClick={handleGoBackClick}>
        Go Back to Validation Home Page
      </StyledLink>
    </>
  );
};

const StyledLink = styled.a`
  margin-top: 38px;
  color: ${vars.palette.primary};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Text = styled.div<{ size?: string; weight?: string; }>`
  font-weight: ${({ weight }) => weight || '300'};
  font-size: ${({ size }) => size || '16px'};
  line-height: 1.375;
  color: #181818;
  margin: 33px auto 30px;
`;

const SvgWrapper = styled.div`
width:332px;
height: 332px;
@media (max-width: 500px){
  width: 100%;
  height: 100%;
  }
`;

export default ValidationSuccess;
