import { useQuery, UseQueryOptions } from 'react-query';

import { getPermitTypesForTenantType } from '@zero5/permit-api';

import { mapPermitTypesForTenantType } from './mappings';
import { permitQueryKeys } from './queryKeys';

type Response = ReturnType<typeof mapPermitTypesForTenantType>;
type Key = ReturnType<typeof permitQueryKeys.permitTypesForTenantType>;

const usePermitTypesForTenantTypeQuery = (
  tenantType: string,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    permitQueryKeys.permitTypesForTenantType(tenantType),
    async () => {
      const response = await getPermitTypesForTenantType(tenantType);

      return mapPermitTypesForTenantType(response.permitTypes);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default usePermitTypesForTenantTypeQuery;
