import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { createSystemUser } from '@zero5/user-api';

import { mapUser } from '@/api/mappings/user';
import { User } from '@/api/models/users';
import { queryKey as companyUsersQueryKey } from '@/api/queries/useCompanyUsersQuery';

type Response = User;

const useCreateUserMutation = (
  options?: UseMutationOptions<Response, Error, {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    roleId: number;
  }, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'createUser',
    async (params) => {
      const response = await createSystemUser(params);

      return mapUser(response.user);
    },
    {
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries(companyUsersQueryKey);

        options?.onSuccess?.(...params);
      },
    },
  );
};

export default useCreateUserMutation;
