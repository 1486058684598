import React from 'react';
import styled from 'styled-components';
import flow from 'lodash/flow';

import Page from '@/components/common/page';
import MyAccountSection from '@/components/MyAccountSection';

import { withAuth } from '@/utils/hocs/withAuth';
import { withRole } from '@/utils/hocs/withRole';
import { withLoading } from '@/utils/hocs/withLoading';

interface Props {}

const MyAccount: React.FC<Props> = () => {
  return (
    <Page>
      <PageWrapper>
        <MyAccountSection />
      </PageWrapper>
    </Page>

  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default flow(
  withRole('myAccount'),
  withAuth(),
  withLoading(),
)(MyAccount);
