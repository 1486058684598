/* eslint-disable no-nested-ternary */
import React from 'react';
import ReactSelect, { Props as ReactSelectProps, StylesConfig } from 'react-select';

interface Props<T> extends ReactSelectProps<T, false> {
  error?: boolean;
}

type OptionTypeBase = {
  [key: string]: unknown;
};

type OptionType = { label: string; value: string; };

const Select = <T extends OptionTypeBase = OptionType>({
  error,
  ...props
}: Props<T>) => {
  const customStyles: StylesConfig<T, false> = {
    option: (provided, state) => {
      let bgc = '';

      if (state.isSelected) {
        bgc = '#30cd9a';
      } else if (state.isFocused) {
        bgc = 'rgba(48, 205, 154, .2)';
      }

      return {
        ...provided,
        backgroundColor: bgc,
        minHeight: '32px',
      };
    },
    multiValueLabel: (provided) => {
      return {
        ...provided,
        color: '#545b61',
        backgroundColor: '#eeeeef',
      };
    },
    control: (provided, state) => ({
      ...provided,
      minHeight: 'unset',
      minWidth: '120px',
      backgroundColor: '#fbfcfc',
      borderColor: error
        ? '#f597a2'
        : state.isFocused
          ? 'rgba(48, 205, 154, .6)'
          : '#eaecf0',
      '&:hover': {
        borderColor: error
          ? '#f597a2'
          : state.isFocused
            ? 'rgba(48, 205, 154, .6)'
            : '#eaecf0',
      },
      boxShadow: state.isFocused
        ? error
          ? '0 0 0 0.2rem rgb(239, 81, 100, .15)'
          : '0 0 0 0.2rem rgba(48, 205, 154, .2)'
        : '',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: '0',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0',
    }),
  };

  return (
    <ReactSelect
      styles={customStyles}
      {...props}
    />
  );
};

export default Select;
