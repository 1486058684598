import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { updateSystemUser } from '@zero5/user-api';

import { mapUser } from '@/api/mappings/user';
import { User } from '@/api/models/users';
import { queryKey as companyUsersQueryKey } from '@/api/queries/useCompanyUsersQuery';

type Response = User;

const useUpdateUserMutation = (
  options?: UseMutationOptions<Response, Error, {
    userId: number;
    updates: {
      firstName: string;
      lastName: string;
      phone: string;
      roleId: number;
    };
  }, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'createUser',
    async (params) => {
      const response = await updateSystemUser(params.userId, params.updates);
      return mapUser(response.user);
    },
    {
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries(companyUsersQueryKey);

        options?.onSuccess?.(...params);
      },
    },
  );
};

export default useUpdateUserMutation;
