import { useMutation, UseQueryOptions } from 'react-query';

import { addHoursOpen, AddHoursOpenArgs } from '@zero5/garage-api';

import { GARAGE_ADD_GARAGE_HOURS, GARAGE_HOURS_OPEN_QUERY, GARAGE_QUERY } from '@/api/garage/constants';

import { handleError } from '@/utils/handleError';
import queryClient from '@/utils/queryClient';

const useAddGarageHoursOpenMutation = (
  options: UseQueryOptions = {},
) => {
  return useMutation(
    [GARAGE_QUERY, GARAGE_ADD_GARAGE_HOURS],
    async (params: AddHoursOpenArgs) => {
      const response = await addHoursOpen(params);
      return response;
    },
    {
      onError: async (error) => {
        handleError(error);
        await queryClient.refetchQueries(GARAGE_HOURS_OPEN_QUERY);
      },
      onSuccess: () => queryClient.refetchQueries([GARAGE_QUERY, GARAGE_HOURS_OPEN_QUERY]),
      ...options,
    },
  );
};

export default useAddGarageHoursOpenMutation;
