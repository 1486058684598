import React from 'react';
import styled from 'styled-components';

import { SimpleRowsMap, Buttons, SimpleTable, Image } from '@zero5/ui';

import { formatTimeStampToString } from '@/utils/date/helpers';
import { emptyFieldStub } from '@/utils/emptyFieldStub';

import { Activity } from '@/controlCenter/api/activity/models';

import { ApplyButton, StyledSimpleTable } from './styled';

interface Props {
  activities: Array<Activity>;
  entryActivityId?: string;
  onSelectActivity: (activity: Activity) => void;
  activityUpdating?: boolean;
  className?: string;
}

const SuggestedActivities: React.FC<Props> = ({
  activities,
  entryActivityId,
  onSelectActivity,
  activityUpdating,
  className,
}) => {
  const [suggestedImageType, setSuggestedImageType] = React.useState(
    activities.map((activity) => activity?.frontImg ? 'front' : 'rear') || [],
  );

  const resultRowsMap: SimpleRowsMap<Activity> = [
    {
      title: 'License Plate',
      data: (activity) => activity?.mainLp || emptyFieldStub,
    },
    {
      title: 'Entry Time',
      data: (activity) => activity?.eventTime
        ? formatTimeStampToString(activity.eventTime, 'MM/dd/yyyy HH:mm:ss')
        : emptyFieldStub,
    },
    {
      title: '',
      data: (activity) => (
        <SelectButton
          variant="contained"
          color="primary"
          loading={activityUpdating}
          onClick={() => onSelectActivity(activity)}
        >
          Select
        </SelectButton>
      ),
    },
  ];

  return (
    <SimilarResults className={className}>
      {activities.map((activity, i) => (
        <ResultsItem
          selected={activity.activityId === entryActivityId}
        >
          <Buttons
            size="small"
            onBack={() => setSuggestedImageType((prev) => {
              prev[i] = 'front';
              return [...prev];
            })}
            onForward={() => setSuggestedImageType((prev) => {
              prev[i] = 'rear';
              return [...prev];
            })}
            disableBack={suggestedImageType[i] === 'front' || Boolean(activity?.frontImg)}
            disableForward={suggestedImageType[i] === 'rear' || Boolean(activity?.rearImg)}
          >
            <Image
              width={140}
              height={116}
              src={
                suggestedImageType[i] === 'front'
                  ? activity?.frontImg || ''
                  : activity?.rearImg || ''
              }
            />
          </Buttons>
          <SuggestedSimpleTable rowsMap={resultRowsMap} item={activity} align="left" />
        </ResultsItem>
      ))}
    </SimilarResults>
  );
};

const SimilarResults = styled.ul`
  display: flex;
  flex-direction: column;
  max-height: 633px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style: none;
`;

const ResultsItem = styled.div<{ selected?: boolean; }>`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
  ${({ selected }) => selected ? 'background-color: #B3ECD9;' : ''}
  
  &:not(:last-child) {
    margin-bottom: 27px;
  }
`;

const SuggestedSimpleTable = styled(StyledSimpleTable)`
  th {
    width: 40%;
    font-weight: 400;
  }
` as typeof SimpleTable;

const SelectButton = styled(ApplyButton)`
  margin: auto 0 0 auto;
`;

export default SuggestedActivities;
