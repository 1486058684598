import { createReducerFunction, ImmerReducer } from 'immer-reducer';

import { Device, DeviceResponse } from '../../api/models/devices';

import { RequestState } from './common';

export interface DeviceState {
  deviceState: RequestState;
  gateState: RequestState;
  gateLockState: RequestState;
  deviceData: DeviceResponse | null;
  deviceList: Device[][];
}

const initialState: DeviceState = {
  deviceState: RequestState.IDLE,
  gateState: RequestState.IDLE,
  gateLockState: RequestState.IDLE,
  deviceData: null,
  deviceList: [],
};

export class DeviceReducer extends ImmerReducer<DeviceState> {
  changeStateOfDevice(state: RequestState) {
    this.draftState.gateState = state;
  }

  changeStateOfGateLock(state: RequestState) {
    this.draftState.gateLockState = state;
  }

  openAllGates() {
    this.draftState.deviceList = this.draftState.deviceList.map((deviceMap) => {
      return deviceMap.map((device) => {
        device.gateControl = false;
        device.gateLock = false;
        device.gateLock = true;
        return device;
      });
    });
  }

  changeStateOpenAllGates(state: RequestState) {
    this.draftState.deviceState = state;
  }

  changeStateOfDevices(state: RequestState) {
    this.draftState.deviceState = state;
  }

  setDeviceData(data: DeviceResponse) {
    this.draftState.deviceData = data;
  }

  setDeviceList(data: Device[][]) {
    this.draftState.deviceList = data;
  }

  setGateLockInDeviceList(gateLock: boolean, deviceId: string) {
    this.draftState.deviceList = this.draftState.deviceList.map((deviceMap) => {
      return deviceMap.map((device) => {
        if (device.deviceId === deviceId) {
          device.gateControl = !gateLock && device.gateControl;
          device.gateLPR = 'good';
          return {
            ...device,
            gateLock,
          };
        }
        return device;
      });
    });
  }

  setDownLockInDeviceList(downLock: boolean, deviceId: string) {
    this.draftState.deviceList = this.draftState.deviceList.map((deviceMap) => {
      return deviceMap.map((device) => {
        if (device.deviceId === deviceId) {
          return {
            ...device,
            downLock,
          };
        }
        return device;
      });
    });
  }

  setErrorInDevice(deviceId: string) {
    this.draftState.deviceList = this.draftState.deviceList.map((deviceMap) => {
      return deviceMap.map((device) => {
        if (device.deviceId === deviceId) {
          device.gateLPR = 'error';
          return {
            ...device,
          };
        }
        return device;
      });
    });
  }

  setGateControlInDeviceList(gateControl: boolean, deviceId: string, gateLock: boolean) {
    this.draftState.deviceList = this.draftState.deviceList.map((deviceMap) => {
      return deviceMap.map((device) => {
        if (device.deviceId === deviceId) {
          device.gateLPR = 'good';
          return {
            ...device,
            gateControl,
            gateLock,
          };
        }
        return device;
      });
    });
  }
}

export default createReducerFunction(DeviceReducer, initialState);
