/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { listCompanyUsers } from '@zero5/user-api';

import { mapCompanyUsers } from '@/api/mappings/user';

type Response = ReturnType<typeof mapCompanyUsers>;
export const queryKey: QueryKey = 'userCompanyUsersQuery';

const useCompanyUsersQuery = (
  options?: UseQueryOptions<Response, unknown, Response, typeof queryKey>,
) => {
  return useQuery<Response, unknown, Response, typeof queryKey>(
    queryKey,
    async () => {
      const response = await listCompanyUsers();

      return mapCompanyUsers(response);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useCompanyUsersQuery;
