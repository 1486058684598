import React, { useEffect } from 'react';
import styled from 'styled-components';

import {
  Button,
  ButtonIcon,
  ConfirmationModal,
  Row,
  RowsMap,
  Table,
  TableContent,
} from '@zero5/ui';
import { centsToCost } from '@zero5/ui/lib/utils/formatters/formatPrice';

import usePermitTypeListQuery from '@/api/permitType/useGetPermitTypeListQuery';
import useDeletePermitTypeMutation from '@/api/permitType/useDeletePermitTypeMutation';
import { PermitType } from '@/api/permit/models';

import Role from '@/components/common/Role';

import DeleteIcon from '@/assets/icons/delete.inline.svg';
import InfoIcon from '@/assets/icons/dots.inline.svg';

import Title from './common/Title';
import PermitPolicyModal from './PermitPolicyModal';

interface Props {}

const Permit: React.FC<Props> = () => {
  const [modalType, setModalType] = React.useState<
  'info' | 'delete' | 'create'
  >();
  const [modalData, setModalData] = React.useState<PermitType>();

  const { mutateAsync: deletePermit, isLoading: isDeleteLoading } = useDeletePermitTypeMutation();

  const { data, isLoading, isFetching, refetch } = usePermitTypeListQuery();

  const closeModalHandler = React.useCallback(() => {
    setModalType(undefined);
  }, []);

  const deletePermitHandler = async () => {
    await deletePermit(modalData!.id).then(() => {
      closeModalHandler();
    });
  };

  const rowsMap: RowsMap<PermitType> = React.useMemo(
    () => [
      {
        title: 'Name',
        id: 'name',
        data: ({ name }) => name,
      },
      {
        title: 'Price',
        id: 'price',
        data: ({ price }) => `$ ${centsToCost(price)}`,
      },
      // {
      //   title: 'No. of Allowed Permits',
      //   id: 'allowedPermits',
      //   data: ({ allowedPermits }) => allowedPermits,
      // },
      {
        title: 'Info',
        id: 'info',
        data: (item) => (
          <Row>
            <StyledButtonIcon
              Icon={InfoIcon}
              onClick={() => {
                setModalData(item);
                setModalType('info');
              }}
            />
          </Row>
        ),
        disableSorting: true,
        HeadCellProps: {
          width: '120px',
        },
      },
      {
        title: 'Delete',
        id: 'delete',
        data: (item) => (
          <Row>
            <StyledButtonIcon
              Icon={DeleteIcon}
              onClick={() => {
                setModalData(item);
                setModalType('delete');
              }}
            />
          </Row>
        ),
        disableSorting: true,
        HeadCellProps: {
          width: '120px',
        },
      },
    ],
    [],
  );

  useEffect(() => {
    if (modalType === 'info') {
      refetch();
    }
  }, [modalType]);

  return (
    <>
      <MainTitleRow>
        <MainTitle>Permit Types</MainTitle>
        <Role widgetId="parkingPolicy:createPermit" action="w">
          <Button
            color="primary"
            variant="contained"
            id={'newPermitTypeButton'}
            onClick={() => setModalType('create')}
          >
            Create Permit Type
          </Button>
        </Role>
      </MainTitleRow>
      <TableContent isLoading={isLoading} data={data || []}>
        <Table isLoading={isFetching} rowsMap={rowsMap} data={data || []} />
      </TableContent>
      <Role widgetId="parkingPolicy:permitPolicy" action="r">
        <PermitPolicyModal
          open={modalType === 'info' || modalType === 'create'}
          title={
            modalType === 'info'
              ? 'Permit Type Information'
              : 'Create New Permit Type'
          }
          data={modalType === 'info' ? modalData : undefined}
          onClose={closeModalHandler}
          isLoading={modalType === 'info' && (isLoading || isFetching)}
        />
      </Role>
      <ConfirmationModal
        open={modalType === 'delete'}
        onAccept={() => deletePermitHandler()}
        onReject={closeModalHandler}
        text="Are you sure you want to delete"
        variableText={modalData?.name}
        loading={ isDeleteLoading }
        text2=" permit type?"
        acceptText="Delete"
      />
    </>
  );
};

const MainTitleRow = styled(Row)`
  margin-bottom: 20px;
`;

const MainTitle = styled(Title)`
  margin: 0;
`;


const StyledButtonIcon = styled(ButtonIcon)`
  margin: 0 auto;
`;

export default Permit;
