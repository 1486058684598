/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { findUserByEmail } from '@zero5/user-api';

import { mapCompanyUser } from '../mappings/user';

type Response = ReturnType<typeof mapCompanyUser> | null;

export const queryKey: QueryKey = 'userByEmailQuery';

const useFindUserByEmailQuery = (
  email: string,
  options?: UseQueryOptions<Response, unknown, Response, [typeof queryKey, string]>,
) => {
  return useQuery<Response, unknown, Response, [typeof queryKey, string]>(
    [queryKey, email],
    async () => {
      const response = await findUserByEmail(email);

      return response.user ? mapCompanyUser(response.user) : null;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useFindUserByEmailQuery;
