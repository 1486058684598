/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getInvoiceSummary } from '@zero5/payment-api';

import { queryGroupName } from './queryGroupName';
import { mapInvoiceSummary } from './mappings';

type Response = ReturnType<typeof mapInvoiceSummary>;
type Params = {
  transactionId: string;
};

export const queryKey: QueryKey = 'invoiceSummary';

type Key = [typeof queryGroupName, typeof queryKey, unknown];

const useInvoiceSummarySummaryQuery = (
  params: Params,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    [queryGroupName, queryKey, params],
    async () => {
      const response = await getInvoiceSummary(params.transactionId);

      return mapInvoiceSummary(response);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useInvoiceSummarySummaryQuery;
