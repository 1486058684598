/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import {
  getForecastedRevenueDetails,
} from '@zero5/payment-api';

import { queryGroupName } from './queryGroupName';
import { mapForecastedRevenueReportDetails } from './mappings';

type Response = ReturnType<typeof mapForecastedRevenueReportDetails>;
type Params = {
  page?: number;
  take?: number;
  from: number;
  until: number;
};

export const queryKey: QueryKey = 'forecastedRevenueDetails';

type Key = [typeof queryGroupName, typeof queryKey, Params];

const useForecastedRevenueDetailsQuery = (
  params: Params,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    [queryGroupName, queryKey, params],
    async () => {
      const response = await getForecastedRevenueDetails(params);

      return mapForecastedRevenueReportDetails(response);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      keepPreviousData: true,
      ...options,
    },
  );
};

export default useForecastedRevenueDetailsQuery;
