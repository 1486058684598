import { createReducerFunction, ImmerReducer } from 'immer-reducer';

import { DateRange } from '@zero5/ui/lib/Chart';
import { z5DateModule } from '@zero5/time-module';

import { RevenueHistoryFormat } from '@/api/revenue/models';

import {
  getTimestampOfCurrentMonthEnd,
  getTimestampOfCurrentMonthStart,
  addTime,
  getTimestampOfTodayEnd,
} from '@/utils/date/helpers';

/*
  Before adding new date property please add new transform option to store/transforms/settings.ts
*/
export interface SettingsState {
  home: {
    revenueDateRange: DateRange;
    trafficDateRange: DateRange;
  };
  events: {
    dateRange: DateRange;
  };
  tenants: {
    dateRange: DateRange;
  };
  users: {
    dateRange: DateRange;
  };
  permit: {
    dateRange: DateRange;
    trendDateRange: DateRange;
    usageDateRange: DateRange;
  };
  revenue: {
    revenueHistoryDateRange: DateRange;
    invoicesDateRange: DateRange;
    trendDateRange: DateRange;
    trendStreamDateRange: DateRange;
    revenueHistoryFormat: RevenueHistoryFormat;
  };
  traffic: {
    dateRange: DateRange;
    trendDateRange: DateRange;
    trendTypeDateRange: DateRange;
  };
  valet: {
    dateRange: DateRange;
    usageTrendDateRange: DateRange;
  };
  validationHistory: {
    dateRange: DateRange;
    trendDateRange: DateRange;
  };
}

const getLast7Days = () => {
  const startOfWeek = new Date(z5DateModule.fromUtc(Date.now()).dayStart().subtractDays(7).getTimestamp());
  const endOfWeek = new Date(getTimestampOfTodayEnd());

  return {
    start: startOfWeek,
    end: endOfWeek,
  };
};

const today = new Date();

const lastDay = {
  start: addTime(today, 'day', -1),
  end: today,
};

const getCurrentMonth = () => {
  const startOfMonth = new Date(getTimestampOfCurrentMonthStart());
  const endOfMonth = new Date(getTimestampOfCurrentMonthEnd());

  return {
    start: startOfMonth,
    end: endOfMonth,
  };
};

const initialState: SettingsState = {
  home: {
    revenueDateRange: getLast7Days(),
    trafficDateRange: getLast7Days(),
  },
  events: {
    dateRange: getCurrentMonth(),
  },
  users: {
    dateRange: getLast7Days(),
  },
  tenants: {
    dateRange: getLast7Days(),
  },
  permit: {
    dateRange: getLast7Days(),
    trendDateRange: getLast7Days(),
    usageDateRange: getLast7Days(),
  },
  revenue: {
    revenueHistoryDateRange: lastDay,
    invoicesDateRange: getLast7Days(),
    trendDateRange: getLast7Days(),
    trendStreamDateRange: getLast7Days(),
    revenueHistoryFormat: 'daily',
  },
  traffic: {
    dateRange: getLast7Days(),
    trendDateRange: getLast7Days(),
    trendTypeDateRange: getLast7Days(),
  },
  valet: {
    dateRange: getLast7Days(),
    usageTrendDateRange: getLast7Days(),
  },
  validationHistory: {
    dateRange: getLast7Days(),
    trendDateRange: getLast7Days(),
  },
};

export class SettingsReducer extends ImmerReducer<SettingsState> {
  setHomeSettings(settings: Partial<SettingsState['home']>) {
    (Object.keys(settings) as Array<keyof SettingsState['home']>).forEach((key) => {
      if (settings[key]) {
        this.draftState.home[key] = settings[key]!;
      }
    });
  }

  setEventsSettings(settings: Partial<SettingsState['events']>) {
    (Object.keys(settings) as Array<keyof SettingsState['events']>).forEach((key) => {
      if (settings[key]) {
        this.draftState.events[key] = settings[key]!;
      }
    });
  }

  setTenantsSettings(settings: Partial<SettingsState['tenants']>) {
    (Object.keys(settings) as Array<keyof SettingsState['tenants']>).forEach((key) => {
      if (settings[key]) {
        this.draftState.tenants[key] = settings[key]!;
      }
    });
  }

  setUsersSettings(settings: Partial<SettingsState['users']>) {
    (Object.keys(settings) as Array<keyof SettingsState['users']>).forEach((key) => {
      if (settings[key]) {
        this.draftState.users[key] = settings[key]!;
      }
    });
  }

  setPermitSettings(settings: Partial<SettingsState['permit']>) {
    (Object.keys(settings) as Array<keyof SettingsState['permit']>).forEach((key) => {
      if (settings[key]) {
        this.draftState.permit[key] = settings[key]!;
      }
    });
  }

  setRevenueSettings(settings: Partial<SettingsState['revenue']>) {
    (Object.keys(settings) as Array<keyof SettingsState['revenue']>).forEach((key) => {
      if (settings[key]) {
        // key to key assign; types are the same
        (this.draftState.revenue[key] as DateRange | RevenueHistoryFormat) = settings[key]!;
      }
    });
  }

  setTrafficSettings(settings: Partial<SettingsState['traffic']>) {
    (Object.keys(settings) as Array<keyof SettingsState['traffic']>).forEach((key) => {
      if (settings[key]) {
        this.draftState.traffic[key] = settings[key]!;
      }
    });
  }

  setValetSettings(settings: Partial<SettingsState['valet']>) {
    (Object.keys(settings) as Array<keyof SettingsState['valet']>).forEach((key) => {
      if (settings[key]) {
        this.draftState.valet[key] = settings[key]!;
      }
    });
  }

  setValidationHistorySettings(settings: Partial<SettingsState['validationHistory']>) {
    (Object.keys(settings) as Array<keyof SettingsState['validationHistory']>).forEach((key) => {
      if (settings[key]) {
        this.draftState.validationHistory[key] = settings[key]!;
      }
    });
  }

  updateTimezone() {
    this.draftState.home.revenueDateRange = getLast7Days();
    this.draftState.home.trafficDateRange = getLast7Days();

    this.draftState.events.dateRange = getCurrentMonth();

    this.draftState.users.dateRange = getLast7Days();

    this.draftState.tenants.dateRange = getLast7Days();

    // Wasn't sure why this was set up for the month
    // this.draftState.permit.dateRange = getCurrentMonth();
    // this.draftState.permit.trendDateRange = getCurrentMonth();
    // this.draftState.permit.usageDateRange = getCurrentMonth();
    this.draftState.permit.dateRange = getCurrentMonth();
    this.draftState.permit.trendDateRange = getLast7Days();
    this.draftState.permit.usageDateRange = getLast7Days();    

    this.draftState.revenue.revenueHistoryDateRange = getLast7Days();
    this.draftState.revenue.invoicesDateRange = getLast7Days();
    this.draftState.revenue.trendDateRange = getLast7Days();
    this.draftState.revenue.trendStreamDateRange = getLast7Days();

    this.draftState.traffic.dateRange = getLast7Days();
    this.draftState.traffic.trendDateRange = getLast7Days();
    this.draftState.traffic.trendTypeDateRange = getLast7Days();

    this.draftState.valet.dateRange = getLast7Days();
    this.draftState.valet.usageTrendDateRange = getLast7Days();

    this.draftState.validationHistory.dateRange = getLast7Days();
    this.draftState.validationHistory.trendDateRange = getLast7Days();
  }
}

export default createReducerFunction(SettingsReducer, initialState);
