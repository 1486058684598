/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import {
  getTransactionsSummary,
} from '@zero5/payment-api';

import { queryGroupName } from './queryGroupName';
import { mapRevenueSummary } from './mappings';

type Response = ReturnType<typeof mapRevenueSummary>;
type Params = {
  from: number;
  until: number;
};

export const queryKey: QueryKey = 'transactionsSummary';

type Key = [typeof queryGroupName, typeof queryKey, Params];

const useTransactionsSummaryQuery = (
  params: Params,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    [queryGroupName, queryKey, params],
    async () => {
      const response = await getTransactionsSummary(params);

      return mapRevenueSummary(response);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useTransactionsSummaryQuery;
