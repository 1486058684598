// eslint-disable-next-line max-len
export const regexEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const regexPassword = /^(?=.*[^\d]+)(?=.*\d)[A-Za-z\x20-\x7E\n\d+]{8,}$/;
export const regexSpecialCharacters = /[$&+,_:;"/\-\\~№`\\{\\}=?@#|'<>.^*\\(\\)%!]/;
export const regexAllSpecialCharacters = /[$&+,_:;"/\-\\~№`\\{\\}=?@#|'<>.^*\\(\\)%!]/g;
export const regexWords = /^[-a-zA-Z\s]*$/g;
export const regexAlphanumeric = /^[a-zA-Z0-9\s]*$/g;
export const regexPhoneNumber = /^\+\d{11,}$/g;
export const regexTenantName = /^(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/g;
export const regenNumbers = /^\d+$/g;
export const wordWithoutSpaces = /^\S*$/;
export const cyrillicPattern = /[а-яА-ЯЁё]/;
export const regexNumbersAlphabets = /^[a-zA-Z0-9]*$/;
