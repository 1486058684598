/* eslint-disable max-len */
import { QueryKey, useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';

import { getLatestActivities } from '@zero5/activity-api';

import { mapActivitiesResponse } from '@/api/mappings/activity';

import { loadImage } from '@/utils/image';

import { ACTIVITY_QUERY } from '@controlCenter/api/activity/constants';

type Response = ReturnType<typeof mapActivitiesResponse>;

export const queryKey: QueryKey = ACTIVITY_QUERY;
export const defaultPerPage = 30;

type Key = [typeof queryKey, number];

const useActivitiesQuery = (
  options?: UseInfiniteQueryOptions<Response, unknown, Response, Response, Key>,
  perPage: number = defaultPerPage,
) => {
  return useInfiniteQuery<Response, unknown, Response, Key>(
    [queryKey, perPage],
    async ({ pageParam }) => {
      const response = await getLatestActivities({
        take: perPage,
        fromDate: pageParam,
      });

      response.activities.forEach(({ data: { frontImg, rearImg } }) => {
        if (frontImg) {
          loadImage(frontImg);
        }
        if (rearImg) {
          loadImage(rearImg);
        }
      });

      return mapActivitiesResponse(response);
    },
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => {
        if (lastPage.length < perPage) return undefined;

        return lastPage[lastPage.length - 1].eventTime;
      },
      ...options,
    },
  );
};

export default useActivitiesQuery;
