import React from 'react';
import styled from 'styled-components';
import produce from 'immer';
import difference from 'lodash/difference';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import {
  Button, ButtonIcon, Checkbox, Row, Select, InputPrice,
} from '@zero5/ui';
import toast from '@zero5/ui/lib/utils/toast';
import vars from '@zero5/ui/lib/styles/vars';
import { centsToCost, costToCents } from '@zero5/ui/lib/utils/formatters/formatPrice';
import { capitalize } from '@zero5/ui/lib/utils/formatters/capitalize';

import useGarageHoursOpenQuery from '@/api/garage/useGarageHoursOpenQuery';
import useAddGarageHoursOpenMutation from '@/api/garage/useAddGarageHoursOpenMutation';
import useUpdateGarageHoursOpenMutation from '@/api/garage/useUpdateGarageHoursOpenMutation';
import useDeleteGarageHoursOpenMutation from '@/api/garage/useDeleteGarageHoursOpenMutation';
import useGarageOptionsQuery, { queryKey as garageOptionsQueryKey } from '@/api/queries/useGarageOptionsQuery';
import useUpdateGarageOptionsMutation from '@/api/mutations/useUpdateGarageOptionsMutation';
import { EventParkingFeeType, GarageDay, GarageHoursPolicy, GarageOptions } from '@/api/models/garages';
import { unmapGarageHoursPolicies } from '@/api/mappings/garages';
import { GARAGE_HOURS_OPEN_QUERY, GARAGE_QUERY } from '@/api/garage/constants';

import { selectCurrentGarage } from '@/store/selectors/workspace';

import { useFindCurrentAction } from '@/components/common/Role';

import { feeOptions, GracePeriodOptions, hoursOptions } from '@/utils/options';
import { handleError } from '@/utils/handleError';
import useDateModule from '@/utils/date/useDateModule';

import MinusIcon from '@/assets/icons/minus.inline.svg';
import PlusIcon from '@/assets/icons/plus.inline.svg';

import TimePicker from '../common/TimePicker';

import Title from './common/Title';
import Table, { HeadCell, TableCell } from './common/Table';
import { setHoursAndMinutes } from './utils/setHoursAndMinutes';
import { sortPolicyArray } from './utils/sortPolicyArray';
import { Policy } from './utils/types';

interface Props {
  initialPolicies: Array<GarageHoursPolicy>;
  initialOptions: GarageOptions;
}


const addKeyToPolicies = (policies: Array<GarageHoursPolicy>): Array<Policy> => {
  return sortPolicyArray(policies.map((item) => ({ ...item, key: item.id }))) as Array<Policy>;
};

const removeKeyFromPolicies = (policies: Array<Policy>) => {
  return sortPolicyArray(policies.map(({ key, ...rest }) => ({ ...rest }))) as Array<Policy>;
};


const HoursAndFees: React.FC<Props> = ({ initialPolicies, initialOptions }) => {
  const queryClient = useQueryClient();
  const findCurrentAction = useFindCurrentAction();

  const { formatTimeStampToString } = useDateModule();

  const [policies, setPolicies] = React.useState<Array<Policy>>(addKeyToPolicies(initialPolicies));
  const [options, setOptions] = React.useState<GarageOptions>(initialOptions);

  const currentGarage = useSelector(selectCurrentGarage);

  const {
    data: hoursPoliciesData,
    isRefetching: hoursPoliciesRefetching,
  } = useGarageHoursOpenQuery({
    onSuccess: (data) => setPolicies(addKeyToPolicies(data)),
    refetchOnMount: false,
  });

  const {
    data: garageOptions,
    isRefetching: garageOptionsRefetching,
  } = useGarageOptionsQuery({
    onSuccess: (data) => setOptions(data),
    refetchOnMount: false,
  });

  const addGarageHoursOpenMutation = useAddGarageHoursOpenMutation({ onSuccess: () => {} });
  const updateGarageHoursOpenMutation = useUpdateGarageHoursOpenMutation({ onSuccess: () => {} });
  const deleteGarageHoursOpenMutation = useDeleteGarageHoursOpenMutation({ onSuccess: () => {} });
  const updateGarageOptionsMutation = useUpdateGarageOptionsMutation();

  const addedPolicies = React.useMemo(() => {
    return policies?.filter(({ id }) => !id);
  }, [policies]);

  const deletedPolicies = React.useMemo(() => {
    const initialIds = hoursPoliciesData?.map(({ id }) => id);
    const currentIds = policies?.map(({ id }) => id).filter((item) => Boolean(item)) as Array<number> | undefined;

    if (initialIds && currentIds) {
      return difference(initialIds, currentIds);
    }
  }, [hoursPoliciesData, policies]);

  const updatedPolicies = React.useMemo(() => {
    if (policies && hoursPoliciesData) {
      return differenceWith(
        removeKeyFromPolicies(policies.filter(({ id }) => Boolean(id))),
        hoursPoliciesData,
        isEqual,
      ) as Array<GarageHoursPolicy>;
    }
  }, [policies, hoursPoliciesData]);

  const saveHandler = React.useCallback(async () => {
    try {
      if (deletedPolicies?.length) {
        await deleteGarageHoursOpenMutation.mutateAsync({
          hoursOpenIds: deletedPolicies,
        });
      }

      if (updatedPolicies?.length) {
        await updateGarageHoursOpenMutation.mutateAsync({
          hoursOpen: unmapGarageHoursPolicies(updatedPolicies),
        });
      }

      if (addedPolicies?.length) {
        await addGarageHoursOpenMutation.mutateAsync({
          hoursOpen: unmapGarageHoursPolicies(
            removeKeyFromPolicies(addedPolicies)
              .map((item) => ({ ...item, id: 1 })),
          )
            .map(({ id, ...rest }) => ({ ...rest })),
        });
      }

      if (options && !isEqual(options, garageOptions)) {
        await updateGarageOptionsMutation.mutateAsync(options);
      }

      await queryClient.invalidateQueries(garageOptionsQueryKey);

      toast('success', 'Changes saved successfully!');
    } catch (error) {
      handleError(error, 'Error while saving policies!');
    } finally {
      await queryClient.refetchQueries([GARAGE_QUERY, GARAGE_HOURS_OPEN_QUERY]);
    }
  }, [
    addedPolicies,
    updatedPolicies,
    deletedPolicies,
    options,
    garageOptions,
    addGarageHoursOpenMutation,
    updateGarageHoursOpenMutation,
    deleteGarageHoursOpenMutation,
    updateGarageOptionsMutation,
    queryClient,
  ]);

  const cancelHandler = React.useCallback(() => {
    setPolicies(addKeyToPolicies(hoursPoliciesData!));
    setOptions(garageOptions!);
  }, [garageOptions, hoursPoliciesData]);

  const dirty = React.useMemo(() => {
    return Boolean(addedPolicies.length)
    || Boolean(updatedPolicies?.length)
    || Boolean(deletedPolicies?.length)
    || !isEqual(options, garageOptions);
  }, [addedPolicies.length, deletedPolicies?.length, garageOptions, options, updatedPolicies?.length]);

  const dataLoading = React.useMemo(() => {
    return addGarageHoursOpenMutation.isLoading
    || updateGarageHoursOpenMutation.isLoading
    || deleteGarageHoursOpenMutation.isLoading
    || updateGarageOptionsMutation.isLoading
    || hoursPoliciesRefetching
    || garageOptionsRefetching;
  }, [
    addGarageHoursOpenMutation.isLoading,
    deleteGarageHoursOpenMutation.isLoading,
    garageOptionsRefetching,
    hoursPoliciesRefetching,
    updateGarageHoursOpenMutation.isLoading,
    updateGarageOptionsMutation.isLoading,
  ]);

  const moneySectionHidden = React.useMemo(() => {
    return findCurrentAction('parkingPolicy:hoursAndFees:money') === 'h';
  }, [findCurrentAction]);

  const moneySectionReadOnly = React.useMemo(() => {
    return findCurrentAction('parkingPolicy:hoursAndFees:money') === 'r';
  }, [findCurrentAction]);

  const hoursSectionReadOnly = React.useMemo(() => {
    return findCurrentAction('parkingPolicy:hoursAndFees:hours') === 'r';
  }, [findCurrentAction]);

  const addPolicyHandler = (idx: number, day: GarageDay) => {
    setPolicies((prev) => {
      const unsorted = produce(prev, (draft) => {
        draft!.splice(
          idx + 1,
          0,
          {
            day,
            start: '0000',
            end: '0000',
            cost: 0,
            isClosed: false,
            key: Math.random(),
            garageId: currentGarage?.garageId!,
          },
        );
      });
      return sortPolicyArray(unsorted);
    });
  };

  const deletePolicyHandler = (idx: number) => {
    setPolicies((prev) => {
      const unsorted = produce(prev, (draft) => {
        delete draft![idx];
      })?.filter((item) => Boolean(item));
    
      return sortPolicyArray(unsorted);
    },
    );
  };

  const changeHoursHandler = (
    date: Date | [Date | null, Date | null] | null,
    idx: number,
    timeField: 'start' | 'end',
  ) => {
    if (date instanceof Date){
      setPolicies((prev) => {
        const unsorted = produce(prev, (draft) => {                        
          draft![idx][timeField] = formatTimeStampToString(date.getTime(), 'HHmm');
        });
        return sortPolicyArray(unsorted);
      });
    }
  };

  return (
    <>
      <Title>Hours and Fees</Title>
      <Table minWidth="655px">
        <tr>
          <HeadCell />
          <HeadCell style={{ width: 240 }}>Time</HeadCell>
          {!moneySectionHidden && (
          <HeadCell colSpan={2}>Hourly Rate</HeadCell>
          )}
        </tr>
        {policies?.map(({
          day, start, end, cost, isClosed, key,
        }, idx, array) => (
          <tr key={key}>
            <TableCell>{array[idx - 1]?.day === day ? '' : capitalize(day)}</TableCell>
            <TableCell>
              <TimePickerRow>
                <StyledTimePicker
                  pickerProps={{
                    disabled: hoursSectionReadOnly || isClosed,
                    selected: setHoursAndMinutes(start),
                    onChange: (date) => changeHoursHandler(date, idx, 'start'),
                  }}
                />
                -
                <StyledTimePicker
                  pickerProps={{
                    disabled: hoursSectionReadOnly || isClosed,
                    selected: setHoursAndMinutes(end),
                    injectTimes: [
                      new Date(new Date(new Date().setHours(23)).setMinutes(59)),
                    ],
                    onChange: (date) => changeHoursHandler(date, idx, 'end'),
                  }}
                />
              </TimePickerRow>
            </TableCell>
            {!moneySectionHidden && (
            <TableCell>
              <InlineInputPrice
                value={centsToCost(cost)}
                onChangeValue={(value) => {
                  setPolicies((prev) => produce(prev, (draft) => {
                    draft![idx].cost = costToCents(value);
                  }));
                }}
                disabled={moneySectionReadOnly || isClosed}
              />
            </TableCell>
            )}
            <TableCell>
              <PolicyControlsWrapper>
                <ButtonIcon
                  iconProps={{
                    width: '20px',
                    fill: moneySectionReadOnly || isClosed ? vars.palette.secondary : vars.palette.primary,
                  }}
                  disabled={moneySectionReadOnly || isClosed}
                  Icon={PlusIcon}
                  onClick={()=>addPolicyHandler(idx, day)}
                />
                {policies.filter(({ day: policyDay }) => day === policyDay)?.length > 1 && (
                  <ButtonIcon
                    iconProps={{
                      width: '20px',
                      fill: moneySectionReadOnly || isClosed ? vars.palette.secondary : vars.palette.danger,
                    }}
                    disabled={moneySectionReadOnly || isClosed}
                    Icon={MinusIcon}
                    onClick={()=> deletePolicyHandler(idx)}
                  />
                )}
                {array[idx - 1]?.day === day ? null : (
                  <TableCheckboxLabel>
                    <Checkbox
                      checked={isClosed}
                      onChange={(event, checked) => {
                        setPolicies((prev) => produce(prev, (draft) => {
                          draft!.forEach((item) => {
                            if (item.day !== day) return;

                            item.isClosed = checked;
                          });
                        }));
                      }}
                      disabled={hoursSectionReadOnly}
                    />
                    Closed
                  </TableCheckboxLabel>
                )}
              </PolicyControlsWrapper>
            </TableCell>
          </tr>
        ))}
        <tr>
          <TableCell>Grace Period</TableCell>
          <TableCell>
            <GracePeriodRow>
              <GracePeriodSelect
                options={GracePeriodOptions}
                placeholder=""
                isDisabled={hoursSectionReadOnly || !options?.isGracePeriodMinApplicable}
                menuPortalTarget={document.body}
                value={
                  !options?.isGracePeriodMinApplicable
                    ? ''
                    : GracePeriodOptions.find((option) => option.value === options?.gracePeriodMin)
                }
                onChange={(option: { label: string; value: number; }) => {
                  setOptions((prev) => produce(prev, (draft) => {
                    draft!.gracePeriodMin = option.value;
                  }));
                }}
              />
              <LineCheckboxLabel>
                <Checkbox
                  checked={!options?.isGracePeriodMinApplicable}
                  onChange={(e, checked) => {
                    setOptions((prev) => produce(prev, (draft) => {
                      draft!.isGracePeriodMinApplicable = !checked;
                    }));
                  }}
                  disabled={hoursSectionReadOnly}
                />
                Not Applicable
              </LineCheckboxLabel>
            </GracePeriodRow>
          </TableCell>
        </tr>
      </Table>
      {!moneySectionHidden && (
        <>
          <Title>Other Fees</Title>
          <PolicyRow>
            <TextInputPrice
              label="Maximum Daily Fee"
              disabled={moneySectionReadOnly || !options?.isMaximumDailyFeeApplicable}
              value={!options?.isMaximumDailyFeeApplicable ? centsToCost(0) : centsToCost(options.maximumDailyFee)}
              onChangeValue={(value) => {
                setOptions((prev) => produce(prev, (draft) => {
                  draft!.maximumDailyFee = costToCents(value);
                }));
              }}
            />
            <LineCheckboxLabel>
              <Checkbox
                checked={!options?.isMaximumDailyFeeApplicable}
                onChange={(e, checked) => {
                  setOptions((prev) => produce(prev, (draft) => {
                    draft!.isMaximumDailyFeeApplicable = !checked;
                  }));
                }}
                disabled={moneySectionReadOnly}
              />
              Not Applicable
            </LineCheckboxLabel>
          </PolicyRow>
          <PolicyRow>
            <ParkingFeeSelect
              label="Event Parking Fee"
              placeholder=""
              options={feeOptions}
              isDisabled={moneySectionReadOnly || !options?.isEventParkingFeeApplicable}
              value={
                !options?.isEventParkingFeeApplicable
                  ? ''
                  : feeOptions.find((option) => option.value === options?.eventParkingFeeType)
              }
              onChange={(option: { label: string; value: EventParkingFeeType; }) => {
                setOptions((prev) => produce(prev, (draft) => {
                  draft!.eventParkingFeeType = option.value;
                }));
              }}
            />
            <TextInputPrice
              value={!options?.isEventParkingFeeApplicable ? centsToCost(0) : centsToCost(options.eventParkingFee)}
              disabled={moneySectionReadOnly || !options?.isEventParkingFeeApplicable}
              onChangeValue={(value) => {
                setOptions((prev) => produce(prev, (draft) => {
                  draft!.eventParkingFee = costToCents(value);
                }));
              }}
            />
            <LineCheckboxLabel>
              <Checkbox
                checked={!options?.isEventParkingFeeApplicable}
                onChange={(e, checked) => {
                  setOptions((prev) => produce(prev, (draft) => {
                    draft!.isEventParkingFeeApplicable = !checked;
                  }));
                }}
                disabled={moneySectionReadOnly}
              />
              Not Applicable
            </LineCheckboxLabel>
          </PolicyRow>
          <InlineWrapper>
            Event parking fee will apply starting
            {' '}
            <HoursSelect
              options={hoursOptions}
              value={hoursOptions.find((option) => parseFloat(option.value) * 60 === options?.eventBeforeGraceMin)}
              onChange={(option) => {
                setOptions((prev) => produce(prev, (draft) => {
                  draft!.eventBeforeGraceMin = parseFloat(option!.value) * 60;
                }));
              }}
              menuPlacement="auto"
              isDisabled={moneySectionReadOnly}
            />
            {' '}
            before the event to
            {' '}
            <HoursSelect
              options={hoursOptions}
              value={hoursOptions.find((option) => parseFloat(option.value) * 60 === options?.eventAfterGraceMin)}
              onChange={(option) => {
                setOptions((prev) => produce(prev, (draft) => {
                  draft!.eventAfterGraceMin = parseFloat(option!.value) * 60;
                }));
              }}
              menuPlacement="auto"
              isDisabled={moneySectionReadOnly}
            />
            {' '}
            after the event.
          </InlineWrapper>
          <Row justifyContent="flex-end">
            {dirty && (
            <CancelButton
              color="primary"
              variant="text"
              onClick={cancelHandler}
              disabled={dataLoading}
            >
              Cancel
            </CancelButton>
            )}
            <Button
              id="parking-policy-save-btn"
              color="primary"
              variant="contained"
              onClick={saveHandler}
              loading={dataLoading}
              disabled={!dirty}
              // data-test="parking-policy-save-btn"
            >
              Save
            </Button>
          </Row>
        </>
      )}
    </>
  );
};

const InlineInputPrice = styled(InputPrice)`
  min-width: 80px;
`;

const StyledTimePicker = styled(TimePicker)`
  width: 150px;
  & input { 
    text-align: center;
  }
`;

const PolicyControlsWrapper = styled.div`
  display: grid;
  grid-template-columns: 36px 36px 1fr;
  grid-column-gap: 15px;
`;

const CheckboxLabel = styled.label`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
  align-items: center;
`;

const TableCheckboxLabel = styled(CheckboxLabel)`
  grid-column: 3 / 4;
`;

const LineCheckboxLabel = styled(CheckboxLabel)`
  padding: 10px 0;
  white-space: nowrap;
`;

const PolicyRow = styled(Row).attrs({
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  wrap: 'wrap',
})`
  margin-bottom: 20px;
  padding-left: 5px;

  & > * {
    margin: 5px;
  }
`;

const TextInputPrice = styled(InputPrice)`
  min-width: 120px;
`;

const InlineWrapper = styled.div`
  margin-bottom: 20px;
  padding-left: 5px;
  line-height: 40px;

  & > * {
    display: inline-block;
  }
`;

const ParkingFeeSelect = styled(Select)`
  min-width: 150px;
`;

const GracePeriodSelect = styled(Select)`
  margin-right:12px;
  width: 150px;
`;

const HoursSelect = styled(Select)`
  min-width: 150px;
  line-height: 1;
` as typeof Select;

const CancelButton = styled(Button)`
  margin-right: 10px;
`;

const TimePickerRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const GracePeriodRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
export default HoursAndFees;
