import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Input, Modal, ModalProps, Button, Row, InputPhone, Select,
} from '@zero5/ui';
import schemas from '@zero5/ui/lib/utils/validation/schemas';
import vars from '@zero5/ui/lib/styles/vars';
import { capitalize } from '@zero5/ui/lib/utils/formatters/capitalize';

import { CompanyUser, Role as UserRole } from '@/api/models/users';
import useCreateUserMutation from '@/api/mutations/useCreateUserMutation';
import useUserRolesQuery from '@/api/queries/useUserRolesQuery';
import useUpdateUserMutation from '@/api/mutations/useUpdateUserMutation';
import useFindUserByEmailQuery from '@/api/queries/useUserByEmail';

import Role, { useFindCurrentAction } from '@/components/common/Role';
import InputLoading from '@/components/common/InputLoading';

import { handleError } from '@/utils/handleError';

import UserImage from '@/assets/images/user.svg';

interface Props extends Omit<ModalProps, 'children'> {
  user?: CompanyUser | null;
  modalType?: string;
}

const AddUpdateUserModal: React.FC<Props> = ({
  user, onClose, modalType, ...props
}) => {
  return (
    <StyledModal
      title={modalType === 'update' ? 'Edit Account' : 'New Account'}
      fullScreenSize="580px"
      onClose={onClose}
      {...props}
    >
      <Content
        onFinished={onClose}
        user={user}
      />
    </StyledModal>
  );
};

type ContentProps = Pick<Props, 'user'> & {
  onFinished: () => void;
};

const Content: React.FC<ContentProps> = ({
  onFinished,
  user,
}) => {
  const findCurrentAction = useFindCurrentAction();

  const createUserMutation = useCreateUserMutation({
    onSuccess: () => {
      onFinished();
    },
    onError: (error) => handleError(error, 'Error while creating user!'),
  });
  const updateUserMutation = useUpdateUserMutation({
    onSuccess: () => {
      onFinished?.();
    },
    onError: (error) => handleError(error, 'Error while updating user!'),
  });

  const roles = useUserRolesQuery();

  const formik = useFormik<{
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    role: UserRole | null;
  }>({
    initialValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      phone: user?.phone || '',
      role: user?.role || null,
    },
    onSubmit: async ({
      firstName,
      lastName,
      email,
      phone,
      role,
    }) => {
      const capitalizeFirstName = capitalize(firstName);
      const capitalizeLastName = capitalize(lastName);
      if (user) {
        await updateUserMutation.mutateAsync({
          userId: user.userId,
          updates: {
            firstName: capitalizeFirstName,
            lastName: capitalizeLastName,
            phone,
            roleId: role!.id,
          },
        });
      } else {
        await createUserMutation.mutateAsync({
          firstName: capitalizeFirstName,
          lastName: capitalizeLastName,
          email,
          phone,
          roleId: role!.id,
        });
      }
    },
    validationSchema: Yup.object().shape({
      firstName: schemas.nameSchema.label('First Name'),
      lastName: schemas.nameSchema.label('Last Name'),
      email: schemas.emailSchema,
      phone: schemas.phoneSchema,
      role: Yup.object()
        .nullable()
        .required()
        .label('Role'),
    }),
  });

  const readOnly = React.useMemo(() => {
    return findCurrentAction('user') === 'r';
  }, [findCurrentAction]);

  const transformedInputChangeHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => {
      formik.setFieldValue(e.target.name, value);
    }, [formik],
  );

  const {
    data, isLoading, isSuccess,
  } = useFindUserByEmailQuery(
    formik.values.email,
    {
      enabled: !user && schemas.emailSchema.isValidSync(formik.values.email),
      onSuccess: (userData) => {
        if (!userData) return;

        formik.setValues((values) => ({
          ...values,
          firstName: userData.firstName,
          lastName: userData.lastName,
          phone: userData.phone,
          role: userData.role || null,
        }));
      },
    },
  );

  const isUserFieldDisabled = useMemo(
    () => ((isLoading || !isSuccess || !!data) && !user),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, isSuccess, data],
  );

  return (
    <Wrapper data-test="new-account-modal">
      <ImageWrapper>
        <StyledImage src={UserImage} />
      </ImageWrapper>
      <Form onSubmit={formik.handleSubmit}>
        <SectionTitle>
          User Information
        </SectionTitle>
        <Grid>
          <StyledInput
            id="email"
            name="email"
            label="Email"
            onChange={transformedInputChangeHandler}
            value={formik.values.email}
            error={Boolean(formik.touched.email && formik.errors.email)}
            helperText={formik.errors.email}
            disabled={Boolean(user) || readOnly}
            columns={6}
            transform="lowercase"
            endAdornment={(<InputLoading isLoading={isLoading} />)}
          />
          <StyledInputPhone
            id="phone"
            name="phone"
            disabled={isUserFieldDisabled || readOnly}
            label="Phone Number"
            onChangeValue={(newPhone) => formik.setFieldValue('phone', newPhone)}
            value={formik.values.phone}
            error={Boolean(formik.touched.phone && formik.errors.phone)}
            helperText={formik.errors.phone}
            columns={6}
          />
          <StyledInput
            id="firstName"
            name="firstName"
            label="First Name"
            disabled={isUserFieldDisabled || readOnly}
            onChange={transformedInputChangeHandler}
            value={formik.values.firstName}
            error={Boolean(formik.touched.firstName && formik.errors.firstName)}
            helperText={formik.errors.firstName}
            columns={6}
            transform="capitalize"
          />
          <StyledInput
            id="lastName"
            name="lastName"
            label="Last Name"
            onChange={transformedInputChangeHandler}
            disabled={isUserFieldDisabled || readOnly}
            value={formik.values.lastName}
            error={Boolean(formik.touched.lastName && formik.errors.lastName)}
            helperText={formik.errors.lastName}
            columns={6}
            transform="capitalize"
          />
          <StyledSelect
            label="Role"
            columns={6}
            options={roles.data}
            onChange={(value) => {
              formik.setFieldValue('role', value);
            }}
            isDisabled={isUserFieldDisabled || readOnly}
            value={formik.values.role}
            getOptionValue={(value) => value.id.toString()}
            getOptionLabel={(value) => value.label || ''}
            isLoading={roles.isLoading}
            error={Boolean(formik.touched.role && formik.errors.role)}
            helperText={formik.errors.role}
            id="select-new-account-role"
          />
        </Grid>
        <StyledRow justifyContent="flex-end">
          <Role widgetId="user" action="w">
            <StyledButton
              color="primary"
              variant="contained"
              type="submit"
              disabled={user ? !formik.dirty : Boolean(data) || isLoading}
              loading={formik.isSubmitting}
              data-test="submit-new-account-btn"
            >
              {user ? 'Update' : 'Create'}
            </StyledButton>
          </Role>
          <Button
            onClick={onFinished}
            variant="text"
            color="primary"
          >
            Cancel
          </Button>
        </StyledRow>
      </Form>
    </Wrapper>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
  align-items: end;
  margin-bottom: 20px;
`;

const columnStyles = css<{ columns: number; }>`
  grid-column: span ${({ columns }) => columns};
  grid-row: span 1;

  @media (max-width: 520px) {
    grid-column: span 12;
  }
`;

const StyledInput = styled(Input)<{ columns: number; }>`
  ${columnStyles}
`;

const StyledInputPhone = styled(InputPhone)<{ columns: number; }>`
  ${columnStyles}
`;

const StyledSelect = styled(Select)`
  ${columnStyles}
` as typeof Select;

const SectionTitle = styled.div`
  margin-bottom: 20px;
  font-weight: 600;
`;

const StyledButton = styled(Button)`
  width: 80px;
  margin-right: 10px;
`;

const StyledModal = styled(Modal)`
  @media (max-width: 1220px) {
    margin: auto 30px;
  }

  @media (max-width: 800px) {
    width: 90%;
    margin: auto 30px;
  }

  @media (max-width: 580px) {
    width: auto;
    margin: auto;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(250px, 380px) minmax(420px, 700px);

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 370px 1fr;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  border-left: 1px solid ${vars.palette.border};

  @media (max-width: 800px) {
    padding-left: 0;
    padding-top: 20px;
    border-left: none;
    border-top: 1px solid ${vars.palette.border};
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img``;

const StyledRow = styled(Row)`
  margin-top: auto;
`;

export default AddUpdateUserModal;
