import { AuthEngine } from '@zero5/ui-utils';

import clientAdminAuthApiProvider from '@/api/user/ClientAdminAuthApiProvider';

import history from '@/utils/history';
import { clearUserData } from '@/utils/authorization';

import { DEV_GARAGE_ID } from '@/devConstants';

const user = new AuthEngine({
  baseURL: process.env.API_GATEWAY_URL!,
  authRedirectUrl: '/login',
  history,
  garageId: DEV_GARAGE_ID,
  authApiProvider: clientAdminAuthApiProvider,
});

user.subscribe('onSignOut', clearUserData);

export default user;
