const eligibleTenantTypes = [
  'Management',
  'Corporate',
  'Entertainment',
  'Retail',
  'Hotel',
  'Restaurant',
];

export default eligibleTenantTypes;
