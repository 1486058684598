import callSoundUrl from '@/assets/sounds/call-ring-sound.mp3';

class CallSound {
  private audio: HTMLAudioElement;
  private shouldPlay: boolean;

  constructor() {
    this.audio = new Audio(callSoundUrl);
    this.audio.loop = true;
    // indicates if audio should playing when user interacts with website
    this.shouldPlay = false;
  }

  async play() {
    try {
      this.shouldPlay = true;

      await this.audio.play();
    } catch (e) {
      console.error(e);

      // subscribe to user interaction to unlock audio playing
      document.addEventListener('touchstart', this.unlock, true);
      document.addEventListener('touchend', this.unlock, true);
      document.addEventListener('click', this.unlock, true);
      document.addEventListener('keydown', this.unlock, true);
    }
  }

  stop() {
    this.stopSound();
    this.shouldPlay = false;
  }

  private stopSound() {
    this.audio.pause();
    this.audio.currentTime = 0;
  }

  private unlock = () => {
    if (this.shouldPlay) {
      this.play();
    }

    // remove subscription as it no longer needed after interaction
    document.removeEventListener('touchstart', this.unlock, true);
    document.removeEventListener('touchend', this.unlock, true);
    document.removeEventListener('click', this.unlock, true);
    document.removeEventListener('keydown', this.unlock, true);
  };
}

export const callSound = new CallSound();
