/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getInvoiceReportDetails } from '@zero5/payment-api';

import { queryGroupName } from './queryGroupName';
import { mapInvoiceReportDetails } from './mappings';

type Response = ReturnType<typeof mapInvoiceReportDetails>;
type Params = {
  transactionId: string;
  page?: number;
  take?: number;
};

export const queryKey: QueryKey = 'invoiceReportTransactions';

type Key = [typeof queryGroupName, typeof queryKey, unknown];

const useInvoiceReportDetailsQuery = (
  params: Params,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    [queryGroupName, queryKey, params],
    async () => {
      const response = await getInvoiceReportDetails(params);

      return mapInvoiceReportDetails(response);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useInvoiceReportDetailsQuery;
