/* eslint-disable max-len */
import { useQuery, UseQueryOptions } from 'react-query';

import { getRevenueDetails } from '@zero5/payment-api';

import { mapRevenueDetails } from './mappings';
import { revenueQueryKeys } from './queryKeys';

type Response = ReturnType<typeof mapRevenueDetails>;
type Key = ReturnType<typeof revenueQueryKeys.revenueDetails>;

const useRevenueDetailsQuery = (
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    revenueQueryKeys.revenueDetails(),
    async () => {
      const response = await getRevenueDetails();
      
      return mapRevenueDetails(response);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useRevenueDetailsQuery;
