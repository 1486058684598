/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { DeviceActionFrontType, sendAllGatesAction } from '@zero5/garage-api';

type Response = unknown;
const queryKey: QueryKey = 'SendAllDevicesActionQuery';

const useSendAllDevicesActionQuery = (
  action: DeviceActionFrontType,
  options?: UseQueryOptions<Response, unknown, Response, [typeof queryKey, DeviceActionFrontType]>,
) => {
  return useQuery<Response, unknown, Response, [typeof queryKey, DeviceActionFrontType]>(
    [queryKey, action],
    async () => {
      await sendAllGatesAction({ action });
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useSendAllDevicesActionQuery;
