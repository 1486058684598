import React from 'react';

interface Props {}

const addZero = (value: number) => {
  if (value >= 10) {
    return value.toString();
  }
  return `0${value}`;
};

const Timer: React.FC<Props> = () => {
  const [seconds, setSeconds] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prev) => prev + 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  });

  return (
    <span>
      {addZero(Math.floor(seconds / 60))}
      :
      {addZero(seconds % 60)}
    </span>
  );
};

export default Timer;
