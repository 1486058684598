/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import {
  getGaragePermits,
  PermitSortBy,
} from '@zero5/permit-api';

import { handleError } from '@/utils/handleError';

import { queryGroupName } from './queryGroupName';
import { mapGaragePermits } from './mappings';

type Response = ReturnType<typeof mapGaragePermits>;
type Params = {
  type?: string;
  sortBy?: PermitSortBy;
  take?: number;
  skip?: number;
};

export const queryKey: QueryKey = 'getPermits';

type Key = [typeof queryGroupName, typeof queryKey, Params];

const useGetPermitsQuery = (
  params: Params,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    [queryGroupName, queryKey, params],
    async () => {
      const response = await getGaragePermits(params);

      return mapGaragePermits(response);
    },
    {
      ...options,
      keepPreviousData: true,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onError: (e) => handleError(e),
    },
  );
};

export default useGetPermitsQuery;
