import { CircularProgress } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

interface Props{
  isLoading: boolean;
  disabled: boolean;
}

const LoaderToggle: React.FC<Props> = ({ isLoading, disabled }) => {
  return (
    <CircleDiv disabled={disabled}>
      {
        isLoading
          ? <CircularProgress size={14} color="secondary" thickness={6} />
          : ''
}
    </CircleDiv>
  );
};

const CircleDiv = styled.div<{ disabled: boolean; }>`
    display: flex;
    align-Items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${({ disabled }) => (disabled ? '#dddddd' : '#30CD9A;')}; 
    .MuiCircularProgress-colorSecondary {
    color: white;
}
`;

export default LoaderToggle;
