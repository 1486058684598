import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';

import schemas from '@zero5/ui/lib/utils/validation/schemas';
import { Input, Button } from '@zero5/ui';

import useForgotPasswordMutation from '@/api/auth/useForgotPasswordMutation';
import useConfirmForgotPasswordMutation from '@/api/auth/useConfirmForgotPasswordMutation';
import user from '@/api/user';

import { confirmPasswordSchema } from '@/utils/validation/schemas';
import { handleError } from '@/utils/handleError';
import { withUnauthorized } from '@/utils/hocs/withUnauthorized';

import PasscodeImage from '@/assets/images/passcode.svg';
import LockImage from '@/assets/images/lock.svg';

const validationSchemaEmail = yup.object({
  email: schemas.emailSchema,
});

const validationSchemaCode = yup.object({
  code: yup.string()
    .label('Code')
    .required(),
});

const ResetPassword: React.FC = () => {
  const history = useHistory();

  const [resettingStep, setResettingStep] = React.useState<'email' | 'code' | 'password'>('email');

  const formikSendConfirmationCode = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchemaEmail,
    onSubmit: async ({ email }) => {
      await forgotPasswordMutation.mutateAsync({ email });
    },
  });

  const formikConfirmEmail = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: validationSchemaCode,
    onSubmit: () => {
      setResettingStep('password');

      return Promise.resolve();
    },
  });

  const formikResetPassword = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: confirmPasswordSchema,
    onSubmit: async ({ newPassword }) => {
      await confirmForgotPasswordMutation.mutateAsync({
        email: formikSendConfirmationCode.values.email,
        confirmationCode: formikConfirmEmail.values.code,
        password: newPassword.trim(),
      });
    },
  });

  const forgotPasswordMutation = useForgotPasswordMutation({
    onSuccess: () => {
      setResettingStep('code');
      formikConfirmEmail.setFieldValue('code', '');
    },
    onError: (error: Error) => handleError(error),
  });

  const confirmForgotPasswordMutation = useConfirmForgotPasswordMutation({
    onSuccess: () => {
      signInMutation.mutate({
        email: formikSendConfirmationCode.values.email,
        password: formikResetPassword.values.newPassword,
      });
    },
    onError: (error: Error) => {
      if (error.message === 'Invalid confirmation code') {
        setResettingStep('code');
      }
      handleError(error);
    },
  });

  const signInMutation = useMutation<void, Error,
  {
    email: string;
    password: string;
  }
  >('confirmEmail', async ({ email, password }) => {
    await user.signIn({ email, password });
  }, {
    onSuccess: () => {
      history.push('/');
    },
    onError: (error: Error) => handleError(error),
  });

  const transformedEmailChangeHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => {
      formikSendConfirmationCode.setFieldValue(e.target.name.toLowerCase(), value.replace(' ', ''));
    }, [formikSendConfirmationCode],
  );

  switch (resettingStep) {
    case 'email':
      return (
        <Page>
          <Form onSubmit={formikSendConfirmationCode.handleSubmit}>
            <StyledImage src={LockImage} />
            <MainHeading align="center">
              Forgot Password?
            </MainHeading>
            <HelperText align="center">
              Enter the email address you used when you joined and we will send you instructions to
              reset your password.
            </HelperText>
            <StyledInput
              placeholder="Email"
              name="email"
              onChange={transformedEmailChangeHandler}
              value={formikSendConfirmationCode.values.email}
              error={
                formikSendConfirmationCode.touched.email
                && Boolean(formikSendConfirmationCode.errors.email)
              }
              helperText={
                formikSendConfirmationCode.touched.email
                && Boolean(formikSendConfirmationCode.errors.email)
                  ? formikSendConfirmationCode.errors.email
                  : undefined
              }
              transform="lowercase"
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              loading={formikSendConfirmationCode.isSubmitting}
            >
              Send Reset Instructions
            </Button>
          </Form>
        </Page>
      );
    case 'code':
      return (
        <Page>
          <Form onSubmit={formikConfirmEmail.handleSubmit}>
            <StyledImage src={PasscodeImage} />
            <MainHeading align="center">
              Enter Verification Code
            </MainHeading>
            <HelperText align="center">
              An
              {' '}
              {forgotPasswordMutation.data?.deliveryMedium.toLocaleLowerCase()}
              {' '}
              with verification code was just sent to
              <br />
              <MailText>
                {forgotPasswordMutation.data?.destination}
              </MailText>
            </HelperText>
            <StyledInput
              placeholder="Code"
              name="code"
              onChange={formikConfirmEmail.handleChange}
              value={formikConfirmEmail.values.code}
              error={
                formikConfirmEmail.touched.code
                && Boolean(formikConfirmEmail.errors.code)
              }
              helperText={
                formikConfirmEmail.touched.code
                  ? formikConfirmEmail.errors.code
                  : undefined
              }
            />
            <StyledButton
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              loading={formikConfirmEmail.isSubmitting}
            >
              Verify
            </StyledButton>
            <Button
              variant="text"
              color="primary"
              onClick={formikSendConfirmationCode.submitForm}
              loading={formikSendConfirmationCode.isSubmitting}
            >
              Resend code
            </Button>
          </Form>
        </Page>
      );
    case 'password':
      return (
        <Page>
          <Form onSubmit={formikResetPassword.handleSubmit}>
            <StyledImage src={LockImage} />
            <MainHeading align="center">
              Change your password
            </MainHeading>
            <HelperText align="center">
              Your new password must be at least 8 characters long with
              at least 1 number and 1 special character. Have some fun with it.
            </HelperText>
            <StyledInput
              placeholder="New Password"
              name="newPassword"
              type="password"
              onChange={formikResetPassword.handleChange}
              value={formikResetPassword.values.newPassword}
              error={
                formikResetPassword.touched.newPassword
                && Boolean(formikResetPassword.errors.newPassword)
              }
              helperText={
                formikResetPassword.touched.newPassword
                && Boolean(formikResetPassword.errors.newPassword)
                  ? formikResetPassword.errors.newPassword
                  : undefined
              }
            />
            <StyledInput
              placeholder="Confirm Password"
              name="confirmPassword"
              type="password"
              onChange={formikResetPassword.handleChange}
              value={formikResetPassword.values.confirmPassword}
              error={
                formikResetPassword.touched.confirmPassword
                && Boolean(formikResetPassword.errors.confirmPassword)
              }
              helperText={
                formikResetPassword.touched.confirmPassword
                && Boolean(formikResetPassword.errors.confirmPassword)
                  ? formikResetPassword.errors.confirmPassword
                  : undefined
              }
            />
            <StyledButton
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              loading={formikResetPassword.isSubmitting || signInMutation.isLoading}
            >
              Reset
            </StyledButton>
          </Form>
        </Page>
      );
    default:
      return null;
  }
};

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f8f9fa;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  padding: 40px;
`;

const StyledImage = styled.img`
  margin-bottom: 20px;
`;

const MainHeading = styled(Typography)`
  margin-bottom: 15px;
  font-size: 28px;
  font-weight: 600;
`;

const HelperText = styled(Typography)`
  margin-bottom: 15px;
`;

const MailText = styled(Typography)`
  font-weight: 600;
`;

const StyledInput = styled(Input)`
  width: 100%;
  margin-bottom: 30px;
`;

const StyledButton = styled(Button)`
  margin-bottom: 10px;
`;

export default withUnauthorized(ResetPassword);
