/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import {
  getPermitsRevenue,
} from '@zero5/permit-api';

import { handleError } from '@/utils/handleError';

import { queryGroupName } from './queryGroupName';


type Response = number;


export const queryKey: QueryKey = 'getPermitsRevenue';

type Key = [typeof queryGroupName, typeof queryKey];

const useGetPermitsRevenueQuery = (
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    [queryGroupName, queryKey],
    async () => {
      const response = await getPermitsRevenue();

      return response.revenue;
    },
    {
      ...options,
      keepPreviousData: true,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onError: (e) => handleError(e),
    },
  );
};

export default useGetPermitsRevenueQuery;
