import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { createPermitType } from '@zero5/permit-api';

import { handleError } from '@/utils/handleError';

import { mapPermitType } from '../permit/mappings';

import { queryKey as permitListQuery } from './useGetPermitTypeListQuery';

type Response = ReturnType<typeof mapPermitType>;
type Params = {
  permitTypeName: string;
  price: number;
  hoursOpen: {
    applicable: boolean;
    day: string;
    end: string;
    start: string;
  }[];
  maxPermits: number;
  eligibleTenantTypes: string[];
};

export const queryKey = 'createPermitType';

const useCreatePermitTypeMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    queryKey,
    async (params) => {
      const response = await createPermitType(params);

      return mapPermitType(response.permitType);
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(permitListQuery);
      },
      onError: (error) => handleError(error),
    },
  );
};

export default useCreatePermitTypeMutation;
