import { TenantCompanyResponse, GetTenantsCountResponse } from '@zero5/user-api';

import { Tenant } from './models';

export const mapTenant = (tenantResponse: TenantCompanyResponse): Tenant => ({
  companyId: tenantResponse.companyId,
  companyName: tenantResponse.companyName,
  companyType: tenantResponse.companyType,
  leaseStartDate: tenantResponse.companyLeaseStartDate,
  tenantAdmin: {
    userId: tenantResponse.tenantAdmin.userId,
    firstName: tenantResponse.tenantAdmin.firstName || '-',
    lastName: tenantResponse.tenantAdmin.lastName || '-',
    status: tenantResponse.tenantAdmin.isActive ? 'active' : 'deactivated',
    phone: tenantResponse.tenantAdmin.phone || '',
    email: tenantResponse.tenantAdmin.email || '-',
  },
});

export const mapTenants = (
  tenantResponse: Array<TenantCompanyResponse>,
) => tenantResponse.map(mapTenant);

export const mapTenantsCount = (
  tenantsCountResponse: GetTenantsCountResponse,
) => ({
  tenantUsersCount: tenantsCountResponse.tenantUsersCount,
  tenantCompaniesCount: tenantsCountResponse.tenantCompaniesCount,
});
