import React from 'react';
import styled from 'styled-components';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  value: string;
}

const GarageTitle: React.FC<Props> = ({ name, value, ...props }) => {
  return (
    <Wrapper {...props}>
      <Name>{name}</Name>
      {' - '}
      <Value>{value}</Value>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-size: 15px;
  line-height: 20px;
`;

const Name = styled.span`
  font-weight: 600;
`;

const Value = styled.span`
  font-weight: 500;
`;

export default GarageTitle;
