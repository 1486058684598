import { useMutation, UseMutationOptions } from 'react-query';

import { setOpenGateByLPR, SetOpenGateByLprArgs } from '@zero5/garage-api';

type Response = void;

const useResetSettingsMutation = (
  options?: UseMutationOptions<Response, Error, SetOpenGateByLprArgs, string>,
) => {
  return useMutation(
    'resetSettings',
    async (args: SetOpenGateByLprArgs) => {
      const deviceId = args.deviceId.replace('gate', 'lpr');
      const response = await setOpenGateByLPR({ ...args, deviceId });
      return response;
    },
    options,
  );
};

export default useResetSettingsMutation;
