import { createSelector, Selector } from 'reselect';

import { Device, DeviceResponse } from '@/api/models/devices';

import { State } from '@/store';
import { DeviceState } from '@/store/reducers/device';

import { RequestState } from '../reducers/common';

const selectDevice = (state: State) => state.deviceReducer;

export const selectDeviceState: Selector<State, DeviceState> = createSelector(
  selectDevice,
  (loginState) => loginState,
);

export const selectDeviceData: Selector<State, DeviceResponse | null> = createSelector(
  selectDevice,
  ({ deviceData }) => deviceData,
);

export const selectDeviceList: Selector<State, Device[][]> = createSelector(
  selectDevice,
  ({ deviceList }) => deviceList,
);

export const selectDeviceIsPending: Selector<State, RequestState> = createSelector(
  selectDevice,
  ({ deviceState }) => deviceState,
);
