import React from 'react';
import styled, { css } from 'styled-components';
import flow from 'lodash/flow';

import {
  Button,
  Card,
  // todo, Dima M has not pushed new version
  // CardTitle,
  CategoryCard,
  DatePicker,
  Input,
  Row,
  Select,
} from '@zero5/ui';

import Page from '@/components/common/page';

import { withAuth } from '@/utils/hocs/withAuth';
import { withLoading } from '@/utils/hocs/withLoading';
import { withPreloadRole, withRole } from '@/utils/hocs/withRole';
import { withGarage, withPreloadGarage } from '@/utils/hocs/withGarage';

interface Props {}

const IssueList: React.FC<Props> = () => {
  const cardsData = [
    {
      title: 'Outstanding Entry Issues',
      data: '23',
    },
    {
      title: 'Unresolved Issuese',
      data: '19',
    },
  ];

  return (
    <Page>
      <CardsWrapper>
        {cardsData.map((props) => (
          <CategoryCard key={props.title} {...props} />
        ))}
      </CardsWrapper>
      <StyledCard>
        <StyledCardTitle>Search</StyledCardTitle>
        <Grid>
          {/* TODO: add validation, transforms to the fields; add formik */}
          <StyledInput
            label="Garage Name"
            columns={3}
          />
          <StyledSelect
            label="Gate Location"
            columns={3}
          />
          <StyledDatePicker
            label="Date"
            columns={3}
            pickerProps={{
              onChange: () => null,
            }}
          />
          {/* TODO: replace this with time picker from zero5 when it's ready */}
          <StyledInput
            label="Time"
            placeholder="replace this with time picker from zero5 when it's ready"
            columns={3}
          />
          <StyledInput
            label="License Plate Number"
            columns={3}
          />
          <StyledInput
            label="Vehicle Make"
            columns={3}
          />
          <StyledInput
            label="Vehicle Color"
            columns={3}
          />
          <StyledInput
            label="Vehicle Type"
            columns={3}
          />
        </Grid>
        <Row justifyContent="flex-end">
          <Button variant="contained" color="primary">Search</Button>
        </Row>
      </StyledCard>
    </Page>
  );
};

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  margin-bottom: 35px;

  @media (max-width: 1050px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;

const StyledCard = styled(Card)`
  padding: 20px;
`;

// todo, Dima M has not pushed new version
// const StyledCardTitle = styled(CardTitle)`
const StyledCardTitle = styled.div`
  margin-bottom: 20px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 10px;
  margin-bottom: 20px;
`;

const columnStyles = css<{ columns: number; }>`
  grid-column: span ${({ columns }) => columns};
`;

const StyledInput = styled(Input)`
  ${columnStyles}
`;

const StyledDatePicker = styled(DatePicker)<{ columns: number; }>`
  ${columnStyles}
`;

const StyledSelect = styled(Select)<{ columns: number; }>`
  ${columnStyles}
`;

export default flow( 
  withGarage,
  withRole('controlCenter:issueList'),
  withPreloadRole,
  withPreloadGarage,
  withAuth(),
  withLoading(),
)(IssueList);
