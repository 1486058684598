import { AxiosInstance } from 'axios';

import {
  AuthApiProviderInterface,
  SystemUserNewPasswordChallengeArgs,
  SystemUserSignInArgs,
  SystemUserSignInResponse,
  Tokens,
} from '@zero5/ui-utils';
import {
  refreshToken as refreshTokenApi,
  systemUserNewPasswordChallenge as systemUserNewPasswordChallengeApi,
  systemUserSignIn as systemUserSignInApi,
} from '@zero5/auth-api';

class ClientAdminAuthApiProvider implements AuthApiProviderInterface {
  // eslint-disable-next-line class-methods-use-this
  async refreshToken(refreshToken: string, axiosInstance: AxiosInstance): Promise<Tokens> {
    return (await refreshTokenApi({ refreshToken }, axiosInstance)).tokens;
  }

  // eslint-disable-next-line class-methods-use-this
  async systemUserNewPasswordChallenge(
    args: SystemUserNewPasswordChallengeArgs,
    axiosInstance: AxiosInstance,
  ): Promise<Tokens> {
    return (await systemUserNewPasswordChallengeApi(args, axiosInstance)).tokens;
  }

  // eslint-disable-next-line class-methods-use-this
  async systemUserSignIn(args: SystemUserSignInArgs, axiosInstance: AxiosInstance): Promise<SystemUserSignInResponse> {
    return systemUserSignInApi(args, axiosInstance);
  }
}

const clientAdminAuthApiProvider = new ClientAdminAuthApiProvider();
export default clientAdminAuthApiProvider;
