import React from 'react';
import styled from 'styled-components';
import saveAs from 'file-saver';

import {
  RowsMap,
  TableCard,
  TableContent,
  Button,
  TableHead,
  InfoButton,
  Modal,
} from '@zero5/ui';
import Table, { Order } from '@zero5/ui/lib/Table';
import Select from '@zero5/ui/lib/Select';
import useToggle from '@zero5/ui/lib/utils/hooks/useToggle';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';

import { Invoice } from '@/api/revenue/models';
import useInvoicesReportsQuery from '@/api/revenue/useInvoicesReportsQuery';
import useInvoiceReportDetailsCsvMutation from '@/api/revenue/useInvoiceReportDetailsCsvMutation';

import useDateModule from '@/utils/date/useDateModule';

import { useFindCurrentAction } from '../common/Role';

import InvoiceReport from './modals/InvoiceReport';
import InvoiceStatus from './components/InvoiceStatus';

interface Props {}

type Option = {
  value: {
    from: Date;
    until: Date;
  };
  label: string;
};

const Invoices: React.FC<Props> = ({}) => {
  const findCurrentAction = useFindCurrentAction();
  const { getStartOfMonth, getEndOfMonth, addTime, getMonthAndYear } = useDateModule();

  const currentDate = React.useMemo(() => new Date(), []);
  const previousMonthDate = React.useMemo(() => {
    const monthStart = getStartOfMonth(currentDate);
    const prevMonth = addTime(monthStart, 'day', -1);
    return getStartOfMonth(prevMonth);
  }, [addTime, currentDate, getStartOfMonth]);

  const [isInfoModalOpen, closeInfoModal, openInfoModal] = useToggle(false);
  const [selectedReport, setSelectedReport] = React.useState<Invoice | null>(null);

  const [selectedReports, setSelectedReports] = React.useState<Array<Invoice>>([]);

  const [invoicesReportsPage, setInvoicesReportsPage] = React.useState(0);
  const [invoicesReportsRowsPerPage, setInvoicesReportsRowsPerPage] = React.useState(10);
  const [invoicesReportsOrder, setInvoicesReportsOrder] = React.useState<Order>();
  const [invoicesReportsOrderBy, setInvoicesReportsOrderBy] = React.useState<string>();

  const [reportsRange, setReportsRange] = React.useState({
    from: getStartOfMonth(previousMonthDate),
    until: getEndOfMonth(previousMonthDate),
  });

  const invoiceReportDetailsCsvMutation = useInvoiceReportDetailsCsvMutation();

  const {
    data: invoicesReports,
    isLoading: invoicesReportsLoading,
    isFetching: invoicesReportsFetching,
  } = useInvoicesReportsQuery({
    from: reportsRange.from.getTime(),
    until: reportsRange.until.getTime(),
    page: invoicesReportsPage + 1,
    take: invoicesReportsRowsPerPage,
    orderBy:
      invoicesReportsOrderBy as 'event' | 'total' | 'permit' | 'transient' | 'validation' | 'tenantName' | undefined,
    direction: invoicesReportsOrder,
  });

  // console.log(invoicesReports);

  const monthYearOptions = React.useMemo<Array<Option>>(() => {
    const startTimeStamp = invoicesReports?.firstTransactionTimestamp;

    const monthStart = getStartOfMonth(previousMonthDate);
    const monthEnd = getEndOfMonth(previousMonthDate);

    const options: Array<{
      value: {
        from: Date;
        until: Date;
      };
      label: string;
    }> = [
      {
        value: {
          from: monthStart,
          until: monthEnd,
        },
        label: getMonthAndYear(monthStart),
      },
    ];

    if (!startTimeStamp) return options;

    const firstMonthStart = getStartOfMonth(new Date(startTimeStamp));

    while (options[options.length - 1].value.from.getTime() > firstMonthStart.getTime()) {
      const lastOption = options[options.length - 1];

      const nextFrom = addTime(lastOption.value.from, 'month', -1);
      const nextUntil = addTime(lastOption.value.until, 'month', -1);

      options.push({
        value: {
          from: nextFrom,
          until: nextUntil,
        },
        label: getMonthAndYear(nextFrom),
      });
    }

    return options;
  }, [
    addTime,
    previousMonthDate,
    getEndOfMonth,
    getMonthAndYear,
    getStartOfMonth,
    invoicesReports?.firstTransactionTimestamp,
  ]);

  const onInfoModalOpen = React.useCallback((record: Invoice) => {
    setSelectedReport(record);
    openInfoModal();
  }, [openInfoModal]);

  const exportReport = React.useCallback(async (report: Invoice) => {
    const reportLocal = await invoiceReportDetailsCsvMutation.mutateAsync({
      transactionId: report.transactionId,
    });

    const fileName = `InvoiceDetailsReport_${report.transactionId}.csv`;
    const file = new File([reportLocal], fileName, { type: 'text/plain;charset=utf-8' });
    saveAs(file);
  }, [invoiceReportDetailsCsvMutation]);

  const exportCurrentReport = React.useCallback(() => {
    exportReport(selectedReport!);
  }, [exportReport, selectedReport]);

  const exportSelectedReports = React.useCallback(async () => {
    selectedReports.forEach((report) => exportReport(report));
  }, [exportReport, selectedReports]);

  const invoicesRowsMap = React.useMemo(() => {
    const rowsMap: RowsMap<Invoice> = [
      {
        title: 'Tenants',
        id: 'tenantName',
        data: ({ tenantName }) => tenantName,
      },
      {
        title: 'Permit',
        id: 'permit',
        data: ({ permit }) => formatPrice(permit),
      },
      {
        title: 'Validation',
        id: 'validation',
        data: ({ validation }) => formatPrice(validation),
      },
      {
        title: 'Event',
        id: 'events',
        data: ({ event }) => formatPrice(event),
      },
      {
        title: 'Total Amount',
        id: 'total',
        data: ({ total }) => {
          return <TotalPrice>{formatPrice(total)}</TotalPrice>;
        },
      },
      {
        title: 'Payment Status',
        id: 'status',
        data: ({ status }) => <InvoiceStatus status={status} />,
        disableSorting: true,
      },
    ];

    if (findCurrentAction('revenue:invoices:report') !== 'h') {
      rowsMap.push({
        title: 'Details',
        id: 'Details',
        disableSorting: true,
        data: (record) => (
          <InfoButton onClick={() => onInfoModalOpen(record)} />
        ),
      });
    }
    return rowsMap;
  }, [findCurrentAction, onInfoModalOpen]);

  return (
    <>
      <TableCard data-test="revenue-invoices-table">
        <TableHead
          title="Invoices"
          extraContent={(
            <>
              <MonthSelect
                options={monthYearOptions}
                onChange={(value) => setReportsRange(value?.value!)}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={exportSelectedReports}
                disabled={!selectedReports.length}
                loading={invoiceReportDetailsCsvMutation.isLoading}
                data-test="revenue-invoices-table-export-btn"
              >
                export
              </Button>
            </>
          )}
        />
        <TableContent
          isLoading={invoicesReportsLoading}
          data={invoicesReports?.reports || []}
        >
          <StyledTable
            rowsMap={invoicesRowsMap}
            data={invoicesReports?.reports!}
            controls="checkbox"
            onItemSelect={(selected) => setSelectedReports(selected)}
            getSelectComparator={(item) => item?.transactionId}
            selected={selectedReports}
            page={invoicesReportsPage}
            onPageChange={(e, page) => setInvoicesReportsPage(page)}
            onRowsPerPageChange={(e) => setInvoicesReportsRowsPerPage(Number(e.target.value))}
            onSortChange={(orderBy, order) => {
              setInvoicesReportsOrderBy(orderBy);
              setInvoicesReportsOrder(order);
            }}
            isLoading={invoicesReportsFetching}
            count={invoicesReports?.entriesCount}
            minWidth="840px"
            data-test="revenue-invoices-table-content"
          />
        </TableContent>
      </TableCard>
      <ReportModal
        open={isInfoModalOpen}
        onClose={closeInfoModal}
        fullScreenSize="550px"
      >
        <InvoiceReport
          invoice={selectedReport!}
          onClose={closeInfoModal}
          onExport={exportCurrentReport}
          isExporting={invoiceReportDetailsCsvMutation.isLoading}
        />
      </ReportModal>
    </>
  );
};

const ReportModal = styled(Modal)`
  max-width: 1080px;
  width: 100%;
`;

const TotalPrice = styled.div`
  font-weight: 700;
`;

const StyledTable = styled(Table)`
  margin-top: 10px;

  & thead.MuiTableHead-root span {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }
` as typeof Table;

const MonthSelect = styled(Select)`
  min-width: 150px;
` as typeof Select;

export default Invoices;
