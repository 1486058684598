import React, { useEffect } from 'react';
import Draggable, { DraggableData } from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import flow from 'lodash/flow';
import styled from 'styled-components';

import { Modal } from '@zero5/ui';

import { withAuth } from '@/utils/hocs/withAuth';
import { withRole } from '@/utils/hocs/withRole';
import { withLoading } from '@/utils/hocs/withLoading';

import { selectCallsQueue, selectCurrentCall } from '@controlCenter/store/selectors/call';
import CallsQueue from '@controlCenter/components/call/CallsQueue';
import Call from '@controlCenter/components/call/Call';
import { getCallsQueue } from '@controlCenter/store/actions/call';

import { TABLET_ONLY_MEDIA_QUERY, TABLET_SIZE_PX } from '../utils/styles/media';

const CallContainer: React.FC = () => {
  const dispatch = useDispatch();

  const callsQueue = useSelector(selectCallsQueue);
  const currentCall = useSelector(selectCurrentCall);
  const [callsQueuePosition, setCallsQueuePosition] = React.useState<DraggableData>();

  useEffect(() => {
    dispatch(getCallsQueue());
  }, []);

  return (
    <>
      {Boolean(callsQueue.length) && !currentCall && (
        <Draggable
          position={callsQueuePosition}
          onStop={(_event, data) => setCallsQueuePosition(data)}
          cancel="button"
        >
          <CallsQueue />
        </Draggable>
      )}
      <CallModal
        open={Boolean(currentCall)}
        onClose={() => null}
        fullScreenSize={TABLET_SIZE_PX}
      >
        <Call call={currentCall!} />
      </CallModal>
    </>
  );
};

const CallModal = styled(Modal)`
  @media ${TABLET_ONLY_MEDIA_QUERY} {
    width: 100%;
  }
`;

export default flow(
  withRole('controlCenter', null),
  withAuth(null),
  withLoading(null),
)(CallContainer);
