import React from 'react';
import { ChartDataset } from 'chart.js';
import styled from 'styled-components';

import {
  BigCategoryCard,
  LineChart,
  Row,
  Select,
} from '@zero5/ui';
import { DateRange } from '@zero5/ui/lib/Chart';

import { RevenueTrendAggregationPeriod } from '@/api/revenue/models';

import RangePicker from '@/components/common/RangePicker';

import useDateModule from '@/utils/date/useDateModule';

export type Value = number;

export type DataItem = {
  values: Array<Value>;
  datasetSettings?: Partial<ChartDataset<'line'>>;
};

type Data = Array<DataItem> | Array<Value>;

interface Props {
  dateRange: DateRange;
  onDateRangeChange: (dateRange: DateRange) => void;
  data: Data;
  labels: Array<string>;
  priceFormat?: boolean;
  isLoading?: boolean;
}

const PermitTrendChart: React.FC<Props> = ({
  dateRange,
  onDateRangeChange,
  data,
  labels,
  isLoading,
}) => {
  const {
    z5DateModule,
  } = useDateModule();

  const [
    permitTrendChartAggregation,
    setPermitTrendChartAggregation,
  ] = React.useState<RevenueTrendAggregationPeriod>('monthly');

  const revenueTrendChartAggregatedOptions = React.useMemo(() => {
    const options: Array<{
      label: string;
      value: RevenueTrendAggregationPeriod;
    }> = [];

    options.push({
      label: 'Monthly',
      value: 'monthly',
    });

    return options;
  }, []);

  const trendDateRangeChangeHandler = React.useCallback(
    (selectedRange: DateRange) => {
      const endOfToday = new Date(z5DateModule.fromUtc(Date.now()).dayEnd().getTimestamp());

      const newStart = new Date(z5DateModule.fromUtc(selectedRange.start.getTime()).monthStart().getTimestamp());
      const newEnd = new Date(
        Math.min(
          z5DateModule.fromUtc(selectedRange.end.getTime()).monthEnd().getTimestamp(),
          endOfToday.getTime(),
        ),
      );

      // eslint-disable-next-line no-param-reassign
      selectedRange = {
        start: newStart,
        end: newEnd,
      };

      onDateRangeChange(selectedRange);
    },
    [onDateRangeChange, z5DateModule],
  );

  return (
    <BigCategoryCard
      title="Permit Trend"
      gap="10px"
      data-test="revenue-trend"
      headerContent={
        <Row wrap="wrap" gap="10px">
          <RangePicker
            dateRange={dateRange}
            onChange={onDateRangeChange}
            hideShortcutsSize="1450px"
          />
          <StyledSelect
            options={revenueTrendChartAggregatedOptions}
            value={revenueTrendChartAggregatedOptions.find(({ value }) => value === permitTrendChartAggregation)}
            onChange={(option) => {
              trendDateRangeChangeHandler(dateRange);
              setPermitTrendChartAggregation(option!.value);
            }}
          />
        </Row>
      }
    >
      <LineChart
        data={data}
        labels={labels}
        isLoading={isLoading}
      />
    </BigCategoryCard>
  );
};

const StyledSelect = styled(Select)`
  min-width: 115px;
` as typeof Select;

export default PermitTrendChart;