/* eslint-disable max-len */
import { useQuery, UseQueryOptions } from 'react-query';

import { getTenantsCount } from '@zero5/user-api';

import { mapTenantsCount } from '@/api/tenants/mappings';

import { tenantQueryKeys } from './queryKeys';

type Response = ReturnType<typeof mapTenantsCount>;
type Key = ReturnType<typeof tenantQueryKeys.count>;

const useTenantsCountQuery = (
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    tenantQueryKeys.count(),
    async () => {
      const response = await getTenantsCount();

      return mapTenantsCount(response);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useTenantsCountQuery;
