/* eslint-disable max-len */
import { QueryKey, useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';

import { getActivitiesSearchResult } from '@zero5/activity-api';

import { mapActivities, mapActivitiesWithType } from '@/api/mappings/activity';
import { queryKey as getActivitiesQuery } from '@/api/queries/useActivitiesQuery';

import { loadImage } from '@/utils/image';
import { getEndOfDayUTC, getStartOfDayUTC, setHours } from '@/utils/date/helpers';

import { SearchFormikState } from '../models/activities';

type Response = ReturnType<typeof mapActivities>;

export const queryKey: QueryKey = [getActivitiesQuery, 'searchedActivitiesQuery'];
export const defaultPerPage = 30;

type Key = [typeof queryKey, number];

const useSearchedActivitiesQuery = (
  params: SearchFormikState,
  options?: UseInfiniteQueryOptions<Response, unknown, Response, Response, Key>,
  perPage: number = defaultPerPage,
) => {
  return useInfiniteQuery<Response, unknown, Response, Key>(
    [queryKey, perPage],
    async ({ pageParam }) => {
      const LEVENSHTEIN_DISTANCE = 3;
      const { startDate, endDate, endTime, startTime, gatewayType, location, mainLp } = params;

      startDate.setMinutes(0, 0, 0);
      endDate.setMinutes(0, 0, 0);

      const fromDate = startTime !== null ? setHours(startDate, startTime) : getStartOfDayUTC(startDate);
      const toDate = endTime !== null ? setHours(endDate, endTime) : getEndOfDayUTC(endDate);

      const response = await getActivitiesSearchResult({
        fromDate,
        toDate: pageParam ? pageParam : toDate,
        gatewayType,
        location,
        mainLp,
        take: perPage,
        levenshteinDistance: LEVENSHTEIN_DISTANCE,
      });

      response.activities.forEach(({ data }) => {
        if (data.frontImg) {
          loadImage(data.frontImg);
        }
        if (data.rearImg) {
          loadImage(data.rearImg);
        }
      });

      return mapActivitiesWithType(response.activities);
    },
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => {
        if (lastPage.length < perPage) return undefined;

        return lastPage[lastPage.length - 1].eventTime;
      },
      ...options,
    },
  );
};

export default useSearchedActivitiesQuery;
