/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getRevenueCards, RevenueCards } from '@zero5/payment-api';

import { queryGroupName } from './queryGroupName';


type Response = RevenueCards;
export const queryKey: QueryKey = 'revenueCards';



const useRevenueCardsQuery = (
  options?: UseQueryOptions<Response, unknown, Response, [typeof queryGroupName, typeof queryKey]>,
) => {
  return useQuery<Response, unknown, Response, [typeof queryGroupName, typeof queryKey]>(
    [queryGroupName, queryKey],
    async () => {
      const response = await getRevenueCards();

      return response;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useRevenueCardsQuery;
