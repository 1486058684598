import React from 'react';
import styled from 'styled-components';
import { Switch as SwitchMU } from '@material-ui/core';

import { InputLabel } from '@zero5/ui';

interface Props {
  label: string;
  value: boolean;
  disabled?: boolean;
  onClick: () => void;
}

export const Switch: React.FC<Props> = ({
  label,
  value,
  disabled,
  onClick,
}) => (
  <>
    <StyledInputLabel>{label}</StyledInputLabel>
    <SwitchControlWrapper hasLabel={!!label}>
      <StyledSpan>Off</StyledSpan>
      <SwitchMU
        color="primary"
        checked={value}
        onChange={onClick}
        disabled={!!disabled}
      />
      <StyledSpan>On</StyledSpan>
    </SwitchControlWrapper>
  </>
);

const StyledInputLabel = styled(InputLabel)`
  margin: 0;
`;

const StyledSpan = styled.span`
  font-style: normal;
  font-weight: normal;
  color: #6c757d;
  font-size: 13px;
`;

const SwitchControlWrapper = styled.div<{ hasLabel: boolean; }>`
  display: flex;
  align-items: center;
   ${({ hasLabel }) => (hasLabel ? 'margin-left: 30px;' : '')}
`;

export default Switch;
