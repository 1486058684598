import React from 'react';

import TimePickerLib, { TimePickerProps } from '@zero5/ui/lib/TimePicker';

import useDateModule from '@/utils/date/useDateModule';

const TimePicker: React.FC<TimePickerProps> = (props) => {
  const { z5DateModule } = useDateModule();

  return (
    <TimePickerLib
      timezone={z5DateModule.getTimezone()}
      {...props}
    />
  );
};

export default TimePicker;
