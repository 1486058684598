import { useQuery, useQueryClient, QueryKey, UseQueryOptions } from 'react-query';


import { getEvents, EventStatus } from '@zero5/garage-api';
import { DateRange } from '@zero5/ui/lib/Chart';

import { mapEventsResponse } from '@/api/mappings/event';
import { queryKey as getEventCountsQuery } from '@/api/queries/useEventCountsQuery';

import useDateModule from '@/utils/date/useDateModule';

type Response = ReturnType<typeof mapEventsResponse>;

type Params = {
  dateRange: DateRange;
  tab: number;
};

export const queryKey: QueryKey = 'events';

const useEventsQuery = (
  params: Params,
  options?: UseQueryOptions<Response, unknown, Response, typeof queryKey>,
) => {
  const queryClient = useQueryClient();
  const { convertDateRangeToTimeZoneWithStartEndOfDay } = useDateModule();
  return useQuery(
    queryKey,
    async () => {
      const { dateRange, tab } = params;
      const { from, until } = convertDateRangeToTimeZoneWithStartEndOfDay(dateRange);
      const statuses = tab === 0 ? [EventStatus.CONFIRMED]
        : [EventStatus.REQUESTED, EventStatus.APPROVED_ADMIN];
      const response = await getEvents({
        statuses,
        fromDate: from,
        toDate: until,
      });
      return mapEventsResponse(response);
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(getEventCountsQuery);
      },
    },
  );
};

export default useEventsQuery;
