const pricingTypes = [
  {
    label: 'Hourly Rate',
    value: 'HOURLY_RATE',
  },
  {
    label: 'Flat Fee',
    value: 'FLAT_FEE',
  },
];

export default pricingTypes;
