import { CircularProgress } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

interface Props{
  isLoading: boolean;

}

const InputLoading: React.FC<Props> = ({ isLoading }) => {
  return (
    <CircleDiv>
      {
        isLoading
          ? <CircularProgress size={14} color="secondary" thickness={6} />
          : ''
}
    </CircleDiv>
  );
};

const CircleDiv = styled.div`
    display: flex;
    align-Items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    .MuiCircularProgress-colorSecondary {
    color: #30CD9A;
}
`;

export default InputLoading;
