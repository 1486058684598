import {
  createStore,
  applyMiddleware,
  compose,
  combineReducers,
  Store,
  Middleware,
} from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import {
  persistStore, persistReducer, PersistConfig,
} from 'redux-persist';
// eslint-disable-next-line import/extensions
import storage from 'redux-persist/lib/storage';

import { ActivitiesActions } from '@/store/actions/activities';

import callReducer from '@controlCenter/store/reducers/call';

import { authActions, AuthActions } from './actions/auth';
import { DeviceActions } from './actions/device';
import { settingsActions, SettingsActions } from './actions/settings';
import { EventsActions } from './actions/events';
import activitiesReducer from './reducers/activities';
import eventsReducer from './reducers/events';
import authReducer from './reducers/auth';
import deviceReducer from './reducers/device';
import workspaceReducer from './reducers/workspace';
import settingsReducer from './reducers/settings';
import { datesTransform } from './transforms/settings';

const PERSIST_VERSION = 1;
const PERSIST_STORAGE = storage;

const appReducer = combineReducers({
  authReducer,
  deviceReducer,
  workspaceReducer,
  settingsReducer,
  call: callReducer,
  activitiesReducer,
  eventsReducer,
});

const rootReducer: typeof appReducer = (state, action) => {
  if (action.type === authActions.signIn.type) {
    PERSIST_STORAGE.removeItem('persist:root');
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

const rootPersistConfig: PersistConfig<RootState> = {
  key: 'root',
  version: PERSIST_VERSION,
  storage: PERSIST_STORAGE,
  transforms: [datesTransform],
  blacklist: ['call', 'activitiesReducer', 'eventsReducer'],
};

const rootPersistReducer = persistReducer(rootPersistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// <-- START -->
// handle timezone changing to update dates that were initialized with default utc timezone
// probably there should be a better place to store these dates
// TODO: initialize timezone dependent dates after getting garage's timezone

const dateMiddleware: Middleware = (store) => (next) => (action) => {
  if (action.type.includes('setCurrentGarage')) {
    setTimeout(() => {
      store.dispatch(settingsActions.updateTimezone());
    }, 0);
  }

  next(action);
};

// <!--- END ---!>

const enhancer = composeEnhancers(applyMiddleware(thunk, dateMiddleware));

export type State = ReturnType<typeof rootPersistReducer>;
export type Actions = AuthActions | DeviceActions | SettingsActions | ActivitiesActions | EventsActions;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, State, unknown, Actions>;

const store = createStore(rootPersistReducer, enhancer);

export const persistor = persistStore(store as unknown as Store);

export default store;
