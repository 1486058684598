import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import flow from 'lodash/flow';

import { WsConnectionKeeper } from '@zero5/ui-utils';
import { connectAdmin } from '@zero5/client-admin-api';

import user from '@/api/user';

import { useFindCurrentAction } from '@/components/common/Role';

import { addActivity, updateActivityHandler, updateEventHandler, updateGateState } from '@/utils/connectAdmin';
import { withAuth } from '@/utils/hocs/withAuth';
import { withAnyRole } from '@/utils/hocs/withRole';
import { withLoading } from '@/utils/hocs/withLoading';

import { DEV_GARAGE_ID } from '@/devConstants';
import connectCall from '@controlCenter/utils/connectCall';


function getAccessToken(): Promise<string> {
  return user.getAccessToken();
}

const WebSocket: React.FC = () => {
  const findCurrentAction = useFindCurrentAction();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  useEffect(() => {
    const wsConnectionKeeper = new WsConnectionKeeper(
      connectAdmin,
    );
    wsConnectionKeeper.openConnection(
      getAccessToken,
      DEV_GARAGE_ID,
      (msg) => {
        if (findCurrentAction('home') !== 'h') dispatch(addActivity(msg));
        if (findCurrentAction('parkingSettings') !== 'h') dispatch(updateGateState(msg));
        if (findCurrentAction('controlCenter') !== 'h') dispatch(connectCall(msg));
        if (findCurrentAction('home') !== 'h') updateEventHandler(msg, queryClient);
        updateActivityHandler(msg, queryClient);
      },
    );

    return () => wsConnectionKeeper.closeConnection();
  }, [findCurrentAction, dispatch, queryClient]);

  return null;
};

export default flow(
  withAnyRole([
    'home',
    'parkingSettings',
    'controlCenter',
    'event',
  ]),
  withAuth(null),
  withLoading(null),
)(WebSocket);
