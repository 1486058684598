import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Tab, Tabs } from '@material-ui/core';
import flow from 'lodash/flow';

import {
  CategoryCard,
  InfoButton,
  Modal,
  RowsMap,
  Table,
  TableCard,
  TableContent,
} from '@zero5/ui';
import useToggle from '@zero5/ui/lib/utils/hooks/useToggle';
import vars from '@zero5/ui/lib/styles/vars';
import { IssueStatus } from '@zero5/client-admin-api';
import { getZone } from '@zero5/ui/lib/utils/formatters/formatGate';

import Page from '@/components/common/page';
import ActivityList from '@/components/activities/ActivityList';
import TableHead from '@/components/common/TableHead';

import useDateModule from '@/utils/date/useDateModule';
import { withAuth } from '@/utils/hocs/withAuth';
import { withLoading } from '@/utils/hocs/withLoading';
import { emptyFieldStub } from '@/utils/emptyFieldStub';
import { withPreloadRole, withRole } from '@/utils/hocs/withRole';
import { withGarage, withPreloadGarage } from '@/utils/hocs/withGarage';


import { IssueActivity as ActivityType } from '@controlCenter/api/activity/models';
import Activity from '@controlCenter/components/modals/Activity';
import GridIcon from '@controlCenter/assets/icons/grid.inline.svg';
import ListIcon from '@controlCenter/assets/icons/list.inline.svg';
import useGetUnresolvedIssues from '@controlCenter/api/activity/useGetUnresolvedIssues';

import useGetIssueActivities from '../api/activity/useGetIssueActivitiesQuery';
import useGetAllActivities from '../api/activity/useGetAllActivitiesQuery';

interface Props {}

const activityStatusToView: Record<IssueStatus, {
  title: string;
  color: string;
}> = {
  RESOLVED: {
    title: 'Resolved',
    color: vars.palette.primary,
  },
  UNRESOLVED: {
    title: 'Unresolved',
    color: vars.palette.danger,
  },
  GOOD: {
    title: 'Resolved',
    color: vars.palette.primary,
  },
};

enum TabsEnum {
  ISSUE_ACTIVITIES,
  ALL_ACTIVITIES,
}

const EntryActivityList: React.FC<Props> = () => {
  const { formatTimeStampToString, z5DateModule } = useDateModule();

  const twoDaysBefore = useMemo(() => z5DateModule.fromUtc(Date.now()).subtractDays(2).getTimestamp(), [z5DateModule]);
  const [tab, setTab] = React.useState<TabsEnum>(TabsEnum.ISSUE_ACTIVITIES);
  const [viewType, setViewType] = React.useState<'table' | 'grid'>('table');
  const [selectedActivity, setSelectedActivity] = React.useState<ActivityType>();
  const [isInfoModalOpen, closeInfoModal, openInfoModal] = useToggle(false);

  const [activitiesPage, setActivitiesPage] = React.useState(0);
  const [activitiesPerPage, setActivitiesPerPage] = React.useState(100);
  const { data: unresolvedIssues } = useGetUnresolvedIssues(twoDaysBefore);

  const {
    data: issueActivitiesData,
    isLoading: issueActivitiesLoading,
    isFetching: issueActivitiesFetching,
  } = useGetIssueActivities({
    page: activitiesPage,
    perPage: activitiesPerPage,
    fromDate: twoDaysBefore,
  }, {
    enabled: tab === TabsEnum.ISSUE_ACTIVITIES,
    keepPreviousData: true,
    staleTime: Infinity,
  });
  const {
    data: allActivitiesData,
    isLoading: allActivitiesLoading,
  } = useGetAllActivities({
    enabled: tab === TabsEnum.ALL_ACTIVITIES,
  });

  const activitiesData = React.useMemo(() => {
    return tab === TabsEnum.ISSUE_ACTIVITIES ? issueActivitiesData : allActivitiesData;
  }, [tab, allActivitiesData, issueActivitiesData]);

  const activitiesLoading = React.useMemo(() => {
    return tab === TabsEnum.ISSUE_ACTIVITIES ? issueActivitiesLoading : allActivitiesLoading;
  }, [tab, allActivitiesLoading, issueActivitiesLoading]);

  const cardsData = useMemo(() => [
    {
      title: 'Unresolved Issues',
      data: unresolvedIssues || emptyFieldStub,
    },
  ], [unresolvedIssues]);

  const openInfoActivityModal = React.useCallback((activity: ActivityType) => {
    setSelectedActivity(activity);
    openInfoModal();
  }, [openInfoModal]);

  const activitiesRowsMap = React.useMemo<RowsMap<ActivityType>>(() => [
    {
      title: 'Issue Status',
      id: 'status',
      data: ({ issueStatus }) => {
        return (
        <Status color={activityStatusToView[issueStatus].color}>
          {activityStatusToView[issueStatus].title}
        </Status>
        );
      },
    },
    {
      title: 'License Plate',
      id: 'mainLp',
      data: (activity) => (
        <div onClick={() => openInfoActivityModal(activity)}>{activity.mainLp}</div>
      ),
    },
    {
      title: 'Entry Time',
      id: 'entryTime',
      data: ({ eventTime }) => formatTimeStampToString(eventTime, 'MM/dd/yyyy HH:mm'),
    },
    {
      title: 'Entry Location',
      id: 'deviceId',
      data: ({ deviceId }) => `Layout ${getZone(deviceId)}`,
    },
    {
      title: 'Info',
      id: '',
      data: (activity) => (
        <InfoButton
          onClick={() => openInfoActivityModal(activity)}
        />
      ),
    },
  ], [formatTimeStampToString, openInfoActivityModal]);

  return (
    <Page>
      <CardsWrapper>
        {cardsData.map((props) => (
          <CategoryCard key={props.title} {...props} />
        ))}
      </CardsWrapper>
      <StyledTabs
        indicatorColor="primary"
        textColor="primary"
        value={tab}
        onChange={(ignoredEvent, newValue) => setTab(newValue)}
      >
        <Tab label="Issue Activities" />
        <Tab label="All Activities" disabled />
      </StyledTabs>
      <TableCard>
        <StyledTableHead
          title="Entry Activity List"
          extraContent={(
            <>
              <StyledIconButton
                selected={viewType === 'table'}
                onClick={() => setViewType('table')}
              >
                <ListIcon />
              </StyledIconButton>
              <StyledIconButton
                selected={viewType === 'grid'}
                onClick={() => setViewType('grid')}
                disabled
              >
                <GridIcon />
              </StyledIconButton>
            </>
          )}
        />
        {viewType === 'table' ? (
          <TableContent
            isLoading={activitiesLoading}
            data={activitiesData?.activities}
          >
            <Table
              data={activitiesData?.activities!}
              rowsMap={activitiesRowsMap}
              count={activitiesData?.amount}
              page={activitiesPage}
              onPageChange={(event, page) => {
                setActivitiesPage(page);
              }}
              onRowsPerPageChange={(e) => {
                setActivitiesPerPage(Number(e.target.value));
                setActivitiesPage(0);
              }}
              isLoading={issueActivitiesFetching}
            />
          </TableContent>
        ) : (
          <>
            <ActivitiesListContent
              isLoading={activitiesLoading}
              data={activitiesData?.activities}
            >
              <ActivityList
                activities={activitiesData?.activities!}
                onActivitySelect={openInfoActivityModal}
                onLoadMore={() => 'load more'}
                isLoading={false}
                hasMoreActivities={false}
              />
            </ActivitiesListContent>
          </>
        )}
      </TableCard>
      <Modal fullScreenSize="550px" onClose={closeInfoModal} open={isInfoModalOpen}>
        <Activity activity={selectedActivity!} onClose={closeInfoModal} />
      </Modal>
    </Page>
  );
};

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  margin-bottom: 35px;

  @media (max-width: 1050px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;

const StyledTabs = styled(Tabs)`
  margin-bottom: 20px;
`;

const StyledTableHead = styled(TableHead)`
  margin-bottom: 20px;
`;

const IconButton = styled.button`
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;

  & > svg {
    display: block;
  }
`;

const StyledIconButton = styled(IconButton)<{ selected: boolean; disabled?: boolean; }>`
  margin: 0 5px;
  color: ${({ selected }) => (selected ? vars.palette.primary : 'inherit')};
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
`;

const Status = styled.div<{ color: string; }>`
  color: ${({ color }) => color};
`;

const ActivitiesListContent = styled(TableContent)`
  min-height: 385px;
`;

export default flow(
  withGarage,
  withRole('controlCenter:entryActivityList'),
  withPreloadRole,
  withPreloadGarage,
  withAuth(),
  withLoading(),
)(EntryActivityList);
