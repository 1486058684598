const permitPolicies = [
  {
    day: 'MONDAY',
    startTime: '0000',
    endTime: '2359',
    applicable: true,
  },
  {
    day: 'TUESDAY',
    startTime: '0000',
    endTime: '2359',
    applicable: true,
  },
  {
    day: 'WEDNESDAY',
    startTime: '0000',
    endTime: '2359',
    applicable: true,
  },
  {
    day: 'THURSDAY',
    startTime: '0000',
    endTime: '2359',
    applicable: true,
  },
  {
    day: 'FRIDAY',
    startTime: '0000',
    endTime: '2359',
    applicable: true,
  },
  {
    day: 'SATURDAY',
    startTime: '0000',
    endTime: '2359',
    applicable: true,
  },
  {
    day: 'SUNDAY',
    startTime: '0000',
    endTime: '2359',
    applicable: true,
  },
];

export default permitPolicies;
