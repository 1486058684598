import React from 'react';
import flow from 'lodash/flow';

import Page from '@/components/common/page';

import { withAuth } from '@/utils/hocs/withAuth';
import { withLoading } from '@/utils/hocs/withLoading';
import { withRole } from '@/utils/hocs/withRole';

import MapImage from '@/assets/images/POSTHTX_Map.svg';

interface Props {}

const GuidanceMap: React.FC<Props> = () => {
  return (
    <Page>
      <img src={MapImage} alt="guidance map" />
    </Page>
  );
};

export default flow(
  withRole('guidanceMap'),
  withAuth(),
  withLoading(),
)(GuidanceMap);
