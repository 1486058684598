import React from 'react';

import { z5DateModule } from '@zero5/time-module';


import * as helpers from './helpers';

const useDateModule = () => {
  const [, setRerenderState] = React.useState({});

  
  const rerender = React.useCallback(() => {
    setRerenderState({});
  }, []);

  React.useEffect(() => {
    
    
    z5DateModule.subscribeToTimezoneChange(rerender);

    return () => {
      z5DateModule.unsubscribeFromTimezoneChange(rerender);
    };
  }, [rerender]);

  return {
    z5DateModule,
    ...helpers,
  };
};

export default useDateModule;
