import { useMutation, UseMutationOptions } from 'react-query';

import { systemUserChangePassword } from '@zero5/auth-api';
import toast from '@zero5/ui/lib/utils/toast';

type ChangePasswordArgs = {
  email: string;
  password: string;
  oldPassword: string;
};
type Response = unknown;

const useResetPasswordMutation = (
  options?: UseMutationOptions<Response, Error, ChangePasswordArgs, string>,
) => {
  return useMutation(
    'resetPassword',
    async (params) => {
      const response = await systemUserChangePassword(params);

      return response;
    },
    {
      ...options,
      onSuccess: (...params) => {
        toast('success', 'Password was changed successfully!');
        options?.onSuccess?.(...params);
      },
      onError: (error: Error) => {
        const message = error.message === 'Invalid email or password' ? 'Invalid old password!' : error.message;
        toast('error', message);
      },
    },
  );
};

export default useResetPasswordMutation;
