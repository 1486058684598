import { useQuery, UseQueryOptions } from 'react-query';

import { getHoursOpen } from '@zero5/garage-api';

import { mapGarageHoursPolicies } from '@/api/mappings/garages';
import { GARAGE_HOURS_OPEN_QUERY, GARAGE_QUERY } from '@/api/garage/constants';

import { handleError } from '@/utils/handleError';
import { sortByWeekdays } from '@/utils/date/sortByWeekday';

type Response = ReturnType<typeof mapGarageHoursPolicies>;
type Key = [typeof GARAGE_QUERY, typeof GARAGE_HOURS_OPEN_QUERY];

const useGarageHoursOpenQuery = (
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery(
    [GARAGE_QUERY, GARAGE_HOURS_OPEN_QUERY],
    async () => {
      const response = await getHoursOpen();

      return sortByWeekdays(mapGarageHoursPolicies(response.hoursOpen), (item) => item.day);
    },
    {
      refetchOnWindowFocus: false,
      onError: handleError,
      ...options,
    },
  );
};

export default useGarageHoursOpenQuery;
