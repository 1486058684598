const weights = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
};

export const sortByWeekdays = <T>(data: Array<T>, getDay: (item: T) => keyof typeof weights) => {
  return data.sort((a, b) => weights[getDay(a)] - weights[getDay(b)]);
};
