import { ActivityType } from '@zero5/client-admin-api';

export enum LPRStatus {
  OK = 'OK',
  BLOCKED = 'BLOCKED',
  MISSING = 'MISSING',
  LOW_CONF_LEVEL = 'LOW_CONF_LEVEL',
  INVALID = 'INVALID',
}

export enum ActivityStatus {
  OK = 'OK',
  ERROR = 'ERROR',
}

export enum GatewayType {
  both = 'both',
  entry = 'entry',
  exit = 'exit',
}

export interface Option {
  label: string;
  value: string;
}

export interface SearchFormikState{
  mainLp?: string;
  gatewayType: GatewayType;
  startDate: Date;
  endDate: Date; 
  startTime: null | number;
  endTime: null | number;
  location: string;
}

export interface Activity {
  activityId: string;
  garageId: string;
  deviceId: string;
  frontConfLevel: number | null;
  frontImg: string | null;
  frontLpNum: string | null;
  frontLpState: string | null;
  frontStatus: LPRStatus | null;
  rearConfLevel: number | null;
  rearImg: string | null;
  rearLpNum: string | null;
  rearLpState: string | null;
  rearStatus: LPRStatus | null;
  mainLp: string | null;
  mainState: string | null;
  eventTime: number;
  status: ActivityStatus;
  vehicleType: string | null;
  vehicleMake: string | null;
  vehicleModel: string | null;
  vehicleColor: string | null;
  type?: ActivityType;
}

export interface RevenueHistory {
  date: string;
  visitor: string;
  valet: string;
  event: string;
  permit: string;
  amount: string;
}

export interface ValidationHistory {
  licensePlate: string;
  entryTime: string;
  hours: string;
  by: string;
  type: string;
}

export interface VehicleInformation {
  licensePlate: string;
  vehicle: {
    make: string;
    type: string;
    color: string;
  };
  entryTime: string;
  exitTime: string;
  zone: string;
  entry: string;
  exit: string;
  parkingType: string;
  paymentType: string;
  duration: string;
  fee: string;
  note: string;
}

export interface ValidationInformation {
  licensePlate: string;
  vehicle: {
    make: string;
    type: string;
    color: string;
  };
  entryTime: string;
  exitTime: string;
  zone: string;
  entry: string;
  exit: string;
  parkingType: string;
  hour: string;
  by: string;
  note: string;
}

export interface Invoice {
  date: string;
  paidBy: string;
  purchase: string;
  amount: string;
  receipt: string;
  status: string;
}
