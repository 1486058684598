import { useQuery, UseQueryOptions } from 'react-query';

import { getUserGarageRole } from '@zero5/user-api';

import { mapUserGarageRole } from '@/api/mappings/user';
import user from '@/api/user';

type Response = ReturnType<typeof mapUserGarageRole>;
export const queryKey = 'getUserGarageRole';

const useGarageRolesQuery = (
  options?: UseQueryOptions<Response, unknown, Response, typeof queryKey>,
) => {
  return useQuery(
    queryKey,
    async () => {
      const response = await getUserGarageRole();

      return mapUserGarageRole(response);
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
      onError: () => user.signOut(),
      ...options,
    },
  );
};

export default useGarageRolesQuery;
