import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { updateCurrentUser } from '@zero5/user-api';

import { mapUser } from '@/api/mappings/user';
import { queryKey as companyUsersQueryKey } from '@/api/queries/useCompanyUsersQuery';
import { User } from '@/api/models/users';

type Response = User;

interface UpdateUserRequest {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
}

const useUpdateCurrentUserMutation = (
  options?: UseMutationOptions<Response, Error, UpdateUserRequest, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'updateCurrentUser',
    async (params) => {
      const response = await updateCurrentUser(params);
      return mapUser(response.user);
    },
    {
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries(companyUsersQueryKey);

        options?.onSuccess?.(...params);
      },
    },
  );
};

export default useUpdateCurrentUserMutation;
