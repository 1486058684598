/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { findDriverByEmail } from '@zero5/user-api';

export interface FindDriverByEmailResponse{
  driver: {
    driverId: number;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
  } | null;
}

type Response = FindDriverByEmailResponse;

export const queryKey: QueryKey = 'userDriverByEmailQuery';

const useDriverByEmailQuery = (
  email: string,
  options?: UseQueryOptions<Response, unknown, Response, [typeof queryKey, string]>,
) => {
  return useQuery<Response, unknown, Response, [typeof queryKey, string]>(
    [queryKey, email],
    async () => {
      const response = await findDriverByEmail(email);
      return response;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useDriverByEmailQuery;
