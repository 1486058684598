/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import {
  getRemainingPermitsCount,
} from '@zero5/permit-api';

import { handleError } from '@/utils/handleError';

import { queryGroupName } from './queryGroupName';

type Response = number;

export const queryKey: QueryKey = 'getRemainingPermitsCount';

type Key = [typeof queryGroupName, typeof queryKey];

const useGetRemainingPermitsQuery = (
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    [queryGroupName, queryKey],
    async () => {
      const response = await getRemainingPermitsCount();

      return response.count;
    },
    {
      ...options,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      staleTime: 10 * 60 * 1000,
      onError: (e) => handleError(e),
    },
  );
};

export default useGetRemainingPermitsQuery;
