import { useMutation, UseMutationOptions } from 'react-query';

import { systemUserForgotPassword } from '@zero5/auth-api';
import { Response as ForgotPasswordResponse } from '@zero5/auth-api/lib/src/functions/systemUserForgotPassword/types';

type Response = ForgotPasswordResponse;

const useForgotPasswordMutation = (
  options?: UseMutationOptions<Response, Error, {
    email: string;
  }, string>,
) => {
  return useMutation(
    'forgotPassword',
    async (params) => {
      const response = await systemUserForgotPassword(params);

      return response;
    },
    options,
  );
};

export default useForgotPasswordMutation;
