import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

interface Props {
  text: string;
  isLoading: boolean;
  data: unknown[] | undefined;
}

const CenterTypography: React.FC<Props> = ({ text, isLoading, data }) => {
  return (
    <>
      {((!isLoading && !data) || (data && data.length === 0)) && (
        <Wrapper>
          <Typography variant="h5" align="center" color="textSecondary">
            {text}
          </Typography>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export default CenterTypography;
