import { useQuery, UseQueryOptions } from 'react-query';

import { getLayouts } from '@zero5/garage-api';

import { Option } from '../models/activities';
import { mapLayoutsToOptions } from '../mappings/activity';

type Response = Option[];

export const queryKey = 'useGetLayoutsQuery';

const useGetLayoutsQuery = (
  options?: UseQueryOptions<Response, unknown, Response>,
) => {
  return useQuery<Response, unknown, Response>(
    queryKey,
    async () => {
      const response = await getLayouts();

      return mapLayoutsToOptions(response.layouts);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      ...options,
    },
  );
};

export default useGetLayoutsQuery;
