import React from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Button, Modal, ModalProps,
  Checkbox, Input, InputPrice, Row,
} from '@zero5/ui';
import schemas from '@zero5/ui/lib/utils/validation/schemas';
import { centsToCost, costToCents } from '@zero5/ui/lib/utils/formatters/formatPrice';

import useCreatePermitTypeMutation from '@/api/permitType/useCreatePermitTypeMutation';
import useUpdatePermitTypeMutation from '@/api/permitType/useUpdatePermitTypeMutation';
import { PermitType } from '@/api/permit/models';

import Title from './common/Title';
import CheckboxButtonsList from './common/CheckboxButtonsList';
import eligibleTenantTypes from './utils/eligibleTenantTypes';
import WeekTimeTable from './common/WeekTimeTable';
import utilsPermitPolicies from './utils/permitPolicies';


interface Props extends Omit<ModalProps, 'children'> {
  data?: PermitType;
  title: string;
  isLoading: boolean;
}

const PermitPolicyModal: React.FC<Props> = ({
  onClose, data, title, isLoading, ...props
}) => {
  return (
    <StyledModal
      title={title}
      fullScreenSize="580px"
      onClose={onClose}
      {...props}
    >
      <Content onClose={onClose} data={data} isLoading={isLoading} />
    </StyledModal>
  );
};


type ContentProps = Pick<Props, 'onClose' | 'data' | 'isLoading'>;

const validationSchema = Yup.object().shape({
  permitTypeName: schemas.nameSchema.required().label('Permit Name'),
  price: Yup.number().min(0).required().label('Permit Price'),
  allowedPermits: Yup.number().required().label('Total Number of Allowed Permits').min(0, 'Invalid value'),
});

const Content: React.FC<ContentProps> = ({ onClose, data, isLoading }) => {
  const { mutateAsync: createPermitType, isLoading: isCreateLoading } = useCreatePermitTypeMutation();

  const { mutateAsync: updatePermitType, isLoading: isUpdateLoading } = useUpdatePermitTypeMutation();

  
  const formik = useFormik({
    initialValues: {
      permitTypeName: data?.name || '',
      price: centsToCost(data?.price),
      selectedEligibleTenantTypes: data?.eligibleTenantTypes,
      allowedPermits: data?.maxPermits,
      permitPolicies: data?.schedule.map((item) => ({
        day: item.day,
        startTime: item.start,
        endTime: item.end,
        applicable: true,
      })) || utilsPermitPolicies,
    },
    validationSchema,
    onSubmit: async ({
      permitTypeName,
      price,
      selectedEligibleTenantTypes,
      permitPolicies,
      allowedPermits,
    }) => {
      const cents = costToCents(price);
      const hoursOpen = permitPolicies
        ?.map((item) => ({
          day: item.day,
          start: item.startTime,
          end: item.endTime,
          applicable: item.applicable,
        }))
        .filter(({ applicable }) => applicable) || [];

      const tenantTypes = selectedEligibleTenantTypes || [];
      const maxPermits = allowedPermits || 0;
      if (data) {
        await updatePermitType({
          id: data.id,
          permitType: {
            permitTypeName,
            price: cents,
            hoursOpen,
            eligibleTenantTypes: tenantTypes,
            maxPermits: maxPermits,
          },
        });

        onClose();
      } else {
        await createPermitType({
          permitTypeName,
          price: cents,
          hoursOpen,
          eligibleTenantTypes: tenantTypes,
          maxPermits: maxPermits,
        });

        onClose();
      }
    },
  });

  const transformedInputChangeHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => {
      formik.setFieldValue(e.target.name, value);
    }, [formik],
  );

  const onMaxPermitChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = Number(e.target.value);
    if (isNaN(newValue)) return;
    formik.setFieldValue('allowedPermits', newValue);
  };

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Title>Permit Detail</Title>
        <FlexRow>
          <Input
            label="Permit Name"
            name="permitTypeName"
            value={formik.values.permitTypeName}
            onChange={transformedInputChangeHandler}
            transform="capitalize"
            error={Boolean(
              formik.touched.permitTypeName && formik.errors.permitTypeName,
            )}
            helperText={formik.errors.permitTypeName}
            isLoading={isLoading}
            variant="rect"
            height={36}
          />
          <InputPrice
            label="Permit Price (per month)"
            value={formik.values.price}
            onChangeValue={(value, sourceInfo) => {
              if (sourceInfo.source === 'prop') return;

              formik.setFieldValue('price', value);
            }}
            error={Boolean(
              formik.touched.price && formik.errors.price,
            )}
            helperText={formik.errors.price}
            isLoading={isLoading}
            variant="rect"
            height={36}
          />
          <Input
            label="Total Number of Allowed Permits"
            name="allowedPermits"
            onChange={onMaxPermitChange}
            value={formik.values.allowedPermits ?? ''}
            error={Boolean(
              formik.touched.allowedPermits && formik.errors.allowedPermits,
            )}
            helperText={formik.errors.allowedPermits}
          />
        </FlexRow>
          <TenantTypesTitleWrapper>
          <TenantTypesTitle>Eligible Business Types</TenantTypesTitle>
          <CheckboxLabel>
            <StyledCheckbox
              onChange={
                (e, checked) => formik.setFieldValue(
                  'selectedEligibleTenantTypes',
                  checked ? eligibleTenantTypes : undefined,
                )
              }
              checked={eligibleTenantTypes.length === formik.values.selectedEligibleTenantTypes?.length}
            />
            Select All
          </CheckboxLabel>
        </TenantTypesTitleWrapper>
        <StyledCheckboxButtonsList
          options={eligibleTenantTypes}
          checked={formik.values.selectedEligibleTenantTypes}
          onChange={(selected) => formik.setFieldValue('selectedEligibleTenantTypes', selected)}
        />
        <Title>Allowed Permit Hours</Title>
        <WeekTimeTable
          data={formik.values.permitPolicies}
          onChange={(newData) => formik.setFieldValue('permitPolicies', newData)}
          isLoading={isLoading}
        />        
        <Row justifyContent="flex-end">
          <Button
            variant="contained"
            type="submit"
            loading={ isCreateLoading || isUpdateLoading || isLoading }
            color="primary"
            disabled={!formik.dirty}
          >
            Save
          </Button>
          <Button variant="text" color="primary" onClick={onClose}>Cancel</Button>
        </Row>
      </Form>
    </>
  );
};

const StyledModal = styled(Modal)`
max-width: 750px;
width: 100%;

@media (max-width: 1220px) {
  margin: auto 30px;
}

@media (max-width: 800px) {
  margin: auto;
}
`;

const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;
  
  & > * {
    min-width: 200px;
    flex-grow: 1;
  }
`;

// const TypesAndFeesWrapper = styled(FlexRow)`

// `;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const TenantTypesTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledCheckboxButtonsList = styled(CheckboxButtonsList)`
  margin-bottom: 30px;
` as typeof CheckboxButtonsList;

const TenantTypesTitle = styled(Title)`
  margin-right: 10px;
  margin-bottom: 0;
`;

const CheckboxLabel = styled.label`
  white-space: nowrap;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 10px;
`;

export default PermitPolicyModal;
