import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { deleteTenantCompany } from '@zero5/user-api';

import { tenantQueryKeys } from './queryKeys';

type Response = void;

const useDeleteTenantCompanyMutation = (
  options?: UseMutationOptions<Response, Error, {
    companyId: number;
  }, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'deleteTenantCompany',
    async ({ companyId }) => {
      await deleteTenantCompany(companyId);
      return;
    },
    {
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries(tenantQueryKeys.garageTenants());
        queryClient.invalidateQueries(tenantQueryKeys.count());

        options?.onSuccess?.(...params);
      },
    },
  );
};

export default useDeleteTenantCompanyMutation;
