import React from 'react';
import styled from 'styled-components';

import vars from '@zero5/ui/lib/styles/vars';

export interface Props<T> extends Omit<React.HTMLAttributes<HTMLUListElement>, 'onChange'> {
  options: Array<T>;
  checked?: Array<T>;
  getOptionLabel?: (option: T) => React.ReactNode;
  getOptionValue?: (option: T) => number | string | null | undefined;
  onChange: (option: Array<T>) => void;
}

type GenericOptionType = object | number | string;

const defaultGetOptionLabel = (option: GenericOptionType) => {
  if (typeof option === 'object') {
    return 'label' in option ? (option as { label: string; }).label : JSON.stringify(option);
  }

  return option;
};

const defaultGetOptionValue = (option: GenericOptionType) => {
  if (typeof option === 'object') {
    return 'value' in option ? (option as { value: string; }).value : JSON.stringify(option);
  }

  return option;
};

const CheckboxButtonsList = <T extends GenericOptionType>({
  options,
  checked = [],
  getOptionLabel = defaultGetOptionLabel,
  getOptionValue = defaultGetOptionValue,
  onChange,
  className,
}: Props<T>) => {
  const isOptionsEqual = React.useCallback(
    (option1: T, option2: T) => getOptionValue(option1) === getOptionValue(option2),
    [getOptionValue],
  );

  return (
    <List className={className}>
      {options.map((option) => {
        const isChecked = checked.some((checkedOption) => isOptionsEqual(checkedOption, option));

        return (
          <li>
            <Option
              key={getOptionValue(option)}
              $checked={isChecked}
              type="button"
              onClick={
              () => onChange(
                isChecked
                  ? checked.filter((checkedOption) => !isOptionsEqual(checkedOption, option))
                  : [...checked, option],
              )
            }
            >
              {getOptionLabel(option)}
            </Option>
          </li>
        );
      })}
    </List>
  );
};

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Option = styled.button<{ $checked: boolean; }>`
  padding: 10px 15px;
  background-color: ${({ $checked }) => ($checked ? vars.palette.primary : '#C4C4C4')};
  color: #ffffff;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  transition: background-color 0.3s;
`;

export default CheckboxButtonsList;
