import React from 'react';
import ReactDOM from 'react-dom';

import { initializeGarageApi } from '@zero5/garage-api';
import { initialize as initializeClientAdminApi } from '@zero5/client-admin-api';
import { initializeRest as initializeUserApi } from '@zero5/user-api';
import { initializeRest as initializeAuthApi, setGarageId } from '@zero5/auth-api';
import { initializeRest as initializeActivityApi, setGarageId as setActivityGarageId } from '@zero5/activity-api';
import { initialize as initializePermitApi } from '@zero5/permit-api';
import { initializeRest as initializePaymentApi } from '@zero5/payment-api';
import { initialize } from '@zero5/tenant-api'; // getCompanyGarageSigns uses this

import { apiInstance } from '@/api';

import App from '@/containers/App';
import { DEV_GARAGE_ID } from '@/devConstants';

initialize(process.env.API_GATEWAY_URL!, process.env.WEBSOCKET_URL_DEVICE!, apiInstance);
initializeGarageApi(process.env.API_GATEWAY_URL!, apiInstance);
initializeClientAdminApi(process.env.API_GATEWAY_URL!, process.env.WEBSOCKET_URL_CA!, apiInstance);
initializeUserApi(process.env.API_GATEWAY_URL!, apiInstance);
initializeAuthApi(process.env.API_GATEWAY_URL!, apiInstance);
initializeActivityApi(process.env.API_GATEWAY_URL!, apiInstance);
initializePermitApi(process.env.API_GATEWAY_URL!, apiInstance);
initializePaymentApi(process.env.API_GATEWAY_URL!, apiInstance);
setActivityGarageId(DEV_GARAGE_ID);
setGarageId(DEV_GARAGE_ID);

ReactDOM.render(<App />, document.querySelector('#root'));
