import React from 'react';
import styled from 'styled-components';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  minWidth?: string;
}

const Table: React.FC<Props> = ({
  children,
  minWidth = 'unset',
  className,
}) => {
  return (
    <TableWrapper className={className}>
      <StyledTable $minWidth={minWidth}>
        {children}
      </StyledTable>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  overflow-x: auto;
`;

const StyledTable = styled.table<{ $minWidth: string; }>`
  width: 100%;
  margin-bottom: 35px;
  min-width: ${({ $minWidth }) => $minWidth};
`;

export const TableCell = styled.td`
  min-width:91px;
  padding: 5px;
`;

export const HeadCell = styled.th`
  padding: 10px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
`;

export default Table;
