/* eslint-disable max-len */
import { QueryKey, useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { updateCompanyFeatures } from '@zero5/user-api';

import { CompanyFeatures } from '@/api/models/companyFeatures';

import { companyFeatureQueryKeys } from './queryKeys';

export const queryKey: QueryKey = 'updateCompanyFeature';

export interface UpdateCompanyFeaturesRequest {
  canCreatePermits: boolean;
  canValidateVisitors: boolean;
  canRequestEvents: boolean;
  createPermitsLimit?: number;
}

type Params = {
  companyId: number;
  args: UpdateCompanyFeaturesRequest;
};

export interface Response {
  features: CompanyFeatures;
}

const useUpdateCompanyFeaturesMutation = (
  options?: UseMutationOptions <Response, unknown, Params, typeof queryKey>,
) => {
  const queryClient = useQueryClient();

  return useMutation<Response, unknown, Params, typeof queryKey>(
    queryKey,
    async (params: Params) => {
      const { companyId, args } = params;
      const response = await updateCompanyFeatures(companyId, args);
      return response;
    },
    {
      ...options,
      onSuccess: async (response, vars, ctx) => {
        queryClient.invalidateQueries(companyFeatureQueryKeys.companyFeatures(vars.companyId));

        options?.onSuccess?.(response, vars, ctx);
      },
    },
  );
};

export default useUpdateCompanyFeaturesMutation;
