import { createReducerFunction, ImmerReducer } from 'immer-reducer';
import { QueryClient } from 'react-query';

import { DateRange } from '@zero5/ui/lib/Chart';

type EventUpdater = (dateRange: DateRange, queryClient: QueryClient, tab: number) => void;

export interface EventsState {
  updateEvent: EventUpdater;
}

const initialState: EventsState = {
  updateEvent: () => console.error('Event updater was not initialized'),
};

export class EventsReducer extends ImmerReducer<EventsState> {
  setEventUpdater(updateEvent: EventUpdater) {
    this.draftState.updateEvent = updateEvent;
  }
}

export default createReducerFunction(EventsReducer, initialState);
