/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getForecastedRevenue } from '@zero5/payment-api';

import { mapRevenueForecastedReports } from './mappings';
import { queryGroupName } from './queryGroupName';

type Response = ReturnType<typeof mapRevenueForecastedReports>;
type Params = {
  page?: number;
  take?: number;
  orderBy?: 'date' | 'permit' | 'event' | 'transient' | 'validation' | 'total';
  direction?: 'DESC' | 'ASC';
};
type Key = [typeof queryGroupName, typeof queryKey, Params];

export const queryKey: QueryKey = 'revenueForecasted';

const useRevenueForecastedQuery = (
  params: Params,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    [queryGroupName, queryKey, params],
    async () => {
      const response = await getForecastedRevenue(params);

      return mapRevenueForecastedReports(response);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      keepPreviousData: true,
      ...options,
    },
  );
};

export default useRevenueForecastedQuery;
