import { createReducerFunction, ImmerReducer } from 'immer-reducer';

import { Garage } from '@/api/models/garages';

export interface WorkspaceState {
  currentGarage: Garage | null;
}

const initialState: WorkspaceState = {
  currentGarage: null,
};

export class WorkspaceReducer extends ImmerReducer<WorkspaceState> {
  setCurrentGarage(garage: Garage) {
    this.draftState.currentGarage = garage;
  }
}

export default createReducerFunction(WorkspaceReducer, initialState);
