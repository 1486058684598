import React from 'react';
import styled, { css } from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Button,
  Checkbox,
  Input,
  InputPhone,
  Modal,
  ModalProps,
  Row,
} from '@zero5/ui';
import vars from '@zero5/ui/lib/styles/vars';
import schemas from '@zero5/ui/lib/utils/validation/schemas';

import useCreateEventMutation from '@/api/mutations/useCreateEventMutation';

import TimePicker from '@/components/common/SelectTimePicker';
import DatePicker from '@/components/common/DatePicker';

import { timeConsecutionValidation } from '@/utils/validation/schemas';
import useDateModule from '@/utils/date/useDateModule';
import { validateNumber } from '@/utils/validateNumber';

import CalendarImage from '@/assets/images/calendar.svg';

interface Props extends Omit<ModalProps, 'children'> {}

const eventNameMaxCharsErrorMsg = 'You have exceeded the maximum number of 40 characters in this field';

const validationSchema = Yup.object().shape({
  eventName: schemas.nameSchema.label('Event Name')
    .max(40, eventNameMaxCharsErrorMsg),
  startDate: schemas.dateSchema.label('Start Date'),
  endDate: schemas.dateSchema.label('End Date'),
  startTime: Yup
    .string()
    .nullable()
    .required('Date is required')
    .label('Time')
    .concat(timeConsecutionValidation),
  endTime: Yup.string().nullable().required().label('Time'),
  valetSpaces: schemas.digitsSchema
    .required()
    .label('Required Valet Spaces'),
  parkingSpaces: schemas.digitsSchema
    .required()
    .label('Required Parking Spaces'),
  firstName: schemas.nameSchema.label('First Name'),
  lastName: schemas.nameSchema.label('Last Name'),
  email: schemas.emailSchema,
  phone: schemas.phoneSchema.required(),
});

const EventAddModal: React.FC<Props> = ({ onClose, ...props }) => {
  return (
    <StyledModal
      title="New Event"
      fullScreenSize="580px"
      onClose={onClose}
      {...props}
    >
      <Content onClose={onClose} />
    </StyledModal>
  );
};

type ContentProps = Pick<Props, 'onClose'>;

const Content: React.FC<ContentProps> = ({ onClose }) => {
  const createMutation = useCreateEventMutation({
    onSuccess: () => {
      onClose();
      formik.resetForm();
    },
  });

  const {
    convertDateToTimezoneUtc, getTimeZoneDate, setTime, getStartOfDay, getHour,
  } = useDateModule();

  const formik = useFormik<{
    eventName: string;
    startDate: Date | null;
    endDate: Date | null;
    startTime: number | null;
    endTime: number | null;
    valetSpaces: string;
    parkingSpaces: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isPrivate: boolean;
  }>({
    initialValues: {
      eventName: '',
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      valetSpaces: '',
      parkingSpaces: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      isPrivate: false,
    },
    onSubmit: async ({
      eventName,
      startTime,
      endTime,
      endDate,
      startDate,
      firstName,
      lastName,
      email,
      phone,
      parkingSpaces,
      valetSpaces,
      isPrivate,
    }) => {
      const finalStartTime = convertDateToTimezoneUtc(
        setTime(getStartOfDay(startDate!), 'hour', startTime!),
      );
      const finalEndTime = convertDateToTimezoneUtc(
        setTime(getStartOfDay(endDate!), 'hour', endTime!),
      );
      const formData = {
        eventName: eventName!,
        startTime: finalStartTime,
        endTime: finalEndTime,
        requiredValetSpaces: Number(valetSpaces),
        requiredParkingSpaces: Number(parkingSpaces),
        firstName: firstName!,
        lastName: lastName!,
        email: email!,
        phone: phone!,
        isPrivate,
      };
      await createMutation.mutateAsync({ ...formData });
    },
    validationSchema,
  });

  const transformedInputChangeHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => {
      formik.setFieldValue(e.target.name, value);
    }, [formik],
  );

  const isDisableEndTimeHandler = React.useMemo(() => {
    if (formik.values.startDate && formik.values.endDate) {
      const startDate = getTimeZoneDate(formik.values.startDate.getTime());
      const endDate = getTimeZoneDate(formik.values.endDate.getTime());
      return (startDate.getDay() === endDate.getDay());
    }
    return false;
  }, [formik.values.startDate, formik.values.endDate]);

  const disableStartTimeHoursHandler = React.useMemo(() => {
    if (formik.values.startDate) {
      const now = new Date();
      const startDate = getTimeZoneDate(formik.values.startDate.getTime());
      const currentDate = getTimeZoneDate(now.getTime());

      return startDate.getDay() === currentDate.getDay() 
        ? Number(getHour(now.getTime()))
        : undefined;
    }
    return undefined;
  }, [formik.values.startDate, formik.values.endDate]);

  const transformToNumberChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement  | HTMLTextAreaElement>) => {
    formik.setFieldValue(e.target.name, validateNumber(e.target.value));
  };

  return (
    <Wrapper data-test="new-event-modal">
      <ImageWrapper>
        <StyledImage src={CalendarImage} />
      </ImageWrapper>
      <Form onSubmit={formik.handleSubmit}>
        <SectionTitle>Event Information</SectionTitle>
        <Grid>
          <StyledInput
            columns={12}
            label="Event Name"
            name="eventName"
            value={formik.values.eventName}
            onChange={formik.handleChange}
            error={Boolean(
              (formik.touched.eventName && formik.errors.eventName)
              || formik.errors.eventName === eventNameMaxCharsErrorMsg,
            )}
            helperText={formik.errors.eventName}
          />
          <StyledDatePicker
            columns={6}
            label="Date"
            helperText={formik.errors.startDate || formik.errors.endDate}
            error={Boolean(
              (formik.touched.startDate && formik.errors.startDate)
              || (formik.touched.endDate && formik.errors.endDate),
            )}
            pickerProps={{
              selectsRange: true,
              dateFormat: 'MM/dd/yy',
              minDate: new Date(),
              startDate: formik.values.startDate,
              endDate: formik.values.endDate,
              placeholderText: 'mm/dd/yy - mm/dd/yy',
              onChange: (selectedDate: [Date, Date]) => {
                formik.setFieldValue('startDate', selectedDate[0]);
                formik.setFieldValue('endDate', selectedDate[1]);
              },
            }}
          />
          <StyledTimePicker
            disabled={!(formik.values.startDate && formik.values.endDate)}
            columns={6}
            startTime={formik.values.startTime}
            endTime={formik.values.endTime}
            isDisableEndTime={isDisableEndTimeHandler}
            disableStartTimeHours={disableStartTimeHoursHandler}
            onChangeStartTime={(hours) => formik.setFieldValue('startTime', hours)}
            onChangeEndTime={(hours) => formik.setFieldValue('endTime', hours)}
            error={Boolean(
              (formik.touched.endTime && formik.errors.endTime)
                || (formik.touched.startTime && formik.errors.startTime),
            )}
            helperText={formik.errors.endTime || formik.errors.startTime}
            testStartTimeId="event-start-time-modal"
            testEndTimeId="event-end-time-modal"
          />
          <StyledInput
            columns={6}
            label="Required Valet Spaces"
            name="valetSpaces"
            value={formik.values.valetSpaces}
            // eslint-disable-next-line max-len
            onChange={transformToNumberChangeHandler}
            error={Boolean(
              formik.touched.valetSpaces
                && formik.errors.valetSpaces,
            )}
            helperText={formik.errors.valetSpaces}
          />
          <StyledInput
            columns={6}
            label="Required Parking Spaces"
            name="parkingSpaces"
            value={formik.values.parkingSpaces}
            onChange={transformToNumberChangeHandler}
            error={Boolean(
              formik.touched.parkingSpaces
                && formik.errors.parkingSpaces,
            )}
            helperText={formik.errors.parkingSpaces}
          />
        </Grid>
        <SectionTitle>
         Settings
        </SectionTitle>
        <SettingContent>
          <CheckboxLabel>
            <StyledCheckbox
              checked={formik.values.isPrivate}
              onChange={(value, checked) => formik.setFieldValue('isPrivate', checked)}
            />
          </CheckboxLabel>
          <CheckboxDescription>
            Apply default event parking fee for the entire lot
          </CheckboxDescription>
        </SettingContent>

        <SectionTitle>Contact Information</SectionTitle>
        <Grid>
          <StyledInput
            columns={6}
            label="First Name"
            name="firstName"
            value={formik.values.firstName}
            onChange={transformedInputChangeHandler}
            error={Boolean(
              formik.touched.firstName && formik.errors.firstName,
            )}
            helperText={formik.errors.firstName}
            transform="capitalize"
          />
          <StyledInput
            columns={6}
            label="Last Name"
            name="lastName"
            value={formik.values.lastName}
            onChange={transformedInputChangeHandler}
            error={Boolean(formik.touched.lastName && formik.errors.lastName)}
            helperText={formik.errors.lastName}
            transform="capitalize"
          />
          <StyledInput
            columns={6}
            label="Email"
            name="email"
            value={formik.values.email}
            onChange={transformedInputChangeHandler}
            error={Boolean(formik.touched.email && formik.errors.email)}
            helperText={formik.errors.email}
            transform="lowercase"
          />
          <StyledInputPhone
            columns={6}
            label="Phone Number"
            name="phone"
            value={formik.values.phone}
            onChangeValue={(newPhone) => formik.setFieldValue('phone', newPhone)}
            error={Boolean(formik.touched.phone && formik.errors.phone)}
            helperText={formik.errors.phone}
          />
        </Grid>
        {/* <SectionTitle>
         Settings
        </SectionTitle>
        <SettingContent>
          <CheckboxLabel>
            <StyledCheckbox
              checked={formik.values.isPrivate}
              onChange={(value, checked) => formik.setFieldValue('isPrivate', checked)}
            />
          </CheckboxLabel>
          <CheckboxDescription>
            Apply event parking fee for the entire lot
          </CheckboxDescription>
        </SettingContent> */}
        <ButtonsRow justifyContent="flex-end">
          <StyledButton
            loading={formik.isSubmitting}
            variant="contained"
            color="primary"
            type="submit"
            disabled={formik.isSubmitting || !formik.dirty}
            data-test="create-event-btn"
          >
            Create Event
          </StyledButton>
          <Button variant="text" color="primary" onClick={onClose}>
            Cancel
          </Button>
        </ButtonsRow>
      </Form>
    </Wrapper>
  );
};

const StyledModal = styled(Modal)`
@media (max-width: 1220px) {
  margin: auto 30px;
}

@media (max-width: 800px) {
  margin: auto;
}
`;

const SettingContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const CheckboxDescription = styled.div`

  @media (max-width: 420px) {
    min-width: unset;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 10px;
`;


const Wrapper = styled.div`
display: grid;
grid-template-columns: minmax(250px, 380px) minmax(420px, 700px);

@media (max-width: 800px) {
  grid-template-columns: 1fr;
  grid-template-rows: 250px 1fr;
}
`;

const ImageWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
`;

const StyledImage = styled.img``;

const Form = styled.form`
display: flex;
flex-direction: column;
padding-left: 20px;
border-left: 1px solid ${vars.palette.border};

@media (max-width: 800px) {
  padding-left: 0;
  padding-top: 20px;
  border-left: none;
  border-top: 1px solid ${vars.palette.border};
}
`;

const SectionTitle = styled.div`
margin-bottom: 20px;
font-weight: 600;
`;

const Grid = styled.div`
display: grid;
grid-template-columns: repeat(12, 1fr);
grid-gap: 20px;
margin-bottom: 20px;
`;

const columnStyles = css<{ columns: number; }>`
grid-column: span ${({ columns }) => columns};
grid-row: span 1;

@media (max-width: 520px) {
  grid-column: span 12;
}
`;

const StyledInput = styled(Input)<{ columns: number; }>`
${columnStyles}
`;

const StyledInputPhone = styled(InputPhone)<{ columns: number; }>`
${columnStyles}
`;

const StyledDatePicker = styled(DatePicker)<{ columns: number; }>`
${columnStyles}
`;

const StyledTimePicker = styled(TimePicker)`
  ${columnStyles}
`;

const StyledButton = styled(Button)`
margin-right: 10px;
min-width:80px;
`;

const ButtonsRow = styled(Row)`
margin-top: auto;
`;

export default EventAddModal;
