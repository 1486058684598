import { useQuery, UseQueryOptions } from 'react-query';

import { getSelfSystemUser } from '@zero5/auth-api';

import { mapSelfUser } from '@/api/mappings/user';

type Response = ReturnType<typeof mapSelfUser>;

export const queryKey = 'selfUser';

const useSelfUserQuery = (
  options?: UseQueryOptions<Response, unknown, Response, string>,
) => {
  return useQuery<Response, unknown, Response, string>(
    queryKey,
    async () => {
      const response = await getSelfSystemUser();

      return mapSelfUser(response);
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
      ...options,
    },
  );
};

export default useSelfUserQuery;
