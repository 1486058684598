// /* eslint-disable max-len */
import { useMutation, UseMutationOptions } from 'react-query';

import {
  getForecastedRevenueDetailsCsvReport,
} from '@zero5/payment-api';


type Response = string;
type Params = {
  from: number;
  until: number;
};

const useForecastedRevenueDetailsReportCsvMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  return useMutation(
    'getForecastedRevenueDetailsReportCsv',
    async (params) => {
      const response = await getForecastedRevenueDetailsCsvReport(params);

      return response.report;
    },
    options,
  );
};

export default useForecastedRevenueDetailsReportCsvMutation;
