import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { setPermitTypesCompanyLimits } from '@zero5/permit-api';

import { permitQueryKeys } from './queryKeys';

type Response = unknown;
type Params = {
  companyId: number;
  limits: {
    permitTypeId: number;
    maxPermits: number;
  }[];
};

const useSetPermitTypesCompanyLimitsMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'setPermitTypesCompanyLimits',
    async ({ companyId, ...params }) => {
      const response = await setPermitTypesCompanyLimits(companyId, params);

      return response;
    },
    {
      ...options,
      onSuccess: (...params) => {
        const companyId = params[1].companyId;
        const limits = params[1].limits;

        queryClient.invalidateQueries(permitQueryKeys.permitTypesCompanyLimits(companyId));
        limits.forEach(({ permitTypeId }) => {
          queryClient.invalidateQueries(permitQueryKeys.permitTypeLimitInfo(companyId, permitTypeId));
        });

        options?.onSuccess?.(...params);
      },
    },
  );
};

export default useSetPermitTypesCompanyLimitsMutation;
