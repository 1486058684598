import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { createApprovedEvent } from '@zero5/garage-api';

import { queryKey as eventsQueryKey } from '@/api/queries/useEventsQuery';

import { handleError } from '@/utils/handleError';

type Params = {
  eventName: string;
  startTime: number;
  endTime: number;
  requiredValetSpaces: number;
  requiredParkingSpaces: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  description?: string;
  isPrivate: boolean;
};
type Response = unknown;

const useCreateEventMutation = (
  options?: UseMutationOptions<Response, unknown, Params, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'createEvent',
    async (params) => {
      const response = await createApprovedEvent(params);
      return response;
    },
    {
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries(eventsQueryKey);
        options?.onSuccess?.(...params);
      },
      onError: (error) => handleError(error),
    },
  );
};

export default useCreateEventMutation;
