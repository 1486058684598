export interface Device {
  deviceId: string;
  layout: string;
  entryExit: string;
  gateLPR: string;
  gateControl: boolean;
  gateLock: boolean;
  downLock: boolean;
  aps: string;
  mLPR: string;
}
export interface ActionDeviceRequest{
  deviceId: string;
  isOpen?: boolean;
  isLocked?: boolean;
}
export interface GateLockRequest{
  deviceId: string;
  isLocked: boolean;
}

export interface DeviceResponse{
  deviceId: string;
  location: string;
  companyName: string;
  deviceType: string;
  isOpen: boolean;
}

export enum DeviceWayType {
  ENTRY = 'Entry',
  EXIT = 'Exit',
}

export enum GateLockType {
  LOCK = 0,
  UNLOCK = 1,
}

export enum DeviceRole {
  CAMERA = 'glpr_camera',
  GATE = 'gate',
  aps = 'aps',
  lpr = 'lpr',
}


export function setWayOfGate(entry: String): DeviceWayType {
  switch (entry.includes('out')) {
    case true:
      return DeviceWayType.EXIT;

    case false:
      return DeviceWayType.ENTRY;

    default:
      return DeviceWayType.EXIT;
  }
}
