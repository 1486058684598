import React from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import {
  Button, Modal, ModalProps,
} from '@zero5/ui';
import { DeviceActionFrontType } from '@zero5/garage-api';

import { openAllGatesAction } from '@/store/actions/device';

import warningImage from '@/assets/images/warn.svg';

interface Props extends Omit<ModalProps, 'children' | 'onClose'> {
  onReject: () => void;
  rejectText?: string;
  loading?: boolean;
  textButton: string;
  action: DeviceActionFrontType;
}

const OpenAllGatesModal: React.FC<Props> = ({
  onReject,
  rejectText = 'CANCEL',
  textButton,
  action,
  loading,
  ...props
}) => {
  const dispatch = useDispatch();

  const openAllGatesHandler = () => {
    dispatch(openAllGatesAction(action));
    onReject();
  };

  return (
    <StyledModal onClose={onReject} {...props} data-test="open-all-gates-modal">
      <StyledGrid container spacing={2}>
        <Grid xs={12}>
          <ImageWrapper>
            <StyledImage src={warningImage} />
          </ImageWrapper>
        </Grid>
        <Grid xs={12}>
          <TextWrapper>
            <StyledTypographyText
              variant="body1"
            >
              Please confirm your action.
            </StyledTypographyText>
          </TextWrapper>
        </Grid>
      </StyledGrid>
      <ButtonWrapper>
        <StyledButton
          style={{
            backgroundColor: '#21CE99',
          }}
          variant="contained"
          color="primary"
          loading={loading}
          onClick={openAllGatesHandler}
          data-test="open-all-gates-modal-open-btn"
        >
          <StyledButtonText>
            {' '}
            {textButton}
          </StyledButtonText>
        </StyledButton>
        <StyledButton
          style={{
            color: '#21CE99',
            marginRight: '10px',
          }}
          variant="text"
          color="primary"
          onClick={onReject}
          disabled={loading}
          data-test="open-all-gates-modal-cancel-btn"
        >
          <StyledButtonText>
            {' '}
            {rejectText}
          </StyledButtonText>
        </StyledButton>
      </ButtonWrapper>
    </StyledModal>
  );
};

const StyledButtonText = styled.span`
font-style: normal;
font-weight: 700;
font-size: 11px;
line-height: 22px;
`;

const StyledGrid = styled(Grid)`
   @media (max-width: 600px){
      margin-top:25%;
  }
`;

const ButtonWrapper = styled.div`
margin-top:40px;
height:100%;
display: flex;
align-items: flex-end;
justify-content: flex-end;
`;

const ImageWrapper = styled.div`
margin-top:20px;
display: flex;
align-items: center;
justify-content: center;
`;
const TextWrapper = styled.div`
margin-top:20px;
display: flex;
align-items: center;
justify-content: center;
`;

const StyledTypographyText = styled(Typography)`
  font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: rgb(0, 0, 0);
`;

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  max-width:80%;
  @media (max-width: 600px){
    max-width:100%;
    height:100%;
  }
`;

const StyledButton = styled(Button)`
  min-width:100px;
  font-size:15px;
  @media (max-width: 600px){
    min-width:100px;
  }
`;

const StyledImage = styled.img`
width:200px;
height:200px;
`;

export default OpenAllGatesModal;
