import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from '@material-ui/core';
import flow from 'lodash/flow';

import { Card, LoadingIndicator } from '@zero5/ui';
import vars from '@zero5/ui/lib/styles/vars';

import useGarageHoursOpenQuery from '@/api/garage/useGarageHoursOpenQuery';
import useGarageOptionsQuery from '@/api/queries/useGarageOptionsQuery';

import Page from '@/components/common/page';
import HoursAndFees from '@/components/ParkingPolicy/HoursAndFees';
import Validation from '@/components/ParkingPolicy/Validation';
import { useFindCurrentAction } from '@/components/common/Role';
import Permit from '@/components/ParkingPolicy/Permit';
import { sortPolicyArray } from '@/components/ParkingPolicy/utils/sortPolicyArray';

import { withAuth } from '@/utils/hocs/withAuth';
import { withPreloadRole, withRole } from '@/utils/hocs/withRole';
import { withLoading } from '@/utils/hocs/withLoading';
import { withGarage, withPreloadGarage } from '@/utils/hocs/withGarage';

import PenIcon from '@/assets/icons/pen.inline.svg';

enum TabsEnum {
  OVERVIEW,
  HOURS_AND_FEES,
  VALIDATION,
  PERMIT,
}

/*

D D D D D D

D D [D D] D D -> [D D] [X X] [D D]

*/

const tabletOrMobileQuery = '(max-width: 690px)';

const ParkingPolicy: React.FC = () => {
  const findCurrentAction = useFindCurrentAction();

  const [tab, setTab] = React.useState<TabsEnum>(0);
  const handleTabChange = React.useCallback((event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  }, []);

  const isTabletOrMobile = useMediaQuery(tabletOrMobileQuery);

  const {
    data: hoursPoliciesData,
    isLoading: hoursPoliciesLoading,
  } = useGarageHoursOpenQuery();

  const {
    data: garageOptions,
    isLoading: garageOptionsLoading,
  } = useGarageOptionsQuery();

  const hoursAndFeesHidden = React.useMemo(() => {
    return findCurrentAction('parkingPolicy:hoursAndFees') === 'h';
  }, [findCurrentAction]);

  const moneySectionHidden = React.useMemo(() => {
    return findCurrentAction('parkingPolicy:hoursAndFees:money') === 'h';
  }, [findCurrentAction]);

  const validationSectionHidden = React.useMemo(() => {
    return findCurrentAction('parkingPolicy:validation') === 'h';
  }, [findCurrentAction]);

  const permitSectionHidden = React.useMemo(() => {
    return findCurrentAction('parkingPolicy:permit') === 'h';
  }, [findCurrentAction]);

  return (
    <Page>
      <StyledCard>
        <MainTitle>Operation Settings</MainTitle>
        <ContentWrapper>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            orientation={isTabletOrMobile ? 'horizontal' : 'vertical'}
            textColor="primary"
            indicatorColor="primary"
            scrollButtons={isTabletOrMobile ? 'on' : 'off'}
            variant="scrollable"
          >
            <Tab label="Overview" data-test="parking-policy-overview"/>
            {!hoursAndFeesHidden && (
              <Tab label="Hours and Fees" />
            )}
            {!validationSectionHidden && (
              <Tab label="Validation" />
            )}
            {!permitSectionHidden && (
              <Tab label="Permit" id={'permitPolicyTab'} />
            )}
          </Tabs>
          <Content>
            {tab === TabsEnum.OVERVIEW && (
              <>
                {!hoursAndFeesHidden && (
                  hoursPoliciesLoading || garageOptionsLoading
                    ? <LoadingIndicator />
                    : (<>
                    <Title onClick={() => setTab(TabsEnum.HOURS_AND_FEES)} data-test="parking-policy-edit-hours-fees">
                      Operating Hours and Fees
                      <StyledPenIcon />
                    </Title>
                    <TableWrapper data-test="parking-policy-days">
                      <OverviewTable $minWidth="250px">
                        {sortPolicyArray(hoursPoliciesData).map(({
                          day, start, end, cost, isClosed,
                        }, idx, array) => (isClosed ? (
                          <tr>
                            <TableCell
                              style={{ fontWeight: '600' }}
                            >
                              {array[idx - 1]?.day === day ? '' : day.charAt(0) + day.slice(1).toLowerCase()}
                            </TableCell>
                            <TableCell>CLOSED</TableCell>
                            <TableCell />
                          </tr>
                        ) : (
                          !isTabletOrMobile ? (
                            <tr>
                              <TableCell
                                width="20%"
                                style={{ fontWeight: '600' }}
                              >
                                {array[idx - 1]?.day === day ? '' : day.charAt(0) + day.slice(1).toLowerCase()}
                              </TableCell>
                              <TableCell width="20%">
                                {start.slice(0, 2)}
                                :
                                {start.slice(2)}
                                {' '}
                                -
                                {' '}
                                {end.slice(0, 2)}
                                :
                                {end.slice(2)}
                              </TableCell>
                              {!moneySectionHidden && (
                                <TableCell>
                                  $
                                  {cost / 100}
                                  {' '}
                                  / hr
                                </TableCell>
                              )}
                            </tr>
                          ) : (
                            <>
                              <tr>
                                <TableCell
                                  style={{ fontWeight: '600' }}
                                >
                                  {array[idx - 1]?.day === day ? '' : day.charAt(0) + day.slice(1).toLowerCase()}
                                </TableCell>
                              </tr>
                              <tr>
                                <TableCell>
                                  {start.slice(0, 2)}
                                  :
                                  {start.slice(2)}
                                  {' '}
                                  -
                                  {' '}
                                  {end.slice(0, 2)}
                                  :
                                  {end.slice(2)}
                                </TableCell>
                                <TableCell>
                                  $
                                  {cost / 100}
                                  {' '}
                                  / hr
                                </TableCell>
                              </tr>
                            </>
                          )
                        )))}
                      </OverviewTable>
                    </TableWrapper>
                    {!moneySectionHidden && (
                      <>
                        <Title onClick={() => setTab(TabsEnum.HOURS_AND_FEES)}>
                          Other Fees
                          <StyledPenIcon />
                        </Title>
                        <TableWrapper>
                          <OverviewTable $minWidth="250px">
                            <tr>
                              <TableCell width={isTabletOrMobile ? undefined : '40%'}>Maximum Daily Fee</TableCell>
                              <TableCell>
                                $
                                {garageOptions ? garageOptions.maximumDailyFee / 100 : '-'}
                              </TableCell>
                            </tr>
                            <tr>
                              <TableCell>Event Parking Fee</TableCell>
                              <TableCell>
                                $
                                {garageOptions ? garageOptions.eventParkingFee / 100 : '-'}
                              </TableCell>
                            </tr>
                          </OverviewTable>
                        </TableWrapper>
                      </>
                    )}
                  </>)
                )}
              </>
            )}
            {tab === TabsEnum.HOURS_AND_FEES && !hoursAndFeesHidden && (
              hoursPoliciesLoading || garageOptionsLoading
                ? <LoadingIndicator />
                : <HoursAndFees initialPolicies={hoursPoliciesData!} initialOptions={garageOptions!} />
            )}
            {tab === TabsEnum.VALIDATION && !validationSectionHidden && (
              <Validation />
            )}
            {tab === TabsEnum.PERMIT && !permitSectionHidden && (
              <Permit />
            )}
          </Content>
        </ContentWrapper>
      </StyledCard>
    </Page>
  );
};

const MainTitle = styled.div`
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;

  @media ${tabletOrMobileQuery} {
    margin-bottom: 10px;
  }
`;

const StyledCard = styled(Card)`
  padding: 20px;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 160px calc(100% - 160px);

  @media ${tabletOrMobileQuery} {
    grid-template-columns: 100%;
  }
`;

const Content = styled.div`
  padding-left: 20px;
  border-left: 1px solid ${vars.palette.border};

  @media ${tabletOrMobileQuery} {
    border-left: 0;
    padding-left: 0;
    margin-top: 20px;
  }
`;

const Title = styled.button`
  margin-bottom: 20px;
  padding: 0;
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 600;
  font-family: inherit;
  cursor: pointer;

  &:hover {
    color: ${vars.palette.primary};
  }

  &:focus {
    outline: none;
    color: ${vars.palette.primary};
  }
`;

const TableWrapper = styled.div`
  overflow-x: auto;
`;

const OverviewTable = styled.table<{ $minWidth: string; }>`
  width: 100%;
  min-width: ${({ $minWidth }) => $minWidth};
  margin-bottom: 20px;
  border-spacing: 10px;
`;

const TableCell = styled.td`
  padding: 5px;
`;

const StyledPenIcon = styled(PenIcon)`
  margin-left: 20px;
`;

export default flow( 
  withGarage,
  withRole('parkingPolicy'),
  withPreloadRole,
  withPreloadGarage,
  withAuth(),
  withLoading(),
)(ParkingPolicy);
