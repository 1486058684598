import React from 'react';

import TableHeadLib, { TableHeadProps } from '@zero5/ui/lib/TableHead';

import useDateModule from '@/utils/date/useDateModule';

const TableHead: React.FC<TableHeadProps> = (props) => {
  const { z5DateModule } = useDateModule();

  return (
    <TableHeadLib
      timezone={z5DateModule.getTimezone()}
      {...props}
    />
  );
};

export default TableHead;
