import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getEntryActivityById } from '@zero5/activity-api';

import { mapEntryActivityResponse } from '@/api/mappings/activity';


type Response = ReturnType<typeof mapEntryActivityResponse>;

export const queryKey: QueryKey = 'activitiesQuery';

type Key = [typeof queryKey, string];

const useGetEntryActivityInfo = (
  activityId: string,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    [queryKey, activityId],
    async () => {
      const response = await getEntryActivityById({ activityId });
      return mapEntryActivityResponse(response);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useGetEntryActivityInfo;
