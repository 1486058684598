import { createReducerFunction, ImmerReducer } from 'immer-reducer';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';

import { Activity } from '@/controlCenter/api/activity/models';

import { ActivityPair, CallData, ChimeMeetingCredentials, IncomingCall } from '../../api/call/models';

export interface CurrentCall {
  meetingCredentials: ChimeMeetingCredentials;
  incomingCall: IncomingCall;
}

interface CallState {
  queue: Array<IncomingCall>;
  currentCall: CurrentCall | null;
  connectionId: string | null;
  acceptingCallId: string | null;
  isAccepting: boolean;
  isEnding: boolean;
}

const initialState: CallState = {
  queue: [],
  currentCall: null,
  connectionId: null,
  acceptingCallId: null,
  isAccepting: false,
  isEnding: false,
};

export class CallReducer extends ImmerReducer<CallState> {
  addCalls(payload: Array<IncomingCall> | IncomingCall) {
    const payloadArray = Array.isArray(payload) ? payload : [payload];

    const uniqQueue = uniqWith([...this.state.queue, ...payloadArray], isEqual);

    this.draftState.queue = uniqQueue;
  }

  removeCall(payload: string) {
    const removedQueue = this.state.queue.filter(
      (value) => value.requestId !== payload,
    );

    this.draftState.queue = removedQueue;
  }

  replaceQueue(payload: Array<IncomingCall> | IncomingCall) {
    const payloadArray = Array.isArray(payload) ? payload : [payload];

    this.draftState.queue = payloadArray;
  }

  setCurrentCall(payload: CurrentCall | null) {
    this.draftState.currentCall = payload;
  }

  updateCurrentCallActivity(payload: ActivityPair) {
    this.draftState.currentCall!.incomingCall.callData!.payload.activity = payload;
  }

  updateCurrentCallSuggestedActivities(payload: Array<Activity>) {
    this.draftState.currentCall!.incomingCall.callData = {
      type: 'EntryNotFound',
      payload: {
        activity: this.draftState.currentCall!.incomingCall.callData!.payload.activity,
        suggestedEntryActivities: payload,
        defaultFee: 0,
      },
    };
  }

  updateCurrentCallCallData(payload: CallData) {
    this.draftState.currentCall!.incomingCall.callData = payload;
  }

  setConnectionId(payload: string) {
    this.draftState.connectionId = payload;
  }

  setIsAccepting(payload: boolean) {
    this.draftState.isAccepting = payload;
  }

  setIsEnding(payload: boolean) {
    this.draftState.isEnding = payload;
  }

  setAcceptingCallId(payload: string | null) {
    this.draftState.acceptingCallId = payload;
  }
}

export default createReducerFunction(CallReducer, initialState);
