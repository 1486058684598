import React from 'react';
import styled from 'styled-components';

import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';
import vars from '@zero5/ui/lib/styles/vars';

interface Props {
  children: number;
}

const Price: React.FC<Props> = ({ children }) => {
  return (
    <Color color={children < 0 ? vars.palette.danger : 'inherit'}>
      {formatPrice(children)}
    </Color>
  );
};

const Color = styled.span`
  color: ${props => props.color};
`;

export default Price;
