import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { LoadingIndicator } from '@zero5/ui';
import toast from '@zero5/ui/lib/utils/toast';

import usefindActivitiesByLicensePlateMutation from '@/api/activity/useActivitiesByLP';

import { regexEmail } from '@/utils/regex';

import ValidationInputContent from './ValidationInputContent';
import ValidationInputResultContent from './ValidationInputResultContent';

type RequestStatus =
  | undefined
  | 'PENDING'
  | 'SUCCESS'
  | 'ERROR'
  | 'VALIDATE_SUCCESS'
  | 'VALIDATE_ERROR';
export type VehicleInfo =
  | undefined
  | {
    photo: string;
    lp: string;
    make?: string;
    type?: string;
    color?: string;
    entryTime: number;
    image: string;
  };

export type ValidationType = null | 'Valet' | 'Parking';

type ValidationContentProps = {
  validationType: ValidationType;
  handleGoBackClick: () => void;
};

const ValidationContent: React.FC<ValidationContentProps> = ({
  validationType,
  handleGoBackClick,
}) => {
  const [status, setStatus] = useState<RequestStatus>();
  const [vehicle, setVehicle] = useState<VehicleInfo>();
  const [searchInput, setSearchInput] = useState<string>('');
  const [hours, setHours] = useState<null | number>(null);
  const [showEmailError, setShowEmailError] = useState<boolean>(false);

  const {
    mutateAsync: findActivityByLP,
    isLoading: isFindActivityLoading,
    data: activity,
  } = usefindActivitiesByLicensePlateMutation();

  useEffect(() => {
    if (activity) {
      setVehicle({
        photo: activity.frontImg || '-',
        lp: activity.mainLp || '-',
        entryTime: activity.eventTime,
        image: activity.rearImg || '-',
      });
      setStatus('SUCCESS');
    }
    if (activity === null) {
      setStatus('ERROR');
      toast('error', `there is no vehicle by licence plate ${searchInput}`);
    }
  }, [activity]);

  const handleEnterClick = () => {
    setStatus('PENDING');
    if (!searchInput.includes('@')) {
      setShowEmailError(false);
      findActivityByLP({
        licensePlate: searchInput,
      });
    }
    if (searchInput.includes('@')) {
      const isValidEmail = isEmailValidHandler(searchInput);
      if (isValidEmail) {
        setStatus('SUCCESS');
      }
    }
  };

  const isEmailValidHandler = (testEmail: string) => {
    const regexp = new RegExp(regexEmail);
    const isValid = regexp.test(testEmail);
    setShowEmailError(!isValid);
    return isValid;
  };

  const handleSearchAgainClick = () => setStatus(undefined);

  const setSearchInputHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchInput(event.target.value);
  };

  const setHoursHandler = (result: number) => {
    setHours(result);
  };

  if (isFindActivityLoading) {
    return (<LoadingIndicator />);
  }

  if (status === 'SUCCESS') {
    return (
      <StyledValidationContent>
        <ValidationInputResultContent
          hours={hours}
          searchInput={searchInput}
          vehicle={vehicle}
          handleSearchAgainClick={handleSearchAgainClick}
          setHoursHandler={setHoursHandler}
          handleGoBackClick={handleGoBackClick}
        />
      </StyledValidationContent>
    );
  }

  return (
    <StyledValidationContent>
      <ValidationInputContent
        validationType={validationType}
        searchInput={searchInput}
        handleEnterClick={handleEnterClick}
        setSearchInputHandler={setSearchInputHandler}
        emailError={showEmailError}
      />
    </StyledValidationContent>
  );
};

const StyledValidationContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export default ValidationContent;
