import { useMutation, UseMutationOptions } from 'react-query';

import { updateTenantCompany } from '@zero5/user-api';

type Response = unknown;

const useUpdateTenantCompanyMutation = (
  options?: UseMutationOptions<Response, Error, {
    companyId: number;
    name?: string;
    typeName?: string;
    email?: string;
  }, string>,
) => {
  return useMutation(
    'updateTenant',
    async ({ companyId, ...params }) => {
      const response = await updateTenantCompany(companyId, params);

      return response;
    },
    {
      ...options,
    },
  );
};

export default useUpdateTenantCompanyMutation;
