import { useMutation, UseMutationOptions } from 'react-query';

import {
  findlastUnusedEntryActivity,
  FindEntryActivitiesByLicensePlateArgs,
  ActivityData, 
} from '@zero5/activity-api';

import { queryKey as getActivitiesQuery } from '@/api/queries/useActivitiesQuery';

type Response = ActivityData | null;

export const queryKey = [getActivitiesQuery, 'findActivitiesByLicensePlateMutation'];

const usefindActivitiesByLicensePlateMutation = (
  options?: UseMutationOptions<Response, Error, FindEntryActivitiesByLicensePlateArgs, [typeof queryKey]>,
) => {
  return useMutation(
    [queryKey],
    async (params: FindEntryActivitiesByLicensePlateArgs) => {
      const response = await findlastUnusedEntryActivity(params);
      return response.entryActivity;
    },
    {
      ...options,
    },
  );
};

export default usefindActivitiesByLicensePlateMutation;
