import { LinkType } from '@zero5/ui/lib/Navigation';

import { privateRouteList as controlCenterPrivateRouteList } from '@controlCenter/utils/routes/routeList';

import PolicyIcon from '@/assets/icons/policy.inline.svg';
import TuneIcon from '@/assets/icons/tune_black.inline.svg';
import UsersAltPlusIcon from '@/assets/icons/users_alt_plus.inline.svg';
import UsersPlusIcon from '@/assets/icons/users_plus.inline.svg';
import UserSquareIcon from '@/assets/icons/user_square.inline.svg';
import CalendarIcon from '@/assets/icons/calendar.inline.svg';
import ValidationIcon from '@/assets/icons/validation.inline.svg';
import PermitIcon from '@/assets/icons/permit.inline.svg';
import MapIcon from '@/assets/icons/map.inline.svg';
import RevenueIcon from '@/assets/icons/revenue.inline.svg';
import HomeIcon from '@/assets/icons/home.inline.svg';

type RouteType = {
  path: string;
};

export const privateRouteList: Array<LinkType & RouteType & {
  widgetId: string;
}> = [
  {
    title: 'Dashboard',
    Icon: HomeIcon,
    path: '/',
    widgetId: 'home',
  },
  {
    title: 'Revenue',
    Icon: RevenueIcon,
    path: '/revenue',
    categoryName: 'Data Analytics',
    widgetId: 'revenue',
  },
  {
    title: 'Guidance Map',
    Icon: MapIcon,
    path: '/guidance-map',
    categoryName: 'Data Analytics',
    widgetId: 'guidanceMap',
  },
  {
    title: 'Permit',
    Icon: PermitIcon,
    path: '/permit',
    categoryName: 'Management',
    widgetId: 'permit',
  },
  {
    title: 'Validation',
    Icon: ValidationIcon,
    path: '/validation',
    categoryName: 'Management',
    nestedLink: '',
    widgetId: 'validation',
  },
  {
    title: 'Events',
    Icon: CalendarIcon,
    path: '/events',
    categoryName: 'Management',
    widgetId: 'event',
  },
  {
    title: 'Parking Policy',
    Icon: PolicyIcon,
    path: '/parking-policy',
    categoryName: 'Management',
    widgetId: 'parkingPolicy',
  },
  {
    title: 'Parking Settings',
    Icon: TuneIcon,
    path: '/parking-settings',
    categoryName: 'Management',
    widgetId: 'parkingSettings',
  },
  ...controlCenterPrivateRouteList,
  {
    title: 'My Account',
    Icon: UserSquareIcon,
    path: '/my-account',
    categoryName: 'Account',
    widgetId: 'myAccount',
  },
  {
    title: 'Manage Users',
    Icon: UsersPlusIcon,
    path: '/manage-users',
    categoryName: 'Account',
    widgetId: 'user',
  },
  {
    title: 'Manage Tenants',
    Icon: UsersAltPlusIcon,
    path: '/manage-tenants',
    categoryName: 'Account',
    widgetId: 'tenant',
  },
];
