import React from 'react';
import styled from 'styled-components';

interface Props {
  service: 'TRANSIENT' | 'EVENT' | 'PERMIT' | 'VALIDATION' | 'DISPUTE' | 'DISPUTE_WON' | 'REFUND';
}

const mapServiceToView = {
  TRANSIENT: {
    label: 'Transient',
    color: '#CBE2D1',
  },
  EVENT: {
    label: 'Event',
    color: '#D0D0E8',
  },
  PERMIT: {
    label: 'Permit',
    color: '#F6D9CB',
  },
  VALIDATION: {
    label: 'Validation',
    color: '#F6DFE8',
  },
  DISPUTE: {
    label: 'Dispute',
    color: '#F7EEC9',
  },
  DISPUTE_WON: {
    label: 'Dispute Won',
    // TODO: The color is now the same as DISPUTE. Maybe we should change it
    color: '#F7EEC9',
  },
  REFUND: {
    label: 'Refund',
    // TODO: The color is now the same as DISPUTE. Maybe we should change it
    color: '#F7EEC9',
  },
};

const Service: React.FC<Props> = ({ service }) => {
  return (
    <Wrapper $color={mapServiceToView[service].color}>
      {mapServiceToView[service].label}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $color: string; }>`
  display: inline-block;
  padding: 4px 7px;
  background-color: ${({ $color }) => $color};
  white-space: nowrap;
`;

export default Service;
