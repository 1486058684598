/* eslint-disable max-len */
import { useQuery, UseQueryOptions } from 'react-query';

import { getCompanyFeatures } from '@zero5/user-api';

import { CompanyFeatures } from '@/api/models/companyFeatures';

import { handleError } from '@/utils/handleError';

import { companyFeatureQueryKeys } from './queryKeys';

type Response = CompanyFeatures;
type Key = ReturnType<typeof companyFeatureQueryKeys.companyFeatures>;

const useCompanyFeaturesQuery = (
  companyId: number,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery(
    companyFeatureQueryKeys.companyFeatures(companyId),
    async () => {
      const response = await getCompanyFeatures(companyId);

      return response.features;
    },
    {
      refetchOnWindowFocus: false,
      onError: handleError,
      staleTime: Infinity,
      ...options,
    },
  );
};

export default useCompanyFeaturesQuery;
