export const revenueQueryKeys = {
  base: ['revenue'] as const,
  revenueTrend: ({
    from,
    until,
    aggregationPeriod,
  }: {
    from: number;
    until: number;
    aggregationPeriod?: 'daily' | 'weekly' | 'monthly';
  }) => [...revenueQueryKeys.base, 'trend', from, until, aggregationPeriod] as const,
  revenueDetails: () => [...revenueQueryKeys.base, 'details'] as const,
};
