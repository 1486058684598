import React from 'react';
import styled, { css } from 'styled-components';

import { Row } from '@zero5/ui';

interface Props<T> {
  data: Array<Array<T>>;
  groupKey: keyof T;
  rowsMap: RowsMap<T>;
}

export type RowsMap<T> = Array<{
  title: string;
  key: (item: T) => string;
  data: (item: T) => React.ReactNode;
  HeadCellProps?: React.TdHTMLAttributes<HTMLTableCellElement>;
}>;

const GroupedTable = <T extends object>({
  data,
  groupKey,
  rowsMap,
}: Props<T>) => {
  return (
    <Table cellSpacing="0">
      <thead>
        <tr>
          {rowsMap.map(({ title, HeadCellProps }) => (
            <StyledHeadCell {...HeadCellProps}>
              {title}
            </StyledHeadCell>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((groupedData, id) => groupedData.map((item, idx) => {      
          return (
          <tr>
            {rowsMap.map(({ key, data: getData }) => {
              if (key(item) === groupKey && idx !== 0) {
                return null;
              }

              return (
                <StyledCell rowSpan={key(item) === groupKey ? groupedData.length : 1} rowColor={id}>
                  <Row alignItems="center" justifyContent="center">
                    {getData(item)}
                  </Row>
                </StyledCell>
              );
            })}
          </tr>
          );
        }))}
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto;
`;

const cellStyles = css`
  border: 1px solid #E7E5E5;


  &:first-child {
    border-left: 0;
  }

  &:last-child {
    border-right: 0;
  }
`;

const StyledHeadCell = styled.th`
  ${cellStyles};
  padding: 14px 10px;
  border-top: none;
  text-align: center;
  font-weight: normal;
  min-width: 150px;
`;

const StyledCell = styled.td<{ rowColor: number; }>`
  ${cellStyles}
  padding: 5px 10px;
  ${({ rowColor }) => rowColor % 2 === 0 && 'background-color:#F2F2F2 !important;'} 
`;

export default GroupedTable;
