/* eslint-disable max-len */
import { useQuery, UseQueryOptions } from 'react-query';

import { listGarageTenants } from '@zero5/user-api';

import { mapTenants } from './mappings';
import { tenantQueryKeys } from './queryKeys';

type Response = ReturnType<typeof mapTenants>;
type Key = ReturnType<typeof tenantQueryKeys.garageTenants>;

const useGarageTenantsQuery = (
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    tenantQueryKeys.garageTenants(),
    async () => {
      const response = await listGarageTenants();

      return mapTenants(response);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useGarageTenantsQuery;
