/* eslint-disable @typescript-eslint/semi */
import { createActionCreators } from 'immer-reducer';

import {
  getDevices as getBackDevices,
  sendDeviceAction, GateStateType,
  sendAllGatesAction, DeviceActionFrontType, DeviceSettingType,
} from '@zero5/garage-api';
import { DeviceData } from '@zero5/garage-api';

import {
  ActionDeviceRequest, Device as FrontDevice, setWayOfGate, DeviceRole,
} from '@/api/models/devices';

import { DeviceReducer } from '@/store/reducers/device';


import { stringToBoolean } from '@/utils/stringToBoolean';

import { RequestState } from '../reducers/common';

import { AsyncAction } from './common';

export const deviceActions = createActionCreators(DeviceReducer);

export type DeviceActions =
  | ReturnType<typeof deviceActions.changeStateOfDevice>
  | ReturnType<typeof deviceActions.changeStateOfDevices>
  | ReturnType<typeof deviceActions.changeStateOfGateLock>
  | ReturnType<typeof deviceActions.changeStateOpenAllGates>
  | ReturnType<typeof deviceActions.setDeviceList>
  | ReturnType<typeof deviceActions.setErrorInDevice>
  | ReturnType<typeof deviceActions.setDeviceData>
  | ReturnType<typeof deviceActions.openAllGates>
  | ReturnType<typeof deviceActions.setGateControlInDeviceList>
  | ReturnType<typeof deviceActions.setDownLockInDeviceList>


// eslint-disable-next-line max-len
export const changeDeviceAction = (request: ActionDeviceRequest): AsyncAction => async (dispatch) => {
  try {
    let action = DeviceActionFrontType.GATE_DEFAULT;
    if (request.isOpen !== undefined) {
      dispatch(deviceActions.changeStateOfDevice(RequestState.LOADING));
      action = request.isOpen ? DeviceActionFrontType.GATE_UP : DeviceActionFrontType.GATE_DOWN;
    }
    if (request.isLocked !== undefined) {
      dispatch(deviceActions.changeStateOfGateLock(RequestState.LOADING));
      action = request.isLocked ? DeviceActionFrontType.GATE_UPLOCK : DeviceActionFrontType.GATE_UNLOCK;
    }

    let { deviceId } = request;
    if (deviceId.includes('gate')) {
      deviceId = deviceId.replace('gate', 'lpr');
    }
    await sendDeviceAction({ deviceId, action }).then((res) => res);
    dispatch(deviceActions.changeStateOfDevice(RequestState.LOADED));
    dispatch(deviceActions.changeStateOfGateLock(RequestState.LOADED));
  } catch {
    dispatch(deviceActions.changeStateOfGateLock(RequestState.ERROR));
    dispatch(deviceActions.changeStateOfDevice(RequestState.ERROR));
  }
};

export const openAllGatesAction = (
  action: DeviceActionFrontType,
): AsyncAction => async (dispatch) => {
  try {
    dispatch(deviceActions.changeStateOpenAllGates(RequestState.LOADING));
    dispatch(deviceActions.openAllGates());
    await sendAllGatesAction({ action });
    dispatch(deviceActions.changeStateOpenAllGates(RequestState.LOADED));
  } catch {
    dispatch(deviceActions.changeStateOpenAllGates(RequestState.ERROR));
  }
};

export const getDevices = (): AsyncAction => async (dispatch) => {
  try {
    dispatch(deviceActions.changeStateOfDevices(RequestState.LOADING));
    // const sortedBackDev = await apiInstance.get('/api/devices');
    const response = await getBackDevices();
    const backDev = response.devices;
    // get all devices which includes lpr_camera,gate,aps
    const sortedBackDev = new Array<DeviceData>();
    backDev.map((dev: DeviceData) => {
      const isInclude = [DeviceRole.CAMERA, DeviceRole.aps, DeviceRole.GATE]
        .some((role) => role === dev.role);
      if (isInclude) {
        sortedBackDev.push(dev);
      }
      return null;
    });
    const newResDevice = new Array<FrontDevice[]>();
    // get array of uniq layouts
    const uniqueLayouts = Array.from(new Set(sortedBackDev.map((item: DeviceData) => {
      const items = item.group!.split('-');
      return items[items.length - 2];
    })));

    uniqueLayouts.map((layout) => {
      // get all devices in layout
      const allDevicesInLayout = sortedBackDev.filter((data: DeviceData) => {
        return data.group!.split(`${data.garageId}-${layout}`).length === 2;
      });
      // get all groups this devices
      const groupList = Array.from(new Set(allDevicesInLayout.map((item: DeviceData) => item.group)));
      // get all devices for layout
      const result = new Array<FrontDevice>();
      groupList.map((groupName) => {
        const groupDevices = sortedBackDev.filter((data: DeviceData) => data.group === groupName);
        const lprDevice: DeviceData[] = backDev.filter((data: DeviceData) => {
          return data.group === groupName && data.role === DeviceRole.lpr
        });
                
        const downLock = lprDevice.length > 0 
          ? lprDevice[0].settings.find((item) => (item.key === DeviceSettingType.OpenGateByLPR && item.value))
          : null;
          
        const resDevice: FrontDevice = {
          layout: `Layout ${layout.toLocaleUpperCase()}`,
          deviceId: groupDevices[0].deviceId,
          gateLPR: 'empty',
          aps: 'empty',
          mLPR: 'empty',
          downLock: downLock ? stringToBoolean(downLock.value || '') : false,
          entryExit: '',
          gateControl: false,
          gateLock: false,
        };
        groupDevices.map((element: DeviceData) => {
          const entrySplit = element.group!.split(`${element.garageId}-${layout}`);
          const entry = entrySplit[entrySplit.length - 1];
          const typeOfEntry = entry.split('_')[1];
          resDevice.entryExit = `${setWayOfGate(entry)} ${typeOfEntry.toLocaleUpperCase()}`;

          if (element.role === DeviceRole.GATE) {
            if (element.gateState) {
              const gateControl = element.gateState.gateState;
              const gateLock = element.gateState.isLocked;
              resDevice.gateLPR = element.status!;
              const tableGateControl = gateControl === GateStateType.DOWN;
              resDevice.gateControl = tableGateControl;
              resDevice.gateLock = gateLock;
              if (gateControl === GateStateType.ERROR) {
                resDevice.gateLPR = 'error';
              }
            }
            if (!element.gateState) {
              resDevice.gateLPR = 'error';
            }
            const { deviceId } = element;

            resDevice.deviceId = deviceId;
          }
          if (element.role === DeviceRole.CAMERA) {
            resDevice!.mLPR = element.status!;
          }
          if (element.role === DeviceRole.aps) {
            resDevice!.aps = element.status!;
          }
          return null;
        });
        result.push(resDevice);
        return null;
      });
      newResDevice.push(result);
      return null;
    });
    dispatch(deviceActions.setDeviceList(newResDevice));
    dispatch(deviceActions.changeStateOfDevices(RequestState.LOADED));
  } catch {
    dispatch(deviceActions.changeStateOfDevices(RequestState.ERROR));
  }
};
