export const TABLET_SIZE = 1200;
export const SMALL_TABLET_SIZE = 1040;
export const MOBILE_SIZE = 760;


export const TABLET_SIZE_PX = `${TABLET_SIZE}px`;
export const SMALL_TABLET_SIZE_PX = `${SMALL_TABLET_SIZE}px`;
export const MOBILE_SIZE_PX = `${MOBILE_SIZE}px`;

export const TABLET_ONLY_MEDIA_QUERY = `(max-width: ${TABLET_SIZE_PX}) and (min-width: ${SMALL_TABLET_SIZE + 1}px)`;
export const TABLET_MEDIA_QUERY = `(max-width: ${TABLET_SIZE_PX})`;
export const SMALL_TABLET_ONLY_MEDIA_QUERY =
  `(max-width: ${SMALL_TABLET_SIZE_PX}) and (min-width: ${MOBILE_SIZE + 1}px)`;
export const SMALL_TABLET_MEDIA_QUERY = `(max-width: ${SMALL_TABLET_SIZE_PX})`;
export const MOBILE_MEDIA_QUERY = `(max-width: ${MOBILE_SIZE_PX})`;