/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getNearestEvent } from '@zero5/garage-api';

import { mapNearestEvent } from '../mappings/event';

type Response = string;
export const queryKey: QueryKey = 'getNearestEvent';

const useGetNearestEventQuery = (
  options?: UseQueryOptions<Response, unknown, Response, typeof queryKey>,
) => {
  return useQuery<Response, unknown, Response, typeof queryKey>(
    queryKey,
    async () => {
      const response = await getNearestEvent();

      return mapNearestEvent(response.event);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useGetNearestEventQuery;
