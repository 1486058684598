import React from 'react';
import styled from 'styled-components';
import { ChartDataset } from 'chart.js';

import {
  BigCategoryCard,
  LineChart,
  Row,
  Select,
} from '@zero5/ui';
import { DateRange } from '@zero5/ui/lib/Chart';

import { RevenueTrendAggregationPeriod } from '@/api/revenue/models';

import RangePicker from '@/components/common/RangePicker';

import useDateModule from '@/utils/date/useDateModule';


export type Value = number;

export type DataItem = {
  values: Array<Value>;
  datasetSettings?: Partial<ChartDataset<'line'>>;
};

type Data = Array<DataItem> | Array<Value>;

interface Props {
  dateRange: DateRange;
  onDateRangeChange: (dateRange: DateRange) => void;
  data: Data;
  labels: Array<string>;
  priceFormat?: boolean;
  isLoading?: boolean;
  revenueTrendChartAggregation: RevenueTrendAggregationPeriod;
  setRevenueTrendChartAggregation:  React.Dispatch<React.SetStateAction<RevenueTrendAggregationPeriod>>;

}

const isAggregationAvailable = (aggregation: RevenueTrendAggregationPeriod, range: DateRange) => {
  const monthlyInMilliseconds = 91 * 24 * 60 * 60 * 1000;
  const weeklyInMilliseconds = 14 * 24 * 60 * 60 * 1000;

  const rangeInMilliseconds = range.end.getTime() - range.start.getTime();

  switch (aggregation) {
    case 'daily': 
      return true;
    case 'weekly':
      return rangeInMilliseconds >= weeklyInMilliseconds;
    case 'monthly':
      return rangeInMilliseconds >= monthlyInMilliseconds;
  }
};

const PermitUsageTrendChart: React.FC<Props> = ({
  dateRange,
  onDateRangeChange,
  data,
  labels,
  isLoading,
  revenueTrendChartAggregation,
  setRevenueTrendChartAggregation,
}) => {
  const {
    z5DateModule,
  } = useDateModule();

  const revenueTrendChartAggregatedOptions = React.useMemo(() => {
    const options: Array<{
      label: string;
      value: RevenueTrendAggregationPeriod;
    }> = [];
    
    if (isAggregationAvailable('daily', dateRange)) {
      options.push({
        label: 'Daily',
        value: 'daily',
      });
    }

    if (isAggregationAvailable('weekly', dateRange)) {
      options.push({
        label: 'Weekly',
        value: 'weekly',
      });
    }

    if (isAggregationAvailable('monthly', dateRange)) {
      options.push({
        label: 'Monthly',
        value: 'monthly',
      });
    }

    return options;
  }, [dateRange]);

  const trendDateRangeChangeHandler = React.useCallback(
    (selectedRange: DateRange, aggregation: RevenueTrendAggregationPeriod) => {
      const endOfToday = new Date(z5DateModule.fromUtc(Date.now()).dayEnd().getTimestamp());

      switch (aggregation) {
        case 'weekly':
          if (isAggregationAvailable('weekly', selectedRange)) {
            const newStart = new Date(z5DateModule.fromUtc(selectedRange.start.getTime()).weekStart().getTimestamp());
            const newEnd = new Date(
              Math.min(
                z5DateModule.fromUtc(selectedRange.end.getTime()).weekEnd().getTimestamp(),
                endOfToday.getTime(),
              ),
            );
    
            // eslint-disable-next-line no-param-reassign
            selectedRange = {
              start: newStart,
              end: newEnd,
            };
          } else {
            setRevenueTrendChartAggregation('daily');
          }
          break;
        case 'monthly':
          if (isAggregationAvailable('monthly', selectedRange)) {
            const newStart = new Date(z5DateModule.fromUtc(selectedRange.start.getTime()).monthStart().getTimestamp());
            const newEnd = new Date(
              Math.min(
                z5DateModule.fromUtc(selectedRange.end.getTime()).monthEnd().getTimestamp(),
                endOfToday.getTime(),
              ),
            );

            // eslint-disable-next-line no-param-reassign
            selectedRange = {
              start: newStart,
              end: newEnd,
            };
          } else {
            setRevenueTrendChartAggregation('daily');
          }
      }

      onDateRangeChange(selectedRange);
    },
    [onDateRangeChange, z5DateModule],
  );

  return (
    <BigCategoryCard
      title="Permit Usage Trend"
      gap="10px"
      headerContent={
        <Row wrap="wrap" gap="10px">
          <RangePicker
            dateRange={dateRange}
            onChange={(range) => trendDateRangeChangeHandler(range, revenueTrendChartAggregation)}
            hideShortcutsSize="1450px"
          />
          <StyledSelect
            options={revenueTrendChartAggregatedOptions}
            value={revenueTrendChartAggregatedOptions.find(({ value }) => value === revenueTrendChartAggregation)}
            onChange={(option) => {
              trendDateRangeChangeHandler(dateRange, option!.value);
              setRevenueTrendChartAggregation(option!.value);
            }}
          />
        </Row>
      }
    >
      <LineChart
        data={data}
        labels={labels}
        isLoading={isLoading}
        priceFormat
      />
    </BigCategoryCard>
  );
};

const StyledSelect = styled(Select)`
  min-width: 115px;
` as typeof Select;

export default PermitUsageTrendChart;