import React from 'react';
import {
  Badge as MUIBadge,
  BadgeProps,
} from '@material-ui/core';

const Badge: React.FC<BadgeProps> = ({
  children,
  ...props
}) => {
  return (
    <MUIBadge {...props}>
      {children}
    </MUIBadge>
  );
};

export default Badge;
