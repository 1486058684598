import React from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Button,
  Checkbox, Input, InputPrice, Row, Select,
  Modal, ModalProps,
} from '@zero5/ui';
import schemas from '@zero5/ui/lib/utils/validation/schemas';
import {  costToCents } from '@zero5/ui/lib/utils/formatters/formatPrice';

import useCreateValidationTypeMutation from '@/api/validationType/createValidationTypeMutation';
import { ValidationType } from '@/api/models/ValidationType';
import useUpdateValidationTypeMutation from '@/api/validationType/updateValidationTypeMutation';



import Title from './common/Title';
import CheckboxButtonsList from './common/CheckboxButtonsList';
import eligibleTenantTypes from './utils/eligibleTenantTypes';
import pricingTypes from './utils/pricingTypes';
import maxValidationHours from './utils/maxValidationHours';


interface Props extends Omit<ModalProps, 'children'> {
  data?: ValidationType;
  title: string;
}

const ValidationPolicyModal: React.FC<Props> = ({
  onClose, title, data, ...props
}) => {
  return (
    <StyledModal
      title={title}
      fullScreenSize="580px"
      onClose={onClose}
      {...props}
    >
      <Content onClose={onClose} data={data} />
    </StyledModal>
  );
};

type ContentProps = Pick<Props, 'onClose' | 'data'>;

interface FormikInitialState{
  validationTypeName: string;
  pricingType: string | undefined;
  maxValidationHours: string | undefined;
  price: number | undefined;
  selectedEligibleTenantTypes: Array<string> | undefined;
}

const Content: React.FC<ContentProps> = ({ onClose, data }) => {
  const { mutateAsync: createValidationType, isLoading: isCreateLoading }  = useCreateValidationTypeMutation();
  const { mutateAsync: updateValidationType, isLoading: isUpdateLoading }  = useUpdateValidationTypeMutation();


  const { values, setFieldValue, handleSubmit, errors, touched, handleChange  } = useFormik<FormikInitialState>({
    initialValues: {
      validationTypeName: data?.name || '',
      pricingType: data?.pricingType,
      maxValidationHours: String(data?.hours),
      price: data?.price,
      selectedEligibleTenantTypes: data?.eligibleTenantTypes,
    },
    validationSchema: Yup.object().shape({
      validationTypeName: schemas.nameSchema.label('Validation Name'),
      pricingType:  Yup.string().required(),
      maxValidationHours: Yup.string().required(),
      price: Yup.number().min(0, 'Price can not be less than 0').required().label('price'),
    }),
    onSubmit: async (validationType: FormikInitialState) => {      
      const { 
        validationTypeName,
        pricingType, 
        price, 
        maxValidationHours: hours,
        selectedEligibleTenantTypes } = validationType;

      const convertedPrice = costToCents(price);
      const maxValidationTimeMin = Number(hours!) * 60;
      if (data){
        await updateValidationType({
          id: data.id,
          validationTypeName,
          pricingType: pricingType!,
          price: convertedPrice,
          maxValidationTimeMin,
          eligibleTenantTypes: selectedEligibleTenantTypes || [],
        });
      } else {
        await createValidationType({
          validationTypeName,
          pricingType: pricingType!,
          price: convertedPrice,
          maxValidationTimeMin,
          eligibleTenantTypes: selectedEligibleTenantTypes || [],
        });
      }
      onClose();
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Title>Validation Types and Fees</Title>
      <TypesAndFeesWrapper>
        <Input
          label="Validation Name"
          name="validationTypeName"
          onChange={handleChange}
          value={values.validationTypeName}
          error={Boolean(errors.validationTypeName && touched.validationTypeName)}
          helperText={errors.validationTypeName}
        />
        <Select
          label="Pricing Type"
          options={pricingTypes}
          value={pricingTypes.find(option=> option.value === values.pricingType)}
          onChange={(selected) => setFieldValue('pricingType', selected?.value)}
          error={Boolean(errors.pricingType && touched.pricingType)}
          helperText={errors.pricingType}
        />
        <InputPrice
          label="Price"
          onChangeValue={(value, sourceInfo) => {
            if (sourceInfo.source === 'prop') return;

            setFieldValue('price', value);
          }}
          value={values.price}
          error={Boolean(errors.price && touched.price)}
          helperText={errors.price}
        />
        <Select
          label="Max Validation Hours"
          options={maxValidationHours}
          onChange={(selected) => setFieldValue('maxValidationHours', selected?.value)}
          value={maxValidationHours.find(option=> option.value === values.maxValidationHours)}
          error={Boolean(errors.maxValidationHours && touched.maxValidationHours)}
          helperText={errors.maxValidationHours}
        />
      </TypesAndFeesWrapper>
      <TenantTypesTitleWrapper>
        <TenantTypesTitle>Eligible Tenant Types</TenantTypesTitle>
        <CheckboxLabel>
          <StyledCheckbox
            onChange={
              (_e, checked) => setFieldValue(
                'selectedEligibleTenantTypes',
                checked ? eligibleTenantTypes : undefined,
              )
            }
          />
          Select All
        </CheckboxLabel>
      </TenantTypesTitleWrapper>
      <StyledCheckboxButtonsList
        options={eligibleTenantTypes}
        checked={values.selectedEligibleTenantTypes}
        onChange={(selected) => setFieldValue('selectedEligibleTenantTypes', selected)}
      />
      {/* <Title>Validation Hours</Title>
      <WeekTimeTable
        data={formik.values.validationPolicies!}
        onChange={(newData) => formik.setFieldValue('validationPolicies', newData)}
      /> */}
      <Row justifyContent="flex-end">
        <Button 
          variant="contained" 
          type="submit" 
          color="primary"
          loading={isCreateLoading || isUpdateLoading }>Save</Button>
        <Button variant="text" color="primary" onClick={onClose}>Cancel</Button>
      </Row>
    </form>
  );
};

const StyledModal = styled(Modal)`
@media (max-width: 1220px) {
  margin: auto 30px;
}

@media (max-width: 800px) {
  margin: auto;
}
`;

const TypesAndFeesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 30px;

  & > * {
    min-width: 200px;
    flex-grow: 1;
  }
`;

const TenantTypesTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledCheckboxButtonsList = styled(CheckboxButtonsList)`
  margin-bottom: 30px;
  max-width: 700px;
` as typeof CheckboxButtonsList;

const TenantTypesTitle = styled(Title)`
  margin-right: 10px;
  margin-bottom: 0;
`;

const CheckboxLabel = styled.label`
  white-space: nowrap;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 10px;
`;

export default ValidationPolicyModal;
