/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import {
  getAggregatedTransactionsDetails,
} from '@zero5/payment-api';

import { queryGroupName } from './queryGroupName';
import { mapAggregatedTransactionsDetails } from './mappings';

type Response = ReturnType<typeof mapAggregatedTransactionsDetails>;
type Params = {
  page?: number;
  take?: number;
  from: number;
  until: number;
  aggregationPeriod?: 'daily' | 'weekly';
};

export const queryKey: QueryKey = 'aggregatedTransactionsDetails';

type Key = [typeof queryGroupName, typeof queryKey, Params];

const useAggregatedTransactionsDetailsQuery = (
  params: Params,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    [queryGroupName, queryKey, params],
    async () => {
      const response = await getAggregatedTransactionsDetails(params);

      return mapAggregatedTransactionsDetails(response);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      keepPreviousData: true,
      ...options,
    },
  );
};

export default useAggregatedTransactionsDetailsQuery;
