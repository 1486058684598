import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import {
  createTheme, CssBaseline, StylesProvider, ThemeProvider,
} from '@material-ui/core';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Helmet } from 'react-helmet';
// eslint-disable-next-line import/extensions
import { PersistGate } from 'redux-persist/integration/react';

import themeOptions from '@zero5/ui/lib/styles/themeOptions';
import { ScrollToTop } from '@zero5/ui';

import store, { persistor } from '@/store';

import WebSocket from '@/components/WebSocket';

import history from '@/utils/history';
import queryClient from '@/utils/queryClient';

import CallContainer from '@controlCenter/containers/CallContainer';
import EntryActivityList from '@/controlCenter/containers/EntryActivityList';
import IssueList from '@/controlCenter/containers/IssueList';

import AppleTouchIcon from '@/assets/favicons/apple-touch-icon.png';
import Favicon16 from '@/assets/favicons/favicon-16x16.png';
import Favicon32 from '@/assets/favicons/favicon-32x32.png';

import Home from './Home';
import Revenue from './Revenue';
import GuidanceMap from './GuidanceMap';
import Permit from './Permit';
import Validation from './Validation';
import Events from './Events';
import ParkingPolicy from './ParkingPolicy';
import ParkingSettings from './ParkingSettings';
import MyAccount from './MyAccount';
import ManageUsers from './ManageUsers';
import ManageTenants from './ManageTenants';
import Login from './Login';
import ResetPassword from './ResetPassword';
import PageNotFound from './PageNotFound';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  html {
    font-size: 14px;
    scroll-behavior: smooth;
  }

  body {
    background-color: #ffffff;
    color: #393F49;
    font-size: 14px;
    font-family: Nunito Sans, sans-serif;
    letter-spacing: 0.01em;
  }
`;

const theme = createTheme(themeOptions);

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Router history={history}>
        <ScrollToTop />
        <Helmet>
          <link rel="icon" type="image/png" sizes="32x32" href={Favicon32} />
          <link rel="icon" type="image/png" sizes="16x16" href={Favicon16} />
          <link rel="apple-touch-icon" sizes="180x180" href={AppleTouchIcon} />
        </Helmet>
        <Provider store={store}>
          <PersistGate
            persistor={persistor}
            loading={null}
          >
            <ThemeProvider theme={theme}>
              <StylesProvider injectFirst>
                <CssBaseline />
                <GlobalStyle />

                <Switch>
                  <Route component={Home} path="/" exact />
                  <Route component={Revenue} path="/revenue" exact />
                  <Route component={GuidanceMap} path="/guidance-map" exact />
                  <Route component={Permit} path="/permit" exact />
                  <Route component={Validation} path="/validation" exact />
                  <Route component={Events} path="/events" exact />
                  <Route component={ParkingPolicy} path="/parking-policy" exact />
                  <Route component={ParkingSettings} path="/parking-settings" exact />

                  <Route component={EntryActivityList} path="/control-center/entry-activities-list" exact />
                  <Route component={IssueList} path="/control-center/issue-list" exact />

                  <Route component={MyAccount} path="/my-account" exact />
                  <Route component={ManageUsers} path="/manage-users" exact />
                  <Route component={ManageTenants} path="/manage-tenants" exact />

                  <Route component={Login} path="/login" exact />
                  <Route component={ResetPassword} path="/reset-password" exact />

                  <Route component={PageNotFound} path="*" />
                </Switch>

                <WebSocket />
                <CallContainer />
              </StylesProvider>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
