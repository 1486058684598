import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { TableCellProps } from '@material-ui/core';

import {
  Button,
  LoadingIndicator,
  Row, RowsMap, Select, Skeleton, Table,
} from '@zero5/ui';
import { DateRange } from '@zero5/ui/lib/Chart';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';

import useTransactionsSummaryQuery from '@/api/revenue/useTransactionsSummaryQuery';
import {
  AggregatedTransactionDetail,
  RevenueReport as RevenueReportType,
  RevenueDetail,
  TransactionsAggregationPeriod,
} from '@/api/revenue/models';
import useTransactionsDetailsQuery from '@/api/revenue/useTransactionsDetailsQuery';
import useAggregatedTransactionsDetailsQuery from '@/api/revenue/useAggregatedTransactionsDetailsQuery';

import { selectCurrentGarage } from '@/store/selectors/workspace';

import { emptyFieldStub } from '@/utils/emptyFieldStub';
import useDateModule from '@/utils/date/useDateModule';

import { mapTransactionTypeToTitle } from '../utils';
import {
  BigText,
  GarageNameRow,
  MediumLevelTitle,
  RowWithOffset,
  TableHiddenScroll,
  TopLevelTitle,
  TransactionsTable,
} from '../components/styled';
import SummaryTable from '../components/SummaryTable';
import ExportButton from '../components/ExportButton';
import Service from '../components/Service';

interface Props {
  revenueReport: RevenueReportType;
  onClose: () => void;
  onExport: (period: TransactionsAggregationPeriod) => void;
  isExporting?: boolean;
}

const transactionOptions: Array<{
  label: string;
  value: TransactionsAggregationPeriod;
}> = [
  {
    label: 'All Transactions',
    value: 'all',
  },
  {
    label: 'Daily',
    value: 'daily',
  },
  {
    label: 'Weekly',
    value: 'weekly',
  },
];

const commonCellProps: TableCellProps = {
  align: 'left',
  style: {
    padding: '11.5px 30px 12.5px',
  },
};

const RevenueReport: React.FC<Props> = ({
  revenueReport,
  onClose,
  onExport,
  isExporting,
}) => {
  const {
    formatTimeStampToString,
    formatFullDate,
    getEndOfMonth,
    getStartOfMonth,
  } = useDateModule();

  const [transactionsType, setTransactionsType] = React.useState<TransactionsAggregationPeriod>('all');

  const [transactionsDetailsPage, setTransactionsDetailsPage] = React.useState(0);
  const [transactionsDetailsRowsPerPage, setTransactionsDetailsRowsPerPage] = React.useState(10);

  const garage = useSelector(selectCurrentGarage);

  const dateRange: DateRange = useMemo(() => {
    const date = new Date(revenueReport.date);

    const start = getStartOfMonth(date);
    const end = getEndOfMonth(date);

    return {
      start,
      end,
    };
  }, [revenueReport.date, getStartOfMonth, getEndOfMonth]);

  const {
    data: transactionsSummaryData,
    isLoading: transactionsSummaryLoading,
  } = useTransactionsSummaryQuery({
    from: Number(dateRange.start),
    until: Number(dateRange.end),
  });
  const {
    data: transactionsDetailsData,
    isLoading: transactionsDetailsLoading,
    isFetching: transactionsDetailsFetching,
  } = useTransactionsDetailsQuery({
    from: Number(dateRange.start),
    until: Number(dateRange.end),
    page: transactionsDetailsPage + 1,
    take: transactionsDetailsRowsPerPage,
  }, {
    enabled: transactionsType === 'all',
  });
  
  const {
    data: aggregatedTransactionsDetailsData,
    isLoading: aggregatedTransactionsDetailsLoading,
    isFetching: aggregatedTransactionsDetailsFetching,
  } = useAggregatedTransactionsDetailsQuery({
    from: Number(dateRange.start),
    until: Number(dateRange.end),
    page: transactionsDetailsPage + 1,
    take: transactionsDetailsRowsPerPage,
    aggregationPeriod: transactionsType === 'all' ? undefined : transactionsType,
  }, {
    enabled: transactionsType !== 'all',
  });

  const revenueDetailsRowsMap = React.useMemo(() => {
    const details: RowsMap<RevenueDetail> = [
      {
        title: 'Date',
        id: 'date',
        data: ({ date }) => formatFullDate(date),
        disableSorting: true,
        HeadCellProps: {
          width: 194,
          ...commonCellProps,
        },
        CellProps: {
          ...commonCellProps,
        },
      },
      {
        title: 'Type',
        id: 'service',
        data: ({ service }) => <Service service={service} />,
        disableSorting: true,
        HeadCellProps: {
          width: 100,
          ...commonCellProps,
        },
        CellProps: {
          ...commonCellProps,
        },
      },
      {
        title: 'Payment Method',
        id: 'transactionType',
        data: ({ transactionType }) => mapTransactionTypeToTitle[transactionType],
        disableSorting: true,
        HeadCellProps: {
          width: 169,
          ...commonCellProps,
        },
        CellProps: {
          ...commonCellProps,
        },
      },
      {
        title: 'Note',
        id: 'note',
        data: () => emptyFieldStub,
        disableSorting: true,
        HeadCellProps: {
          width: 260,
          ...commonCellProps,
        },
        CellProps: {
          ...commonCellProps,
        },
      },
      {
        title: 'Amount',
        id: 'amount',
        data: ({ amount }) => formatPrice(amount).replace('$', '$ '),
        disableSorting: true,
        HeadCellProps: {
          width: 116,
          align: 'right',
          style: {
            padding: '11.5px 33px 12.5px 30px',
          },
        },
        CellProps: {
          align: 'right',
          style: {
            padding: '11.5px 33px 12.5px 30px',
          },
        },
      },
      {
        title: 'Total Amount',
        id: 'total',
        data: ({ total }) => formatPrice(total).replace('$', '$ '),
        disableSorting: true,
        HeadCellProps: {
          width: 165,
          align: 'right',
          style: {
            padding: '11.5px 47px 12.5px 30px',
          },
        },
        CellProps: {
          align: 'right',
          style: {
            padding: '11.5px 47px 12.5px 30px',
          },
        },
      },
    ];

    return details;
  }, []);

  const aggregatedRevenueDetailsRowsMap = React.useMemo(() => {
    const details: RowsMap<AggregatedTransactionDetail> = [
      {
        title: 'Date',
        id: 'date',
        data: ({ date }) => date,
      },
      {
        title: 'Transient',
        id: 'transient',
        data: ({ transient }) => formatPrice(transient),
      },
      {
        title: 'Permit',
        id: 'permit',
        data: ({ permit }) => formatPrice(permit),
      },
      {
        title: 'Validation',
        id: 'validation',
        data: ({ validation }) => formatPrice(validation),
      },
      {
        title: 'Event',
        id: 'event',
        data: ({ event }) => formatPrice(event),
      },
      {
        title: 'Total Amount',
        id: 'total',
        data: ({ total }) => formatPrice(total),
      },
    ];

    return details;
  }, []);

  return (
    <>
      <RowWithOffset>
        <TopLevelTitle>Revenue Information</TopLevelTitle>
        <ExportButton
          onClick={() => onExport(transactionsType)}
          isExporting={isExporting}
          data-test="revenue-reports-modal-export-btn"
        />
      </RowWithOffset>

      <GarageNameRow>
        <BigText>{garage?.label}</BigText>
        <TopLevelTitle>
          {`${
            formatTimeStampToString(+dateRange.start, 'MM/dd/yyyy')
          } - ${
            formatTimeStampToString(+dateRange.end, 'MM/dd/yyyy')
          }`}
        </TopLevelTitle>
      </GarageNameRow>

      {transactionsSummaryLoading ? (
        <TransactionsSkeleton width="100%" height="310px" variant="rect" />
      ) : (
        <RevenueSummary data-test="revenue-reports-modal-summary">
          <RowWithOffset>
            <MediumLevelTitle>Revenue Summary</MediumLevelTitle>
          </RowWithOffset>
          <RowWithOffset>
            <BigText>
              {
                transactionsSummaryLoading
                  ? <Skeleton width="150px" />
                  : formatPrice(transactionsSummaryData?.total || 0)
              }
            </BigText>
          </RowWithOffset>
          <TableHiddenScroll>
            <SummaryTable
              report={transactionsSummaryData}
            />
          </TableHiddenScroll>
        </RevenueSummary>
      )}

      <Row>
        <TopLevelTitle>Details</TopLevelTitle>
        <StyledSelect
          options={transactionOptions}
          onChange={(option) => {
            setTransactionsType(option!.value);
            setTransactionsDetailsPage(0);
          }}
          value={transactionOptions.find(({ value }) => value === transactionsType)}
        />
      </Row>

      {transactionsType === 'all' ? (
        <>
          {transactionsDetailsLoading ? (<LoadingIndicator />) : (
            <StyledTransactionsTable
              rowsMap={revenueDetailsRowsMap}
              data={transactionsDetailsData!.detailedStats}
              page={transactionsDetailsPage}
              onPageChange={(e, page) => setTransactionsDetailsPage(page)}
              onRowsPerPageChange={(e) => setTransactionsDetailsRowsPerPage(Number(e.target.value))}
              count={transactionsDetailsData!.entriesCount}
              isLoading={transactionsDetailsFetching}
              minWidth="1008px"
              data-test="revenue-reports-modal-details"
            />
          )}
        </>
      ) : (
        <>
          {aggregatedTransactionsDetailsLoading ? (<LoadingIndicator />) : (
            <StyledTransactionsTable
              rowsMap={aggregatedRevenueDetailsRowsMap}
              data={aggregatedTransactionsDetailsData!.detailedStats}
              page={transactionsDetailsPage}
              onPageChange={(e, page) => setTransactionsDetailsPage(page)}
              onRowsPerPageChange={(e) => setTransactionsDetailsRowsPerPage(Number(e.target.value))}
              count={aggregatedTransactionsDetailsData!.entriesCount}
              isLoading={aggregatedTransactionsDetailsFetching}
              minWidth="1080px"
            />
          )}
        </>
      )}

      <ButtonRow justifyContent="flex-end">
        <Button
            variant="contained"
            color="primary"
            onClick={onClose}
            data-test="revenue-reports-modal-close-btn"
        >
          Close
        </Button>
      </ButtonRow>
    </>
  );
};

const StyledSelect = styled(Select)`
  flex-shrink: 0;
  min-width: 160px;
` as typeof Select;

const TransactionsSkeleton = styled(Skeleton)`
  margin-bottom: 30px;
`;

const RevenueSummary = styled.div`
  padding: 15px 20px;
  background: #E7E5E5;
  border-radius: 5px;
  margin-bottom: 30px;
`;

const ButtonRow = styled(Row)`
  margin-top: 30px;
`;

const StyledTransactionsTable = styled(TransactionsTable)`
  margin-top: 10px;
` as typeof Table;

export default RevenueReport;
