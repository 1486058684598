/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { listClientAdminRoles } from '@zero5/user-api';

import { mapRoles } from '@/api/mappings/user';

type Response = ReturnType<typeof mapRoles>;
const queryKey: QueryKey = 'userRolesQuery';

const useUserRolesQuery = (
  options?: UseQueryOptions<Response, unknown, Response, typeof queryKey>,
) => {
  return useQuery<Response, unknown, Response, typeof queryKey>(
    queryKey,
    async () => {
      const response = await listClientAdminRoles();

      return mapRoles(response);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useUserRolesQuery;
