import { useQuery, UseQueryOptions } from 'react-query';

import { getLatestEntryActivities } from '@zero5/client-admin-api';

import { loadImage } from '@/utils/image';

import { mapIssueEntryActivities } from './mappings';
import { ACTIVITY_QUERY, GET_ISSUE_ACTIVITIES_QUERY } from './constants';

type Response = ReturnType<typeof mapIssueEntryActivities>;
type Params = {
  page: number;
  perPage: number;
  fromDate?: number;
};

export const queryKey = [ACTIVITY_QUERY, GET_ISSUE_ACTIVITIES_QUERY] as const;

// const PER_PAGE = 10;

type Key = [...typeof queryKey, Params];

const useGetIssueActivities = (
  params: Params,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    [...queryKey, params],
    async () => {
      const response = await getLatestEntryActivities({
        take: params.perPage,
        fromDate: params.fromDate,
        skip: params.page * params.perPage,
      });

      const mappedIssueActivities = mapIssueEntryActivities(response);

      mappedIssueActivities.activities.forEach(({ frontImg, rearImg }) => {
      // response.activities.forEach((activity) => {
        // const { frontImg, rearImg } = activity.data;
        if (frontImg) {
          loadImage(frontImg);
        }
        if (rearImg) {
          loadImage(rearImg);
        }
      });

      return mappedIssueActivities;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useGetIssueActivities;
