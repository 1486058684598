import React from 'react';
import { useMediaQuery } from '@material-ui/core';

import { DatePicker, SimpleRowsMap } from '@zero5/ui';
import { centsToCost } from '@zero5/ui/lib/utils/formatters/formatPrice';
import { formatDuration } from '@zero5/ui/lib/utils/formatters/formatDuration';

import { emptyFieldStub } from '@/utils/emptyFieldStub';
import useDateModule from '@/utils/date/useDateModule';

import { Activity } from '@/controlCenter/api/activity/models';
import { PaymentFeatures } from '@/controlCenter/api/call/models';
import { hasMinutes } from '@/controlCenter/utils/callData';

import EditableText from '../common/EditableText';
import Select from '../common/Select';

import { StyledDataCard, StyledSimpleTable, ApplyButton, CardContent } from './styled';

type DiscountOption = {
  label: string;
  value: number | null;
};

type AccessInfo = {
  entryActivity?: Activity | null;
  exitActivity?: Activity;
};

interface Props {
  info: AccessInfo;
  activityUpdating: boolean;
  onApply: (data: {
    entryDate: Date | null;
    fee: number | null;
    discount: number | null;
    afterSuccessfullyUpdate: () => void;
  }) => void;
  fee?: number;
  paymentFeatures?: PaymentFeatures | null;
  /**
   * Screen size from which to place in the column
   */
  columnFrom: string;
  className?: string;
}

const discountOptions: Array<DiscountOption> = [{
  label: 'No discount',
  value: null,
}];

const ActivityPaymentInfo: React.FC<Props> = ({
  info,
  activityUpdating,
  onApply,
  fee,
  paymentFeatures,
  columnFrom,
  className,
}) => {
  const {
    z5DateModule,
    formatTimeStampToString,
  } = useDateModule();

  const isColumn = useMediaQuery(`(max-width: ${columnFrom})`, { noSsr: true });

  const [editedEntryDate, setEditedEntryDate] = React.useState<Date | null>(null);
  const [editedFee, setEditedFee] = React.useState<number | null>(null);
  const [editedDiscount, setEditedDiscount] = React.useState<DiscountOption | null>(
    discountOptions.find((option) => {
      if (!paymentFeatures) return false;

      return hasMinutes(paymentFeatures) ? option.value === paymentFeatures.minutes : false;
    }) || null,
  );

  const onChangeFee = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace('$', '');
    if (!isNaN(Number(value)) || value === '') {
      if (value.match(/^[0-9]+\.[0-9]{3,}$/)){
        value = Number(value).toFixed(2);
      }
      value = value.replace(/^0{2,}/, '0');
      if (value.match(/^0+[1-9]+\.?[0-9]*$/)){
        value = value.replace(/^0/, '');
      }
      if (value === '') value = '0';
      setEditedFee(Number(value));
      setEditedDiscount(null);
    }
  }, []);

  const applyHandler = React.useCallback(() => {
    onApply({
      entryDate: editedEntryDate,
      discount: editedDiscount ? editedDiscount.value : null,
      fee: editedFee,
      afterSuccessfullyUpdate: () => {
        setEditedFee(null);
      },
    });
  }, [editedDiscount, editedEntryDate, editedFee, onApply]);

  const garageTime = React.useMemo(
    () => z5DateModule.fromUtc(new Date().getTime()).getTimezoneTimeAsLocalDate(),
    [z5DateModule],
  );
  const minTime = React.useMemo(
    () => new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)),
    [],
  );
  const maxTime = React.useMemo(
    () => {
      return (editedEntryDate || garageTime).getDate() === garageTime.getDate()
        ? garageTime
        : new Date(new Date(new Date(new Date().setHours(23)).setMinutes(59)).setSeconds(59));
    },
    [garageTime, editedEntryDate],
  );

  const paymentRowsMap: SimpleRowsMap<{
    entryActivity?: Activity | null;
    exitActivity?: Activity;
  }> = [
    {
      title: 'Entry Time',
      data: ({ entryActivity }) => entryActivity?.eventTime
        ? formatTimeStampToString(entryActivity.eventTime, 'MM/dd/yyyy HH:mm:ss')
        : entryActivity
          ? <DatePicker
              pickerProps={{
                selected: editedEntryDate || new Date(),
                onChange: (date) => setEditedEntryDate(date as Date),
                showTimeSelect: true,
                dateFormat: 'MM/dd/yyyy HH:mm',
                maxDate: maxTime,
                maxTime: maxTime,
                minTime: minTime,
                disabled: activityUpdating,
              }}
            />
          : emptyFieldStub,
    },
    {
      title: 'Exit Time',
      data: ({ exitActivity }) => exitActivity?.eventTime
        ? formatTimeStampToString(exitActivity.eventTime, 'MM/dd/yyyy HH:mm:ss')
        : emptyFieldStub,
    },
    {
      title: 'Duration',
      data: ({ entryActivity, exitActivity }) => entryActivity && exitActivity
        ? formatDuration(entryActivity.eventTime, exitActivity.eventTime)
        : emptyFieldStub,
    },
    {
      title: 'Fee',
      data: ({ exitActivity }) => {
        return exitActivity
          ? <EditableText
              value={'$' + (editedFee ?? centsToCost(fee))}
              onChange={onChangeFee}
            />
          : emptyFieldStub;
      },
    },
    {
      title: 'Method',
      data: () => emptyFieldStub,
    },
    {
      title: 'Validation',
      data: () => emptyFieldStub,
    },
    {
      title: 'Discount',
      data: ({ exitActivity: exitActivityLocal }) => (
        exitActivityLocal
          ? <Select
              onChange={(option) => {
                setEditedDiscount(option);
                setEditedFee(null);
              }}
              value={editedDiscount}
              options={discountOptions}
            />
          : emptyFieldStub
      ),
    },
  ];

  return (
    <StyledDataCard
      label="Payment"
      direction={isColumn ? 'column' : 'row'}
      className={className}
    >
      <CardContent>
        <StyledSimpleTable
          rowsMap={paymentRowsMap}
          item={info}
          align="left"
        />
        <ApplyButton
          variant="contained"
          color="primary"
          disabled={!editedEntryDate && !editedFee && !editedDiscount}
          loading={activityUpdating}
          onClick={applyHandler}
        >
          Apply
        </ApplyButton>
      </CardContent>
    </StyledDataCard>
  );
};

export default ActivityPaymentInfo;