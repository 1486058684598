import { Garage as GarageResponse } from '@zero5/garage-api';

import { Garage } from '../models/garages';

export const mapGarage = (garage: GarageResponse): Garage => ({
  garageId: garage.garageId,
  externalGarageId: garage.externalGarageId,
  label: garage.label,
  address: garage.address,
  phone: garage.phone,
  url: garage.url,
  longitude: garage.longitude,
  latitude: garage.latitude,
  capacity: garage.capacity,
  permitLimit: garage.permitLimit,
  timezone: garage.timezone,
});
