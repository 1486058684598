import { createReducerFunction, ImmerReducer } from 'immer-reducer';

import { Activity } from '@/api/models/activities';

export interface ActivitiesState {
  newActivitiesQueue: Activity[];
}

const initialState: ActivitiesState = {
  newActivitiesQueue: [],
};

export class ActivitiesReducer extends ImmerReducer<ActivitiesState> {
  addNewActivitiesToQueue(newActivity: Activity) {
    this.draftState.newActivitiesQueue = [newActivity, ...this.state.newActivitiesQueue];
  }
  clearNewActivitiesQueue() {
    this.draftState.newActivitiesQueue = [];
  }
}

export default createReducerFunction(ActivitiesReducer, initialState);
