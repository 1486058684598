import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export const checkKeySelect = (
  callback: (e: React.KeyboardEvent) => void,
  keys: Array<string> = ['Enter', ' '],
) => (e: React.KeyboardEvent) => {
  e.preventDefault();

  if (keys.some((key) => key === e.key)) {
    callback(e);
  }
};
