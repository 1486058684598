import { QueryClient } from 'react-query';

import { GateStateType } from '@zero5/garage-api';
import { UnionCaCcActionMessageType } from '@zero5/client-admin-api';

import { queryKey as getEventsQuery } from '@/api/queries/useEventsQuery';
import { queryKey as getActivitiesQuery } from '@/api/queries/useActivitiesQuery';
import { Activity } from '@/api/models/activities';
import { mapActivityWithType } from '@/api/mappings/activity';

import { deviceActions } from '@/store/actions/device';
import { activitiesActions } from '@/store/actions/activities';
import { AppThunk } from '@/store';

import { loadImage } from '@/utils/image';

export const updateGateState = (msg: UnionCaCcActionMessageType): AppThunk => (dispatch) => {
  if (msg.eventType === 'DeviceActionMessage') {
    const { deviceId, isLocked, status } = msg.payload;
    if (status === GateStateType.DOWN) {
      dispatch(deviceActions.setGateControlInDeviceList(true, deviceId, isLocked));
    }
    if (status === GateStateType.UP) {
      dispatch(deviceActions.setGateControlInDeviceList(false, deviceId, isLocked));
    }
    if (status === GateStateType.ERROR) {
      dispatch(deviceActions.setErrorInDevice(deviceId));
    }
  }
};

// eslint-disable-next-line max-len
export const addActivity = (msg: UnionCaCcActionMessageType): AppThunk => (dispatch) => {
  if (msg.eventType === 'ActivityActionMessage') {
    const socketActivity: Activity = mapActivityWithType(msg.payload);
    setTimeout(() => {
      if (socketActivity.frontImg) loadImage(socketActivity.frontImg);
      if (socketActivity.rearImg) loadImage(socketActivity.rearImg);
      dispatch(activitiesActions.addNewActivitiesToQueue(socketActivity));
    }, process.env.NODE_ENV === 'development' ? 20000 : 0);
  }
};

export const updateEventHandler = (msg: UnionCaCcActionMessageType, queryClient: QueryClient) => {
  if (msg.eventType === 'EventUpdatedActionMessage' || msg.eventType === 'EventCreatedActionMessage') {
    queryClient.refetchQueries(getEventsQuery);
  }
};

export const updateActivityHandler = (msg: UnionCaCcActionMessageType, queryClient: QueryClient) => {
  if (msg.eventType === 'ExitActivityCreatedActionMessage'
    || msg.eventType === 'ExitActivityResolvedActionMessage'
    || msg.eventType === 'ExitActivityUpdatedActionMessage'
    || msg.eventType === 'EntryActivityCreatedActionMessage'
    || msg.eventType === 'EntryActivityUpdatedActionMessage'
    || msg.eventType === 'ActivityUpdatedActionMessage'
  ) {
    queryClient.refetchQueries(getActivitiesQuery);
  }
};
