/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import {
  getAvailablePermitTypes,
} from '@zero5/permit-api';

import { handleError } from '@/utils/handleError';

import { queryGroupName } from './queryGroupName';
import { mapPermitTypes } from './mappings';

type Response = ReturnType<typeof mapPermitTypes>;

export const queryKey: QueryKey = 'getPermitTypes';

type Key = [typeof queryGroupName, typeof queryKey];

const useGetPermitTypesQuery = (
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    [queryGroupName, queryKey],
    async () => {
      const response = await getAvailablePermitTypes();

      return mapPermitTypes(response.permitTypes);
    },
    {
      ...options,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      staleTime: 10 * 60 * 1000,
      onError: (e) => handleError(e),
    },
  );
};

export default useGetPermitTypesQuery;
