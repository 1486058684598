import React from 'react';
import styled from 'styled-components';

import Button from '@/components/common/Button';

import Role from '../common/Role';

import { ValidationType } from './ValidationContent';

interface Props {
  setValidationType: (type: ValidationType) => void;
}

const ValidationTypeSelect: React.FC<Props> = ({ setValidationType }) => {
  return (
    <StyledValidationTypeSelect>
      <Role widgetId="validation:parkingValidation" action="w">
        <Button
          width="335px"
          variant="contained"
          color="primary"
          disabled={process.env.STAGE === 'prod'}
          onClick={() => setValidationType('Parking')}
        >
          Parking Validation
        </Button>
      </Role>
      <Gap width="100px" />
      <Role widgetId="validation:valetValidation" action="w">
        <Button
          width="335px"
          variant="text"
          color="primary"
          type="submit"
          disabled
          onClick={() => setValidationType('Valet')}
        >
          Valet Validation
        </Button>
      </Role>
    </StyledValidationTypeSelect>
  );
};

const StyledValidationTypeSelect = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 170px;
  @media (max-width: 568px) {
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin-top: 40px;
  }
`;

const Gap = styled.div<{ width: string; shrink?: number; grow?: number; }>`
  width: ${({ width }) => width};
  flex-shrink: ${({ shrink }) => (shrink === undefined ? 2 : shrink)};
  ${({ grow }) => typeof grow === 'number' && `flex-grow: ${grow};`}
`;

export default ValidationTypeSelect;
