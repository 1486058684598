import { ValidationType as ValidationTypelib } from '@zero5/garage-api';
import { centsToCost } from '@zero5/ui/lib/utils/formatters/formatPrice';


import { ValidationType } from '../models/ValidationType';

export const mapValidationType = (validationTypeLib: ValidationTypelib): ValidationType =>{
  const { costCents, maxValidationTimeMin, ...restValidationTypeLib } = validationTypeLib;
  const price = centsToCost(costCents);
  const hours = Math.round(maxValidationTimeMin / 60);

  return {
    ...restValidationTypeLib,
    price,
    hours,
  };
};

export const mapValidationTypeList = (validationTypeLibList: ValidationTypelib[]): ValidationType[] =>{
  return validationTypeLibList.map(mapValidationType);
};