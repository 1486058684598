import { EntryStatus, IssueStatus } from '@zero5/client-admin-api';
import { VehicleStatus } from '@zero5/client-admin-api';

export enum LPRStatus {
  OK = 'OK',
  BLOCKED = 'BLOCKED',
  MISSING = 'MISSING',
  LOW_CONF_LEVEL = 'LOW_CONF_LEVEL',
  INVALID = 'INVALID',
}

export enum ActivityStatus {
  OK = 'OK',
  ERROR = 'ERROR',
}


export type Activity = {
  activityId: string;
  garageId: string;
  deviceId: string;
  frontConfLevel: number | null;
  frontImg: string | null;
  frontLpNum: string | null;
  frontLpState: string | null;
  frontStatus: LPRStatus | null;
  rearConfLevel: number | null;
  rearImg: string | null;
  rearLpNum: string | null;
  rearLpState: string | null;
  rearStatus: LPRStatus | null;
  mainLp: string | null;
  mainState: string | null;
  eventTime: number;
  status: ActivityStatus;
  vehicleType: string | null;
  vehicleMake: string | null;
  vehicleModel: string | null;
  vehicleColor: string | null;
  frontLpResolutionStatus: VehicleStatus | null;
  rearLpResolutionStatus: VehicleStatus | null;
  mainLpResolutionStatus: EntryStatus | null;
};

export type IssueActivity = Activity & {
  issueStatus: IssueStatus;
};
