import React from 'react';
import styled from 'styled-components';

import vars from '@zero5/ui/lib/styles/vars';

import { InvoiceStatus as InvoiceStatusType } from '@/api/revenue/models';

interface Props {
  status: InvoiceStatusType;
}

export const invoiceStatusToView: Record<InvoiceStatusType, {
  color: string;
  title: string;
}> = {
  Complete: {
    color: vars.palette.primary,
    title: 'Complete',
  },
  Pending: {
    color: vars.palette.primary,
    title: 'Pending',
  },
  Incomplete: {
    color: vars.palette.primary,
    title: 'Incomplete',
  },
};

const InvoiceStatus: React.FC<Props> = ({ status }) => {
  if (!(status in invoiceStatusToView)) {
    return <div>{status}</div>;
  }

  return (
    <Status color={invoiceStatusToView[status].color}>
      {invoiceStatusToView[status].title}
    </Status>
  );
};

const Status = styled.div<{ color: string; }>`
  color: ${({ color }) => color};
`;

export default InvoiceStatus;