import { useMutation, UseQueryOptions } from 'react-query';

import { deleteHoursOpen, DeleteHoursOpenArgs } from '@zero5/garage-api';

import { GARAGE_DELETE_GARAGE_HOURS, GARAGE_HOURS_OPEN_QUERY, GARAGE_QUERY } from '@/api/garage/constants';

import { handleError } from '@/utils/handleError';
import queryClient from '@/utils/queryClient';

const useDeleteGarageHoursOpenMutation = (
  options: UseQueryOptions = {},
) => {
  return useMutation(
    [GARAGE_QUERY, GARAGE_DELETE_GARAGE_HOURS],
    async (params: DeleteHoursOpenArgs) => {
      const response = await deleteHoursOpen(params);
      return response;
    },
    {
      onError: async (error) => {
        handleError(error);
        await queryClient.refetchQueries([GARAGE_QUERY, GARAGE_HOURS_OPEN_QUERY]);
      },
      onSuccess: () => queryClient.refetchQueries(GARAGE_HOURS_OPEN_QUERY),
      ...options,
    },
  );
};

export default useDeleteGarageHoursOpenMutation;
