import { UnionCaCcActionMessageType } from '@zero5/client-admin-api';

import { callActions, endCall } from '@controlCenter/store/actions/call';

import { AppThunk } from '../store';
import { mapCall } from '../api/call/mappings';

const connectCall = (msg: UnionCaCcActionMessageType): AppThunk => (dispatch) => {
  if (msg.eventType === 'InputCallActionMessage') {
    dispatch(callActions.addCalls(mapCall(msg.payload.callData)));
  }
  if (msg.eventType === 'CallEndedActionMessage' || msg.eventType === 'CallAnsweredActionMessage') {
    const requestId = msg.payload?.requestId;

    if (requestId){
      dispatch(callActions.removeCall(requestId));
      if (msg.eventType === 'CallEndedActionMessage') dispatch(endCall(requestId));
    }
  }
};

export default connectCall;
