import { GarageHoursPolicy } from '@/api/models/garages';

import { setHoursAndMinutes } from './setHoursAndMinutes';

export const sortPolicyArray = <T extends {
  day: GarageHoursPolicy['day'];
  start: string;
}>(policies?: Array<T>) =>{
  return policies ? [...policies].sort((policy, policyTwo) => {
    if (policy.day === policyTwo.day){
      return setHoursAndMinutes(policy.start).getTime() - setHoursAndMinutes(policyTwo.start).getTime();
    }
    return 0;
  }) : [];
};