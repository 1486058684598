import styled from 'styled-components';

import * as z5ui from '@zero5/ui';

const Button = styled(z5ui.Button)<{ width?: string; }>`
  ${({ width }) => (width ? `width: ${width};` : '')}
  font-size: 14px;
  font-weight: 300;
  text-transform: none;
  line-height: 1.375;
  @media (max-width: 568px) {
    width: 100%;
  }
`;

export default Button;
