import {
  ActivityResolved,
  CallData,
  DiscountMinutes,
  EntryNotFound,
  PaymentFeatures,
  PaymentRecalculatedWithTickets,
} from '@/controlCenter/api/call/models';

export const hasSuggestedActivities = (callData: CallData): callData is EntryNotFound => {
  return callData?.type === 'EntryNotFound';
};

export const hasPaymentCalculationResult = (
  callData: CallData,
): callData is ActivityResolved | PaymentRecalculatedWithTickets => {
  return callData?.type === 'ActivityResolved'
    || callData?.type === 'PaymentRecalculatedWithTickets'
    || callData?.type === 'EntryFound';
};

export const hasMinutes = (paymentFeatures: PaymentFeatures): paymentFeatures is DiscountMinutes => {
  return paymentFeatures.type === 'DiscountMinutes';
};

export const extractSuggestedActivities = (callData: CallData) => {
  if (hasSuggestedActivities(callData)) {
    return callData.payload.suggestedEntryActivities;
  }

  return null;
};
