import { useMutation, UseMutationOptions } from 'react-query';

import { updateUser } from '@zero5/user-api';

import { mapUser } from '../mappings/user';

type Response = ReturnType<typeof mapUser>;

const useUpdateTenantUserMutation = (
  options?: UseMutationOptions<Response, Error, {
    userId: number;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
  }, string>,
) => {
  return useMutation(
    'updateTenant',
    async ({ userId, ...params }) => {
      const response = await updateUser(userId, params);
      return mapUser(response.user);
    },
    {
      ...options,
    },
  );
};

export default useUpdateTenantUserMutation;
