import React from 'react';

import { useFindCurrentAction } from '@zero5/ui';

import useUserGarageRolesQuery from '@/api/queries/useUserGarageRoleQuery';

import PageNoAccess from '@/containers/PageNoAccess';

export const withPreloadRole = <P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P> => (
    props,
  ) => {
    useUserGarageRolesQuery();

    return <Component {...props} />;
  };

export const withRole = (
  featureId: string,
  fallback: JSX.Element | null = <PageNoAccess />,
) => <P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P> => (
    props,
  ) => {
    const {
      data: garageRolesData,
      isLoading: garageRolesLoading,
    } = useUserGarageRolesQuery({
      suspense: true,
    });

    const findCurrentAction = useFindCurrentAction(garageRolesData!.role?.uiPermissions);

    const hasAccess = findCurrentAction(featureId) !== 'h';

    if (hasAccess) return <Component {...props} />;

    if (!hasAccess && !garageRolesLoading) return fallback;

    return null;
  };

export const withAnyRole = (
  featureIds: Array<string>,
  fallback: JSX.Element | null = <PageNoAccess />,
) => <P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P> => (
    props,
  ) => {
    const {
      data: garageRolesData,
      isLoading: garageRolesLoading,
    } = useUserGarageRolesQuery({
      suspense: true,
    });
  
    const findCurrentAction = useFindCurrentAction(garageRolesData!.role?.uiPermissions);
  
    const hasAccess = React.useMemo(() => {
      return featureIds
        .map((featureId) => findCurrentAction(featureId) !== 'h')
        .some((hasAccessLocal) => hasAccessLocal);
    }, [findCurrentAction]);
  
    if (hasAccess) return <Component {...props} />;
  
    if (!hasAccess && !garageRolesLoading) return fallback;
  
    return null;
  };
