import { useQuery, UseQueryOptions } from 'react-query';

import { getPermitTypesCompanyLimits } from '@zero5/permit-api';

import { mapPermitTypesCompanyLimits } from './mappings';
import { permitQueryKeys } from './queryKeys';

type Response = ReturnType<typeof mapPermitTypesCompanyLimits>;
type Key = ReturnType<typeof permitQueryKeys.permitTypesCompanyLimits>;

const usePermitTypesCompanyLimitsQuery = (
  companyId: number,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    permitQueryKeys.permitTypesCompanyLimits(companyId),
    async () => {
      const response = await getPermitTypesCompanyLimits(companyId);

      return mapPermitTypesCompanyLimits(response.limits);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default usePermitTypesCompanyLimitsQuery;
