import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode.react';
import flow from 'lodash/flow';

import { Button, LoadingIndicator } from '@zero5/ui';

import useCompanyGarageSignsQuery from '@/api/queries/useCompanyGarageSignsQuery';

import Page from '@/components/common/page';
import ValidationTypeSelect from '@/components/Validation/ValidationTypeSelect';
import ValidationContent, {
  ValidationType,
} from '@/components/Validation/ValidationContent';

import { withAuth } from '@/utils/hocs/withAuth';
import { withPreloadRole, withRole } from '@/utils/hocs/withRole';
import { withLoading } from '@/utils/hocs/withLoading';
import { withGarage, withPreloadGarage } from '@/utils/hocs/withGarage';

import ArrowSvg from '@/assets/icons/arrow_back_black.inline.svg';

const Validation: React.FC = () => {
  const { data, isLoading } = useCompanyGarageSignsQuery();
  const [link, setLink] = useState<string>('');
  const [validationType, setValidationType] = useState<ValidationType>(null);
  useEffect(() => {
    if (data) {
      setLink(`${process.env.PAY_PAGE_VALIDATION}/${data.signs.validationReceipt}`);
    }
  }, [data]);
  const handleGoBackClick = () => setValidationType(null);
  if (!validationType) {
    return (
      <Page nestedLink={{ title: 'Validation' }}>
        <QRCodeWrapper>
          { !isLoading ? (<QRCode size={150} value={link} />) : <LoadingIndicator />}
        </QRCodeWrapper>
        <ValidationTypeSelect setValidationType={setValidationType} />
      </Page>
    );
  }
  return (
    <Page nestedLink={{ title: 'Validation', nestedTitle: validationType }}>
      <GoBackButton onClick={handleGoBackClick}>
        <ArrowSvg />
        <GoBackText>Go Back</GoBackText>
      </GoBackButton>
      <ValidationContent
        validationType={validationType}
        handleGoBackClick={handleGoBackClick}
      />
    </Page>
  );
};

const GoBackButton = styled(Button)`
  padding: 0;
  align-self: flex-start;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 77px;
  :hover {
  background-color: transparent;
}
`;

const GoBackText = styled.span`
font-style: normal;
font-weight: normal;
margin-left:10px;
text-transform: none;
font-size: 18px;
line-height: 21px;
`;

const QRCodeWrapper = styled.div`
display: flex;
align-items: center;
min-height: 150px;
justify-content: center;
`;

export default flow( 
  withGarage,
  withRole('validation'),
  withPreloadRole,
  withPreloadGarage,
  withAuth(),
  withLoading(),
)(Validation);
