import { useQuery, UseQueryOptions } from 'react-query';

import {  mapIssueEntryActivities } from './mappings';
import { ACTIVITY_QUERY, GET_ALL_ACTIVITIES_QUERY } from './constants';

type Response = ReturnType<typeof mapIssueEntryActivities>;

const queryKey = [ACTIVITY_QUERY, GET_ALL_ACTIVITIES_QUERY];

const useGetAllActivities = (
  options?: UseQueryOptions<Response, unknown, Response, typeof queryKey>,
) => {
  return useQuery<Response, unknown, Response, typeof queryKey>(
    queryKey,
    async () => {
      throw new Error('not implemented');
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useGetAllActivities;
