import styled from 'styled-components';

const Status = styled.span<{ color: string; }>`
  color: ${({ color }) => color};
  position: relative;
  padding-left: 20px;
  font-weight: 600;

  &::before {
    content: '';
    width: 13px;
    height: 13px;
    background-color: ${({ color }) => color};
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export default Status;
