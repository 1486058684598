import { useMutation, UseMutationOptions } from 'react-query';

import { sendDeviceAction, SendDeviceAction } from '@zero5/garage-api';

type Response = void;

const useSendDeviceMutation = (
  options?: UseMutationOptions<Response, Error, SendDeviceAction, string>,
) => {
  return useMutation(
    'sendDeviceAction',
    async (args: SendDeviceAction) => {
      const deviceId = args.deviceId.replace('gate', 'lpr');
      const response = await sendDeviceAction({ ...args, deviceId });
      return response;
    },
    options,
  );
};

export default useSendDeviceMutation;
