import { useMutation, UseMutationOptions } from 'react-query';

import { confirmSystemUserForgotPassword } from '@zero5/auth-api';

type Response = {
  status: 'ok';
};

const useConfirmForgotPasswordMutation = (
  options?: UseMutationOptions<Response, Error, {
    email: string;
    password: string;
    confirmationCode: string;
  }, string>,
) => {
  return useMutation(
    'confirmForgotPassword',
    async (params) => {
      const response = await confirmSystemUserForgotPassword(params);

      return response;
    },
    options,
  );
};

export default useConfirmForgotPasswordMutation;
