import produce from 'immer';
import {
  createTransform,
} from 'redux-persist';

import { DateRange } from '@zero5/ui/lib/Chart';

import { SettingsState } from '@/store/reducers/settings';
import { RootState } from '@/store';

// eslint-disable-next-line import/prefer-default-export
export const datesTransform = createTransform<unknown, SettingsState, RootState, unknown>(
  null,
  (outboundState) => {
    return produce(outboundState, (draft) => {
      const setDateFromRaw = (to?: DateRange) => {
        if (to) {
          to.start = new Date(to.start);
          to.end = new Date(to.end);
        }
      };
      setDateFromRaw(draft.home.revenueDateRange);
      setDateFromRaw(draft.home.trafficDateRange);
      setDateFromRaw(draft.events.dateRange);
      setDateFromRaw(draft.tenants.dateRange);
      setDateFromRaw(draft.users.dateRange);
      setDateFromRaw(draft.permit.dateRange);
      setDateFromRaw(draft.permit.trendDateRange);
      setDateFromRaw(draft.permit.usageDateRange);
      setDateFromRaw(draft.revenue.revenueHistoryDateRange);
      setDateFromRaw(draft.revenue.invoicesDateRange);
      setDateFromRaw(draft.revenue.trendDateRange);
      setDateFromRaw(draft.revenue.trendStreamDateRange);
      setDateFromRaw(draft.traffic.dateRange);
      setDateFromRaw(draft.traffic.trendDateRange);
      setDateFromRaw(draft.traffic.trendTypeDateRange);
      setDateFromRaw(draft.valet.dateRange);
      setDateFromRaw(draft.valet.usageTrendDateRange);
      setDateFromRaw(draft.validationHistory.dateRange);
      setDateFromRaw(draft.validationHistory.trendDateRange);
    });
  },
  { whitelist: ['settingsReducer'] },
);
