import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { deleteValidationType } from '@zero5/garage-api';

import { handleError } from '@/utils/handleError';

import { queryKey as validationTypeQuery } from './useGetValidationTypesQuery';

type Response = unknown;

export const queryKey = 'deletePermitType';

const useDeleteValidationTypeMutation = (
  options?: UseMutationOptions<Response, Error, number, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    queryKey,
    async (id: number) => {
      await deleteValidationType(id);
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(validationTypeQuery);
      },
      onError: (error) => handleError(error),
    },
  );
};

export default useDeleteValidationTypeMutation;
