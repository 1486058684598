import React from 'react';
import styled from 'styled-components';
// import { Tooltip } from '@material-ui/core';

import { ButtonIcon, Row, Tooltip } from '@zero5/ui';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';
import { formatNumber } from '@zero5/ui/lib/utils/formatters/formatNumber';

import { TransactionStats } from '@/api/revenue/models';

import Price from '@/components/common/Price';

import InfoIcon from '@/assets/info.inline.svg';

type Props = {
  forecasted?: boolean;
} & {
  report?: {
    permit: TransactionStats;
    event: TransactionStats;
    transient: TransactionStats;
    validation: TransactionStats;
    dispute: TransactionStats;
    pending?: TransactionStats;
  };
};

const SummaryTable: React.FC<Props> = (props) => {
  return (
    <StyledTable data-test="modal-revenue-table">
      <thead>
        <th aria-label="Table head" />
        <th>Transient</th>
        <th>Permit {props.forecasted ? '(fcst.)' : ''}</th>
        <th>Event {props.forecasted ? '(fcst.)' : ''}</th>
        <th>Validation {props.forecasted ? '(fcst.)' : ''}</th>
        <th>
          <Row justifyContent="flex-end">
            Refunds & Dispute
            <Tooltip
              title="When your business receives a dispute (also called a chargeback),
              the payment amount as well as a separate dispute fee levied by the card
              network will be deducted from your account balance."
              placement="top-end"
              arrow
            >
              <StyledButtonIcon
                Icon={InfoIcon}
                iconProps={{
                  width: '20px',
                  height: '20px',
                }}
              />
            </Tooltip>
          </Row>
        </th>
        {props.report?.pending && (
          <th>Pending</th>
        )}
      </thead>
      <tbody>
        <tr>
          <td>Revenue</td>
          <td>{formatPrice(props.report?.transient.total || 0)}</td>
          <td>{formatPrice(props.report?.permit.total || 0)}</td>
          <td>{formatPrice(props.report?.event.total || 0)}</td>
          <td>{formatPrice(props.report?.validation.total || 0)}</td>
          <td>
            <Price>
              {props.report?.dispute.total || 0}
            </Price>
          </td>
          {props.report?.pending && (
            <td>{formatPrice(props.report?.pending?.total || 0)}</td>
          )}
        </tr>
        <tr>
          <td>Number of Transactions</td>
          <td>{formatNumber(props.report?.transient.count || 0)}</td>
          <td>{formatNumber(props.report?.permit.count || 0)}</td>
          <td>{formatNumber(props.report?.event.count || 0)}</td>
          <td>{formatNumber(props.report?.validation.count || 0)}</td>
          <td>{formatNumber(props.report?.dispute.count || 0)}</td>
          {props.report?.pending && (
            <td>{formatNumber(props.report?.pending?.total || 0)}</td>
          )}
        </tr>
        <tr>
          <td>Average Amount</td>
          <td>{formatPrice(props.report?.transient.average || 0)}</td>
          <td>{formatPrice(props.report?.permit.average || 0)}</td>
          <td>{formatPrice(props.report?.event.average || 0)}</td>
          <td>{formatPrice(props.report?.validation.average || 0)}</td>
          <td>{formatPrice(Math.abs(props.report?.dispute.average || 0))}</td>
          {props.report?.pending && (
            <td>{formatPrice(props.report?.pending?.total || 0)}</td>
          )}
        </tr>
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  width: 100%;
  min-width: 660px;
  white-space: nowrap;

  & > thead {
    position: relative;
    & > th {
      padding: 0 10px 23px 10px;
      text-align: right;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 1px;
      background: black;
      bottom: 0;
      left: 0;
    }
  }
  & td:first-child {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: left;
  }
  & td {
    padding: 0 10px;
    text-align: right;
  }
  & tr:not(:last-child) > td {
    padding-bottom: 14px;
  }
`;

const StyledButtonIcon = styled(ButtonIcon)`
  margin-left: 5px;
`;

export default SummaryTable;
