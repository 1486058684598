export interface Garage {
  garageId: string;
  externalGarageId: string;
  label: string;
  address: string;
  phone: string;
  url: string;
  longitude: string;
  latitude: string;
  capacity: number;
  permitLimit?: number | null;
  timezone: string;
}

export enum GarageDay {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export interface GarageHoursPolicy {
  id: number;
  garageId: string;
  day: GarageDay;
  start: string;
  end: string;
  cost: number;
  isClosed: boolean;
}

export enum EventParkingFeeType {
  HOURLY_RATE = 'HOURLY_RATE',
  FLAT_FEE = 'FLAT_FEE',
}

export interface GarageOptions {
  maximumDailyFee: number;
  isMaximumDailyFeeApplicable: boolean;
  eventParkingFeeType: EventParkingFeeType;
  eventParkingFee: number;
  isEventParkingFeeApplicable: boolean;
  isGracePeriodMinApplicable: boolean;
  eventBeforeGraceMin: number;
  eventAfterGraceMin: number;
  gracePeriodMin: number;
}
