import { 
  EventParkingFeeType as EventParkingFeeTypeResponse,
  GarageOptions as GarageOptionsResponse } from '@zero5/garage-api';
import { GarageHoursOpen, GarageHoursOpenDay } from '@zero5/garage-api';

import {
  EventParkingFeeType, GarageDay, GarageHoursPolicy, GarageOptions,
} from '../models/garages';

const serverDayToDay: Record<GarageHoursOpenDay, GarageDay> = {
  MONDAY: GarageDay.MONDAY,
  TUESDAY: GarageDay.TUESDAY,
  WEDNESDAY: GarageDay.WEDNESDAY,
  THURSDAY: GarageDay.THURSDAY,
  FRIDAY: GarageDay.FRIDAY,
  SATURDAY: GarageDay.SATURDAY,
  SUNDAY: GarageDay.SUNDAY,
};

const dayToServerDay: Record<GarageDay, GarageHoursOpenDay> = {
  MONDAY: GarageHoursOpenDay.MONDAY,
  TUESDAY: GarageHoursOpenDay.TUESDAY,
  WEDNESDAY: GarageHoursOpenDay.WEDNESDAY,
  THURSDAY: GarageHoursOpenDay.THURSDAY,
  FRIDAY: GarageHoursOpenDay.FRIDAY,
  SATURDAY: GarageHoursOpenDay.SATURDAY,
  SUNDAY: GarageHoursOpenDay.SUNDAY,
};

export const mapGarageHoursPolicy = (response: GarageHoursOpen): GarageHoursPolicy => ({
  id: response.id,
  garageId: response.garageId,
  day: serverDayToDay[response.day],
  start: response.start,
  end: response.end,
  cost: response.cost,
  isClosed: response.isClosed,
});

export const unmapGarageHoursPolicy = (data: GarageHoursPolicy): GarageHoursOpen => ({
  id: data.id,
  garageId: data.garageId,
  day: dayToServerDay[data.day],
  start: data.start,
  end: data.end,
  cost: data.cost,
  isClosed: data.isClosed,
});

export const mapGarageHoursPolicies = (response: Array<GarageHoursOpen>) => response.map(mapGarageHoursPolicy);

export const unmapGarageHoursPolicies = (data: Array<GarageHoursPolicy>) => data.map(unmapGarageHoursPolicy);

const eventParkingFeeServerToEventParkingFee: Record<EventParkingFeeTypeResponse, EventParkingFeeType> = ({
  HOURLY_RATE: EventParkingFeeType.HOURLY_RATE,
  FLAT_FEE: EventParkingFeeType.FLAT_FEE,
});

export const mapGarageOptions = (response: GarageOptionsResponse): GarageOptions => ({
  maximumDailyFee: response.maximumDailyFee,
  isMaximumDailyFeeApplicable: response.isMaximumDailyFeeApplicable,
  eventParkingFeeType: eventParkingFeeServerToEventParkingFee[response.eventParkingFeeType],
  eventParkingFee: response.eventParkingFee,
  isEventParkingFeeApplicable: response.isEventParkingFeeApplicable,
  eventBeforeGraceMin: response.eventBeforeGraceMin,
  eventAfterGraceMin: response.eventAfterGraceMin,
  gracePeriodMin: response.gracePeriodMin,
  isGracePeriodMinApplicable: response.isGracePeriodMinApplicable,
});
