/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getGarageOptions } from '@zero5/garage-api';

import { mapGarageOptions } from '@/api/mappings/garages';

type Response = ReturnType<typeof mapGarageOptions>;
export const queryKey: QueryKey = 'garageOptions';

const useGarageOptionsQuery = (
  options?: UseQueryOptions<Response, unknown, Response, typeof queryKey>,
) => {
  return useQuery<Response, unknown, Response, typeof queryKey>(
    queryKey,
    async () => {
      const response = await getGarageOptions();

      return mapGarageOptions(response.options);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useGarageOptionsQuery;
