/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getInvoicesReports } from '@zero5/payment-api';

import { mapRevenueInvoices } from './mappings';
import { queryGroupName } from './queryGroupName';

type Response = ReturnType<typeof mapRevenueInvoices>;
type Params = {
  page?: number;
  take?: number;
  orderBy?: 'tenantName' | 'permit' | 'event' | 'transient' | 'validation' | 'total';
  direction?: 'DESC' | 'ASC';
  from: number;
  until: number;
};
type Key = [typeof queryGroupName, typeof queryKey, Params];

export const queryKey: QueryKey = 'invoicesReports';

const useInvoicesReportsQuery = (
  params: Params,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    [queryGroupName, queryKey, params],
    async () => {
      const response = await getInvoicesReports(params);

      return mapRevenueInvoices(response);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: Infinity,
      ...options,
    },
  );
};

export default useInvoicesReportsQuery;
