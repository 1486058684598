import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '@material-ui/core';
import flow from 'lodash/flow';

import {
  CategoryCard,
  Row,
  Table,
  RowsMap,
  ButtonIcon,
  Button, TableHead,
  TableCard,
  TableContent,
} from '@zero5/ui';
import useToggle from '@zero5/ui/lib/utils/hooks/useToggle';
import vars from '@zero5/ui/lib/styles/vars';
import { DateRange } from '@zero5/ui/lib/Chart';

import { Event as EventType } from '@/api/models/events';
import useEventsQuery from '@/api/queries/useEventsQuery';
import useEventCountsQuery from '@/api/queries/useEventCountsQuery';
import useGetNearestEventQuery from '@/api/queries/useGetNearestEvent';

import { selectEventsSettings } from '@/store/selectors/settings';
import { settingsActions } from '@/store/actions/settings';

import Page from '@/components/common/page';
import Status from '@/components/common/Status';
import EventAddModal from '@/components/modals/EventAddModal';
import EventEditModal from '@/components/modals/EventEditModal';
import Role, { useFindCurrentAction } from '@/components/common/Role';

import useDateModule from '@/utils/date/useDateModule';
import { withAuth } from '@/utils/hocs/withAuth';
import { withPreloadRole, withRole } from '@/utils/hocs/withRole';
import { withLoading } from '@/utils/hocs/withLoading';
import { withGarage, withPreloadGarage } from '@/utils/hocs/withGarage';

import InfoIcon from '@/assets/icons/dots.inline.svg';

interface Props {}

const eventStatusToView: Record<string, {
  color: string;
  text: string;
}> = {

  CONFIRMED: {
    color: '#30cd9a',
    text: 'Approved',
  },  
  APPROVED_ADMIN: {
    text: 'Updated',
    color: '#FF9F1C',
  },
  REQUESTED: {
    color: vars.palette.danger,
    text: 'Pending',
  },
  REJECTED_ADMIN: {
    color: vars.palette.danger,
    text: 'Rejected Admin',
  },
  REJECTED_TENANT: {
    color: vars.palette.danger,
    text: 'Rejected Tenant',
  },
};

const Events: React.FC<Props> = () => {
  const dispatch = useDispatch();

  const { getMonthAndYear } = useDateModule();

  const [tab, setTab] = React.useState(0);
  const [currentEvent, setCurrentEvent] = useState<EventType>();
  // const [nextEvent, setNextEvent] = React.useState<string | undefined>();
  const [isEditModalOpen, closeEditModal, openEditModal] = useToggle(false);
  const [isCreateModalOpen, closeCreateModal, openCreateModal] = useToggle(false);

  const { dateRange } = useSelector(selectEventsSettings);

  const dateRangeChangeHandler = React.useCallback((newDateRange: DateRange) => {
    dispatch(settingsActions.setEventsSettings({
      dateRange: newDateRange,
    }));
  }, [dispatch]);

  // const getNumberSuffix = (num: number) => {
  //   const th = 'th';
  //   const rd = 'rd';
  //   const nd = 'nd';
  //   const st = 'st';

  //   if (num === 11 || num === 12 || num === 13) return num + th;

  //   let lastDigit = num.toString().slice(-1);

  //   switch (lastDigit) {
  //     case '1': return num + st;
  //     case '2': return num + nd;
  //     case '3': return num + rd;
  //     default:  return num + th;
  //   }
  // };

  const findCurrentAction = useFindCurrentAction();
  const { data: events, isLoading, refetch, isRefetching } = useEventsQuery({ dateRange, tab }, { enabled: false });
  const { data: dataCounts } = useEventCountsQuery({ enabled: findCurrentAction('event:stats') !== 'h' });
  const { data: nearestEvent } = useGetNearestEventQuery({ enabled: findCurrentAction('event:stats') !== 'h' });

  useEffect(() => {
    if (dateRange && dateRange.start && dateRange.end) {
      refetch();
    }
  }, [dateRange, tab]);

  useEffect(() => {
    if (findCurrentAction('event:upcomingEvent') === 'h') {
      setTab(1);
    }
    if (findCurrentAction('event:requestEvent') === 'h') {
      setTab(0);
    }
  }, []);

  const cardsData = [
    {
      title: 'Next Event',
      data: nearestEvent,
      widgetId: 'event:stats:nextEvent',
    },
    {
      title: 'Scheduled Events',
      data: dataCounts !== undefined ? dataCounts.scheduledCount : '-',
      subtext: getMonthAndYear(new Date()),
      widgetId: 'event:stats:scheduledEvent',
    },
    {
      title: 'Upcoming Events',
      data: dataCounts !== undefined ? dataCounts.upcomingCount : '-',
      subtext: getMonthAndYear( new Date()),
      widgetId: 'event:stats:upcomingEvent',
    },
    {
      title: 'Pending Requests',
      data: dataCounts !== undefined ? dataCounts.pendingCount : '-',
      subtext: getMonthAndYear(new Date()),
      widgetId: 'event:stats:pendingRequests',
    },
  ];

  const eventsRowsMap = React.useMemo(() => {
    const eventsRows: RowsMap<EventType> = [
      {
        title: 'Name',
        id: 'name',
        data: ({ name }) => name,
      },
      {
        title: 'Host',
        id: 'host',
        data: ({ host }) => host,
      },
      {
        title: 'Date',
        id: 'date',
        data: ({ date }) => (
          `${date}`
        ),
      },
      {
        title: 'Time',
        id: 'time',
        data: ({ time }) => time,
      },
      {
        title: 'Event Status',
        id: 'status',
        data: ({ status }) => (
            <Status color={eventStatusToView[status].color}>
              {eventStatusToView[status].text}
            </Status>
        ),
      },
      {
        title: 'Reserved Spots',
        id: 'reservedSpots',
        data: ({ reservedSpots }) => reservedSpots,
      },

    ];
    if (findCurrentAction('event:updateEvent') === 'r' || findCurrentAction('event:updateEvent') === 'w') {
      eventsRows.push({
        title: 'Info',
        id: 'info',
        disableSorting: true,
        data: (event) => (
          <StyledButtonIcon
            Icon={InfoIcon}
            onClick={() => {
              setCurrentEvent(event);
              openEditModal();
            }}
            data-test="event-info-icon"
          />
        ),
      });
    }
    return eventsRows;
  }, []);

  const changeTabHandler = React.useCallback((event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  }, []);

  const isEventsLoading = useMemo(()=> isLoading || isRefetching, [isLoading, isRefetching]);

  return (
      <Page>
        <Role widgetId="event:stats" action="r">
          <CardsWrapper>
            {cardsData.map(({ widgetId, ...props }) => (
                <Role widgetId={widgetId} action="r">
                  <CategoryCard key={props.title} {...props} />
                </Role>
            ))}
          </CardsWrapper>
        </Role>
        <Role widgetId="event" action="w">
          <StyledRow justifyContent="flex-end">
            <Button
              color="primary"
              variant="contained"
              onClick={openCreateModal}
              data-test="open-create-modal-event"
            >
              Create Event
            </Button>
          </StyledRow>
        </Role>
        {!(findCurrentAction('event:requestEvent') === 'h'
            && findCurrentAction('event:upcomingEvent') === 'h') && (
            <>
              <StyledTabs
                  indicatorColor="primary"
                  textColor="primary"
                  value={tab}
                  onChange={changeTabHandler}
              >
                <Role widgetId="event:upcomingEvent" action="r">
                  <Tab label="Upcoming Events" disabled={isEventsLoading} />
                </Role>
                <Role widgetId="event:requestEvent" action="r">
                  <Tab label="Event Requests"  disabled={isEventsLoading}  />
                </Role>
              </StyledTabs>
              <Role widgetId="event" action="r">
                <TableCard>
                  <TableHead
                    // title="Event List"
                    // setDateRange={dateRangeChangeHandler}
                    // reverse
                    // dateRange={dateRange}
                    // isHideShortcuts
                    title="Event List"
                    setDateRange={dateRangeChangeHandler}
                    reverse
                    dateRange={dateRange}
                    isHideShortcuts
                    allowFullRangeOfDates
                  />
                  <TableContent
                      // isLoading={isLoading || (!events?.length && isRefetching)}
                      isLoading={isEventsLoading}
                      data={events || []}
                  >
                    <StyledTable
                        rowsMap={eventsRowsMap}
                        // data={events!}
                        // isLoading={isRefetching}
                        data={events || []}
                        data-test="events-list"
                    />
                  </TableContent>
                </TableCard>
              </Role>
            </>
        )}
        <Role widgetId="event" action="w">
          <EventAddModal open={isCreateModalOpen} onClose={closeCreateModal} />
        </Role>
        <Role widgetId="event" action="r">
          <EventEditModal
              open={isEditModalOpen}
              currentEvent={currentEvent!}
              onClose={closeEditModal}
          />
        </Role>
      </Page>
  );
};

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  margin-bottom: 35px;

  @media (max-width: 1050px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;

const StyledTable = styled(Table)`
  margin-top: 10px;
  & thead.MuiTableHead-root span {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }

` as typeof Table;

const StyledButtonIcon = styled(ButtonIcon)`
  margin: 0 auto;
`;

const StyledRow = styled(Row)`
  margin-bottom: 20px;
`;

const StyledTabs = styled(Tabs)`
  margin-bottom: 20px;
`;

export default flow(
  withGarage,
  withRole('events'),
  withPreloadRole,
  withPreloadGarage,
  withAuth(),
  withLoading(),
)(Events);
