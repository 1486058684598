import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { deletePermitType } from '@zero5/permit-api';

import { handleError } from '@/utils/handleError';

import { queryKey as permitListQuery } from './useGetPermitTypeListQuery';

type Response = unknown;

export const queryKey = 'deletePermitType';

const useDeletePermitTypeMutation = (
  options?: UseMutationOptions<Response, Error, number, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    queryKey,
    async (id: number) => {
      await deletePermitType(id);
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(permitListQuery);
      },
      onError: (error) => handleError(error),
    },
  );
};

export default useDeletePermitTypeMutation;
