import React from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';

import { Button, Input, ModalProps } from '@zero5/ui';

import useResetPasswordMutation from '../../../api/mutations/useResetPasswordMutation';
import PasswordInfo from '../../PasswordInfo';

import { validationSchema } from './validationSchema';

interface Props extends Omit<ModalProps, 'children'> {
  email: string;
}

interface ContentProps extends Pick<Props, 'onClose'> {
  email: string;
}

interface FormikState{
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const formikInitialState: FormikState = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const Content: React.FC<ContentProps> = ({ onClose, email }) => {
  const resetPassword = useResetPasswordMutation();
  const formik = useFormik<FormikState>({
    initialValues: formikInitialState,
    validationSchema,
    onSubmit: async (values) => {
      const formData = {
        email,
        oldPassword: values.oldPassword,
        password: values.newPassword,
      };
      return resetPassword.mutateAsync(formData).then(() => onClose());
    },
  });

  return (
    <ChangePasswordForm onSubmit={formik.handleSubmit}>
      <StyledInput
        type="password"
        label="Password"
        name="oldPassword"
        onChange={formik.handleChange}
        error={Boolean(formik.touched.oldPassword && formik.errors.oldPassword)}
        helperText={formik.errors.oldPassword}
      />
      <StyledInput
        type="password"
        label="New password"
        name="newPassword"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={Boolean(formik.touched.newPassword && formik.errors.newPassword)}
        helperText={formik.errors.newPassword}
      />
      <StyledInput
        type="password"
        label="Confirm password"
        name="confirmPassword"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
        helperText={formik.errors.confirmPassword}
      />
      <PasswordInfo />
      <ButtonsWrapper>
        <StyledButton
          variant="contained"
          color="primary"
          type="submit"
          loading={formik.isSubmitting}
        >
          SAVE
        </StyledButton>
        <StyledButton variant="text" color="primary" onClick={onClose}>
          {formik.dirty ? 'cancel' : 'close'}
        </StyledButton>
      </ButtonsWrapper>
    </ChangePasswordForm>
  );
};

const ChangePasswordForm = styled.form`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled(Input)`
  margin-bottom: 15px;
`;

const StyledButton = styled(Button)`
  width: 80px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;

  ${StyledButton}:last-child {
    margin-left: 10px;
  }
`;

export default Content;
