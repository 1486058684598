// /* eslint-disable max-len */
import { useMutation, UseMutationOptions } from 'react-query';

import {
  getInvoiceReportDetailsCsv,
} from '@zero5/payment-api';

type Response = string;
type Params = {
  transactionId: string;
};

const useInvoiceReportDetailsCsvMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  return useMutation(
    'getInvoiceDetailsReportCsv',
    async (params) => {
      const response = await getInvoiceReportDetailsCsv(params.transactionId);

      return response.report;
    },
    options,
  );
};

export default useInvoiceReportDetailsCsvMutation;
