import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Switch, FormControlLabel } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import flow from 'lodash/flow';

import {
  Button, LoadingIndicator, Checkbox, TableCard,
} from '@zero5/ui';
import { DeviceActionFrontType } from '@zero5/garage-api';

import { ActionDeviceRequest, Device } from '@/api/models/devices';
import useSendAllDevicesActionQuery from '@/api/device/useSendAllDevicesActionQuery';
import useResetSettingsMutation from '@/api/device/useResetSettingsMutation';
import useSendDeviceMutation from '@/api/device/useSendDeviceAction';

import { changeDeviceAction, deviceActions, getDevices } from '@/store/actions/device';
import { selectDeviceList, selectDeviceState } from '@/store/selectors/device';
import { RequestState } from '@/store/reducers/common';

import Page from '@/components/common/page';
import GroupedTable, { RowsMap } from '@/components/GroupedTable';
import Status from '@/components/common/ButtonStatus';
import OpenAllGatesModal from '@/components/modals/OpenAllGatesModal';
import LoaderToggle from '@/components/common/LoaderToggle';
import Role, { useFindCurrentAction } from '@/components/common/Role';
import TableHead from '@/components/common/TableHead';

import { withAuth } from '@/utils/hocs/withAuth';
import { withRole } from '@/utils/hocs/withRole';
import { withLoading } from '@/utils/hocs/withLoading';

interface Props {}

const statusToView: Record<string, {
  text: string;
  color: string;
  background: string;
}> = {
  good: {
    text: 'Good',
    // color: '#05690D',
    // background: '#D7F7C2',
    color: '#30CD9A',
    background: '#97E6CB4D',
  },
  error: {
    text: 'Error',
    color: '#FF9F1C',
    background: '#F8E5B9',
    // color: '#FF9F1C',
    // background: '#F8E5B9',
  },
  down: {
    text: 'Down',
    color: '#EF5164',
    background: '#EF51644D',
  },
  empty: {
    text: 'N/A',
    color: '#545A69',
    background: '#D1D1D1',
  },
};

const defaultColor = '#30CD9A';
const disabledColor = '#dddddd';
// const defaultColor = '#05690D';

const ParkingSettings: React.FC<Props> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [allGatesAction, setAllGatesAction] = useState<DeviceActionFrontType>();
  const [modalTextButton, setModalTextButton] = useState<string>('');
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>('');
  const dispatch = useDispatch();
  const deviceList = useSelector(selectDeviceList);
  const deviceState = useSelector(selectDeviceState);
  const findCurrentAction = useFindCurrentAction();
  const { refetch } = useSendAllDevicesActionQuery(DeviceActionFrontType.GATE_STATUS, { enabled: false });
  const { mutateAsync: resetSettings, isLoading: isResetLoading } = useResetSettingsMutation();
  const { mutateAsync: sendDeviceAction, isLoading: isSendDeviceLoading } = useSendDeviceMutation();


  const resetSettingHandler = (deviceId: string, action: boolean) => ()=> {
    setSelectedDeviceId(deviceId);
    resetSettings({ deviceId, action });
    dispatch(deviceActions.setDownLockInDeviceList(action, deviceId));
  };

  const sendDeviceHandler = (deviceId: string, action: DeviceActionFrontType ) => ()=> {
    setSelectedDeviceId(deviceId);
    sendDeviceAction({ deviceId, action });
  };

  useEffect(() => {
    refetch();
    dispatch(getDevices());
  }, []);

  const updateAction = (device: Device) => () => {
    const request: ActionDeviceRequest = {
      isOpen: device.gateControl,
      deviceId: device.deviceId,
    };
    setSelectedDeviceId(device.deviceId);
    dispatch(changeDeviceAction(request));
    dispatch(deviceActions.setGateControlInDeviceList(!device.gateControl, device.deviceId, false));
  };

  const updateLockAction = (device: Device) => () => {
    const request: ActionDeviceRequest = {
      isLocked: !device.gateLock,
      deviceId: device.deviceId,
    };
    dispatch(changeDeviceAction(request));
    dispatch(deviceActions.setGateLockInDeviceList(!device.gateLock, device.deviceId));
  };

  const getSwitchTextColor = (gateControl: boolean, isLocked: boolean, gateLpr: string) => {
    if (isLocked || gateLpr === 'error') {
      return disabledColor;
    }
    if (!gateControl) {
      return defaultColor;
    }
    return undefined;
  };

  const isToggleDisabled = (isLocked: boolean, gateLpr: string) => {
    return !!(isLocked || gateLpr === 'error');
  };

  const deviceRowsMap = React.useMemo(() => {
    const deviceRows: RowsMap<Device> = [
      {
        title: 'Layout',
        data: ({ layout }) => layout,
        key: () => 'layout',
      },
      {
        title: 'Entry / Exit',
        data: ({ entryExit }) => entryExit,
        key: () => 'entryExit',
        HeadCellProps: {
          style: {
            minWidth: '120px',
          },
        },
      },
      {
        title: 'Gate',
        data: ({ gateLPR }) => (
          <Status
            disabled
            textColor={statusToView[gateLPR].color}
            background={statusToView[gateLPR].background}
          >
            {statusToView[gateLPR].text}
          </Status>
        ),
        key: () => 'gateLPR',
      },
      {
        title: 'Pay Station',
        data: ({ aps }) => (
          <Status
            disabled
            textColor={statusToView[aps].color}
            background={statusToView[aps].background}
          >
            {statusToView[aps].text}
          </Status>
        ),
        key: () => 'ps',
      },
      {
        title: 'Camera(Rear)',
        data: ({ mLPR }) => (
          <Status
            disabled
            textColor={statusToView[mLPR].color}
            background={statusToView[mLPR].background}
          >
            {statusToView[mLPR].text}
          </Status>
        ),
        key: () => 'CR',
      },
      {
        title: 'LPR Lock',
        data: ({ downLock, deviceId }) => (
          <StyledFormControlLabel
            control={(
              <Checkbox
                checked={!downLock}
                color="primary"
                disabled={findCurrentAction('parkingSettings:sendDeviceAction') !== 'r'}
                onClick={resetSettingHandler(deviceId, !downLock)}
              />
          )}
            label="Lock"
          />
        ),
        key: () => 'lock',
      },
      {
        title: 'Gate Reset',
        data: ({ deviceId }) => (
        <StyledButton
          color="primary"
          loading={ selectedDeviceId === deviceId && isSendDeviceLoading}
          disabled={findCurrentAction('parkingSettings:sendDeviceAction') === 'r'}
          onClick={sendDeviceHandler(deviceId, DeviceActionFrontType.SYSTEM_RESET)}
          variant="text" >
            Reset
        </StyledButton>
        ),
        key: () => 'gateReset',
      },
    ];
    if (findCurrentAction('parkingSettings:sendDeviceAction') !== 'h') {
      deviceRows.splice(2, 0,
        {
          title: 'Gate Control',
          data: (device: Device) => {
            return (
              <SwitchWrapper data-test="parking-settings-gate-control">
                <StyledSpan
                  color={getSwitchTextColor(device.gateControl, device.gateLock, device.gateLPR)}
                  fontWeight={device.gateControl ? '300' : '600'}
                >
                  Open
                </StyledSpan>
                <StyledSwitch
                  disabled={findCurrentAction('parkingSettings:sendDeviceAction') !== 'w'
                  || isToggleDisabled(device.gateLock, device.gateLPR)}
                  checked={device.gateControl}
                  icon={(
                    <LoaderToggle
                      isLoading={selectedDeviceId === device.deviceId
                  && deviceState.gateState === RequestState.LOADING}
                      disabled={isToggleDisabled(device.gateLock, device.gateLPR)}
                    />
                  )}
                  checkedIcon={(
                    <LoaderToggle
                      isLoading={selectedDeviceId === device.deviceId
                  && deviceState.gateState === RequestState.LOADING}
                      disabled={isToggleDisabled(device.gateLock, device.gateLPR)}
                    />
                  )}
                  inputProps={{ 'aria-label': 'Switch with loading state' }}
                  onClick={updateAction(device)}
                />
                <StyledSpan
                  color={getSwitchTextColor(!device.gateControl, device.gateLock, device.gateLPR)}
                  fontWeight={device.gateControl ? '600' : '300'}
                >
                  Close
                </StyledSpan>
              </SwitchWrapper>
            );
          },
          key: () => 'other',
        });
      deviceRows.splice(3, 0, {
        title: 'Gate Uplock',
        data: (device: Device) => {
          return (
            <StyledFormControlLabel
              disabled={device.gateLPR === 'error'}
              control={(
                <Checkbox
                  checked={device.gateLock}
                  color="primary"
                  disabled={findCurrentAction('parkingSettings:sendDeviceAction') !== 'r'}
                  onClick={updateLockAction(device)}
                  data-test="parking-settings-uplock"
                />
             )}
              label="Uplock"
            />

          );
        },
        key: () => 'gateUpLock',
        HeadCellProps: {
          style: {
            minWidth: '165px',
          },
        },
      });
      // deviceRows.splice(4, 0, {
      //   title: 'LPR Lock',
      //   data: ({ downLock, deviceId }:Device) => {
      //     return (
      //       <StyledFormControlLabel
      //         control={(
      //           <Checkbox
      //             checked={!downLock}
      //             color="primary"
      //             disabled={findCurrentAction('parkingSettings:sendDeviceAction') === 'r'}
      //             onClick={resetSettingHandler(deviceId, !downLock)}
      //           />
      //        )}
      //         label="Lock"
      //       />
      //     );
      //   },
      //   key: () => 'downLock',
      //   HeadCellProps: {
      //     style: {
      //       minWidth: '165px',
      //     },
      //   },
      // });
    }
    return deviceRows;
  }, [deviceList, deviceState.gateState, deviceState.gateLockState, isResetLoading, isSendDeviceLoading]);

  const closeOpenAllGatesModalHandler = () => {
    setIsOpen(false);
  };
  const openAllGatesModalHandler = (textButton: string, action: DeviceActionFrontType) => () => {
    setIsOpen(true);
    setModalTextButton(textButton);
    setAllGatesAction(action);
  };
  return (
    <Page>
      <Role widgetId="parkingSettings:allGatesAction" action="w">
        <StyledDiv>
          {/* <Button
            color="primary"
            onClick={openAllGatesModalHandler('UNLOCK', DeviceActionFrontType.GATE_UNLOCK)}
            style={{ marginRight: '10px' }}
            variant="contained"
          >
            UNLOCK ALL GATES
          </Button> */}
          <Button
            color="primary"
            onClick={openAllGatesModalHandler('OPEN & LOCK', DeviceActionFrontType.GATE_UPLOCK)}
            variant="contained"
            data-test="parking-settings-uplock-all-gates"
          >
            UPLOCK All Gates
          </Button>
        </StyledDiv>
      </Role>
      {deviceState.deviceState !== RequestState.LOADED ? <StyledLoadingIndicator /> : (
        <>
          <StyledTableHead
            title="Device Status"
          />
          <TableCard>
            <StyledWrapper>
              <GroupedTable rowsMap={deviceRowsMap} data={deviceList} groupKey="layout" />
            </StyledWrapper>
          </TableCard>
        </>
      )}
      <OpenAllGatesModal
        open={isOpen}
        action={allGatesAction!}
        textButton={modalTextButton}
        onReject={closeOpenAllGatesModalHandler}
      />
    </Page>
  );
};

const StyledWrapper = styled.div`
overflow-x: auto;
width: 100%;
`;

const StyledTableHead = styled(TableHead)`
margin-bottom: 10px;
`;

const StyledDiv = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
margin-bottom: 35px;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

const StyledButton = styled(Button)`
  text-transform: none;
  font-size: 14px;
  font-weight: 300;
`;

const StyledSwitch = styled(Switch)`
 color: #98E6CD;
 .MuiSwitch-track {
   background-color: #98E6CD;
 }
 .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #98E6CD;
}
 .MuiSwitch-switchBase{
   color:#05690D;
 }
`;
const SwitchWrapper = styled.div`
display: flex;
justify-content:center;
justify-items: center;
flex-direction: row;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 0px;
  margin-right: 0px;
 .MuiTypography-body1 {
    font-weight: 300;
    margin-left: 5px;
}
`;

const StyledSpan = styled.span<{ color?: string; fontWeight?: string; }>`
font-style: normal;
font-weight: ${({ fontWeight }) => fontWeight};
color: ${({ color }) => color};
font-size: 14px;
line-height: 22px;
display: flex;
align-items: center;
text-align: center;
`;

export default flow(
  withRole('parkingSettings'),
  withAuth(),
  withLoading(),
)(ParkingSettings);
