import { useMutation, UseQueryOptions } from 'react-query';

import { GarageHoursOpen, updateHoursOpen } from '@zero5/garage-api';

import { GARAGE_HOURS_OPEN_QUERY, GARAGE_QUERY, GARAGE_UPDATE_GARAGE_HOURS } from '@/api/garage/constants';

import { handleError } from '@/utils/handleError';
import queryClient from '@/utils/queryClient';

type Params = {
  hoursOpen: GarageHoursOpen[];
};

const useUpdateGarageHoursOpenMutation = (
  options: UseQueryOptions = {},
) => {
  return useMutation(
    [GARAGE_QUERY, GARAGE_UPDATE_GARAGE_HOURS],
    async (params: Params) => {
      const response = await updateHoursOpen(params);
      return response;
    },
    {
      onError: async (error) => {
        handleError(error);
        await queryClient.refetchQueries(GARAGE_HOURS_OPEN_QUERY);
      },
      onSuccess: () => queryClient.refetchQueries([GARAGE_QUERY, GARAGE_HOURS_OPEN_QUERY]),
      ...options,
    },
  );
};

export default useUpdateGarageHoursOpenMutation;
