/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import {
  getPermitReceiptAggregatedCount,
} from '@zero5/permit-api';

import { handleError } from '@/utils/handleError';

import { queryGroupName } from './queryGroupName';
import { mapReceiptAggregatedCount } from './mappings';

type Response = ReturnType<typeof mapReceiptAggregatedCount>;
type Params = {
  from: Date;
  to: Date;
  aggregationPeriod: 'daily' | 'weekly' | 'monthly';
};

export const queryKey: QueryKey = 'getReceiptAggregatedCount';

type Key = [typeof queryGroupName, typeof queryKey, Params];

const useGetReceiptAggregatedCount = (
  params: Params,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery<Response, unknown, Response, Key>(
    [queryGroupName, queryKey, params],
    async () => {
      const response = await getPermitReceiptAggregatedCount({
        fromDate: params.from.getTime(),
        toDate: params.to.getTime(),
        aggregationPeriod: params.aggregationPeriod,
      });

      return mapReceiptAggregatedCount(response);
    },
    {
      ...options,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      staleTime: 10 * 60 * 1000,
      onError: (e) => handleError(e),
    },
  );
};

export default useGetReceiptAggregatedCount;
