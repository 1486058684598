import { createActionCreators } from 'immer-reducer';

import { SettingsReducer } from '@/store/reducers/settings';

export const settingsActions = createActionCreators(SettingsReducer);

export type SettingsActions =
  | ReturnType<typeof settingsActions.setHomeSettings>
  | ReturnType<typeof settingsActions.setEventsSettings>
  | ReturnType<typeof settingsActions.setTenantsSettings>
  | ReturnType<typeof settingsActions.setUsersSettings>
  | ReturnType<typeof settingsActions.setPermitSettings>
  | ReturnType<typeof settingsActions.setRevenueSettings>
  | ReturnType<typeof settingsActions.setTrafficSettings>
  | ReturnType<typeof settingsActions.setValetSettings>
  | ReturnType<typeof settingsActions.setValidationHistorySettings>;
