import { useQuery, UseQueryOptions } from 'react-query';

import { ownerGetCompanyGarageSigns } from '@zero5/tenant-api';

type CompanyGarageSigns = { validationReceipt: string | null;
  created: number;
  updated: number; };
type Response = { signs: CompanyGarageSigns; };

const useCompanyGarageSignsQuery = (
  options?: UseQueryOptions<Response, unknown, Response, string>,
) => {
  return useQuery<Response, unknown, Response, string>(
    'getCompanyGarageSign',
    async () => {
      const response = await ownerGetCompanyGarageSigns();
      return response;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useCompanyGarageSignsQuery;
