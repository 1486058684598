import React from 'react';
import Select, { components } from 'react-select';

import vars from '@zero5/ui/lib/styles/vars';

import SelectArrowSvg from '@/assets/icons/select-arrow.inline.svg';

interface Props {
  setHours: (hours: number) => void;
}

const HourSelector: React.FC<Props> = ({ setHours }) => {
  return (
    <Select
      menuPlacement="top"
      placeholder="Hours"
      onChange={(value) => setHours(value!.value)}
      components={{
        DropdownIndicator: (props) => {
          return (
            <components.DropdownIndicator {...props}>
              <SelectArrowSvg />
            </components.DropdownIndicator>
          );
        },
      }}
      options={[
        { value: 1, label: '1 hour' },
        { value: 2, label: '2 hours' },
        { value: 6, label: '6 hours' },
        { value: 12, label: '12 hours' },
      ]}
      styles={{
        indicatorSeparator: (styles) => ({
          ...styles,
          backgroundColor: vars.palette.primary,
        }),
        control: (styles) => ({
          ...styles,
          border: 0,
          boxShadow: 'none',
          width: 160,
          height: 50,
        }),
        valueContainer: (styles) => ({
          ...styles,
          justifyContent: 'center',
        }),
        container: (styles) => ({
          ...styles,
          border: `1px solid ${vars.palette.primary}`,
          borderColor: vars.palette.primary,
          borderRadius: 5,
          cursor: 'pointer',
        }),
        placeholder: (styles) => ({
          ...styles,
          color: '#ACB3BF',
          fontWeight: 300,
        }),
        dropdownIndicator: (styles) => ({
          ...styles,
          width: 54,
          justifyContent: 'center',
        }),
      }}
    />
  );
};

export default HourSelector;
