import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import {
  ConfirmationModal, Layout, /* , SearchInput */
  Skeleton,
} from '@zero5/ui';
import useToggle from '@zero5/ui/lib/utils/hooks/useToggle';
import { LinkType } from '@zero5/ui/lib/Navigation';

import user from '@/api/user';
import useUserCompanyQuery from '@/api/queries/useUserCompanyQuery';
import useSelfUserQuery from '@/api/queries/useSelfUserQuery';

import ChangePasswordModal from '@/components/modals/changePasswordModal';
import LabeledIcon from '@/components/common/LabeledIcon';
import { useFindCurrentAction } from '@/components/common/Role';
import { privateRouteList } from '@/components/common/page/routeList';

import SignOutIcon from '@/assets/icons/sign_out.inline.svg';
import PasswordIcon from '@/assets/icons/password.inline.svg';

interface Props {
  children: React.ReactNode;
  nestedLink?: NestedLink;
}

interface NestedLink {
  title: string;
  nestedTitle?: string;
}

const Page: React.FC<Props> = ({ children, nestedLink }) => {
  const history = useHistory();
  const findCurrentAction = useFindCurrentAction();
  const { data: selfUserData } = useSelfUserQuery();

  const {
    data: userCompanyData,
    isLoading: userCompanyLoading,
  } = useUserCompanyQuery();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const signOutHandler = React.useCallback(async () => {
    user.signOut();
    history.push('/login');
  }, [history]);

  const closeSignOutHandler = React.useCallback(async () => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleOpenModal = React.useCallback(async () => {
    setIsOpen(true);
  }, [setIsOpen]);

  const [
    isChangePasswordModalOpen,
    closeChangePasswordModal,
    openChangePasswordModal,
  ] = useToggle(false);

  const signOutItem: LinkType = {
    title: 'Sign Out',
    Icon: SignOutIcon,
    onClick: handleOpenModal,
  };

  const navigationLinks: Array<LinkType> = [
    ...privateRouteList
      .map(({ widgetId, ...rest }) => {
        const isRouteAccessible = findCurrentAction(widgetId) !== 'h';

        return isRouteAccessible ? { ...rest } : null;
      })
      .filter((item) => Boolean(item)) as Array<LinkType>,
    signOutItem,
  ];

  const [navigationLinkList, setNavigationLinkList] = useState(navigationLinks);

  useEffect(() => {
    if (nestedLink) {
      const res = navigationLinks.map((link) => {
        if (link.title === nestedLink?.title) {
          link.nestedLink = nestedLink.nestedTitle;
        }
        return link;
      });
      setNavigationLinkList(res);
    }
  }, [nestedLink]);

  return (
    <>
      <Layout
        navigationHeader="Home"
        isPageNavigation
        navigationLinks={navigationLinkList}
        avatar={{
          name: selfUserData?.user.firstName || '',
          src: 'avatar_src_mock',
        }}
        logoItem={(
          <LogoItem companyLength={userCompanyData?.name.length || 0}>
            {userCompanyLoading ? (
              <Skeleton variant="text" width="60px" />
            ) : userCompanyData?.name}
          </LogoItem>
        )}
        userMenu={(
          <MainPopperWrapper>
            <LabeledIcon
              Icon={PasswordIcon}
              label="Change Password"
              color="#bcbec0"
              onClick={openChangePasswordModal}
            />
            <LabeledIcon
              Icon={SignOutIcon}
              label="Sign Out"
              color="#bcbec0"
              onClick={handleOpenModal}
            />
          </MainPopperWrapper>
        )}
        // headerContent={(
        //   <HeaderContent>
        //     <StyledSearchInput />
        //   </HeaderContent>
        // )}
      >
        <>
          {children}
        </>
      </Layout>
      <ChangePasswordModal
        open={isChangePasswordModalOpen}
        onClose={closeChangePasswordModal}
        email={selfUserData?.user.email || ''}
      />
      <ConfirmationModal
        open={isOpen}
        onAccept={signOutHandler}
        onReject={closeSignOutHandler}
        title="Want to Leave?"
        text="Please confirm. You will be redirected to the login page."
      />
    </>
  );
};

// const HeaderContent = styled.div`
//   display: flex;
//   justify-content: center;
// `;

// const StyledSearchInput = styled(SearchInput)`
//   max-width: 400px;
//   width: 100%;

//   @media (max-width: 750px) {
//     display: none;
//   }
// `;

const MainPopperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
`;

const LogoItem = styled.div<{ companyLength: number; }>`
  ${({ companyLength }) => (companyLength < 18 ? 'font-size: 16px;' : 'font-size: 14px;')}
  line-height: 1;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
`;

export default Page;
