import React from 'react';
import styled from 'styled-components';
import { Icon as IconType } from 'react-feather';

interface Props {
  Icon: IconType;
  color?: string;
  label?: string;
  width?: string;
  className?: string;
  onClick?: () => void;
}

const LabeledIcon: React.FC<Props> = ({
  Icon, color, label, className, onClick, width = '12px',
}) => {
  return (
    <Wrapper onClick={onClick} className={className}>
      <IconWrapper>
        <Icon fill={color} width={width} />
      </IconWrapper>
      {label && (
        <Label>{label}</Label>
      )}
    </Wrapper>
  );
};

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: transparent;
  
`;

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;

  &:hover, &:focus {
    color: #393F49;
    outline: none;

    ${IconWrapper} {
      background-color: #f8f9fa;

      svg {
        fill: #393F49;
      }
    }
  }
`;

const Label = styled.div`
  display: block;
  margin-left: 15px;
  white-space: nowrap;
  font-size: 12px;
  color: #393F49;
`;

export default LabeledIcon;
