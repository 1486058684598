import React from 'react';

import useGetGarageQuery from '@/api/garage/useGetGarageQuery';

import { setGarage } from '../setGarage';

export const withPreloadGarage = <P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P> => (
    props,
  ) => {
    useGetGarageQuery({
      onSuccess: (data) => {
        setGarage(data);
      },
    });
    
    return <Component {...props} />;
  };


export const withGarage = <P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P> => (
    props,
  ) => {
    useGetGarageQuery({
      suspense: true,
      onSuccess: (data) => {
        setGarage(data);
      },
    });
  
    return <Component {...props} />;
  };
  