import { LinkType } from '@zero5/ui/lib/Navigation';

import PriceIcon from '@controlCenter/assets/icons/price_square.inline.svg';

import HomeIcon from '@/assets/icons/home.inline.svg';

type RouteType = {
  path: string;
};

export const privateRouteList: Array<LinkType & RouteType & {
  widgetId: string;
}> = [
  {
    title: 'Entry Activities',
    Icon: HomeIcon,
    path: '/control-center/entry-activities-list',
    widgetId: 'controlCenter:entryActivityList',
    categoryName: 'Customer Service',
  },
  {
    title: 'Search',
    Icon: PriceIcon,
    path: '/control-center/issue-list',
    widgetId: 'controlCenter:issueList',
    categoryName: 'Customer Service',
  },
];
