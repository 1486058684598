import React from 'react';
import styled, { css } from 'styled-components';

import {
  Button,
  LoadingIndicator,
  Row,
  RowsMap,
  SimpleRowsMap,
  SimpleTable,
  Skeleton,
  Table,
} from '@zero5/ui';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';

import {
  Invoice,
  InvoiceReportSummary,
  InvoiceReportDetail,
} from '@/api/revenue/models';
import useInvoiceSummarySummaryQuery from '@/api/revenue/useInvoiceSummaryQuery';
import useInvoiceReportDetailsQuery from '@/api/revenue/useInvoiceReportDetailsQuery';

import ExportButton from '../components/ExportButton';
import {
  BigText,
  // detailsTableStyles,
  GarageNameRow,
  MediumLevelTitle,
  RowWithOffset,
  SummarySection,
  TableHiddenScroll,
  TopLevelTitle,
  TransactionsTable,
} from '../components/styled';
import InvoiceStatus from '../components/InvoiceStatus';

interface Props {
  invoice: Invoice;
  onClose: () => void;
  onExport: () => void;
  isExporting?: boolean;
}

const InvoiceReport: React.FC<Props> = ({
  invoice,
  onClose,
  onExport,
  isExporting,
}) => {
  const [invoiceDetailsPage, setInvoiceDetailsPage] = React.useState(0);
  const [invoiceDetailsRowsPerPage, setInvoiceDetailsRowsPerPage] = React.useState(10);

  const { data: invoiceSummaryData, isLoading: invoiceSummaryLoading } = useInvoiceSummarySummaryQuery({
    transactionId: invoice.transactionId,
  });
  const {
    data: invoiceDetailsData,
    isLoading: invoiceDetailsLoading,
    isFetching: invoiceDetailsFetching,
  } = useInvoiceReportDetailsQuery({
    transactionId: invoice.transactionId,
    page: invoiceDetailsPage + 1,
    take: invoiceDetailsRowsPerPage,
  });

  const summaryRowsMap = React.useMemo<SimpleRowsMap<InvoiceReportSummary>>(() => [
    {
      title: 'Permit Parking Fee',
      data: ({ summary }) => formatPrice(summary.permit.amount),
    },
    {
      title: 'Number of Permits',
      data: ({ summary }) => summary.permit.count,
    },
    {
      title: 'Validation Parking Fee',
      data: ({ summary }) => formatPrice(summary.validation.amount),
    },
    {
      title: 'Number of Validations',
      data: ({ summary }) => summary.validation.count,
    },
    {
      title: 'Event Parking Fee',
      data: ({ summary }) => formatPrice(summary.event.amount),
    },
    {
      title: 'Number of Events',
      data: ({ summary }) => summary.event.count,
    },
  ], []);

  const billingRowsMap = React.useMemo<SimpleRowsMap<InvoiceReportSummary>>(() => [
    {
      title: 'Payment Method',
      data: ({ paymentDetails }) => paymentDetails.method,
    },
    {
      title: 'Payment Status',
      data: ({ paymentDetails }) => <InvoiceStatus status={paymentDetails.status} />,
    },
    {
      title: 'Autopay Cycle',
      data: ({ paymentDetails }) => paymentDetails.cycle,
    },
    {
      title: 'Statement Period',
      data: ({ paymentDetails }) => paymentDetails.period,
    },
  ], []);

  const invoiceDetailsRowsMap = React.useMemo<RowsMap<InvoiceReportDetail>>(() => [
    {
      title: 'Date',
      id: 'date',
      data: ({ date }) => date,
    },
    {
      title: 'Type',
      id: 'transactionType',
      data: ({ transactionType }) => transactionType,
    },
    {
      title: 'Account',
      id: 'account',
      data: ({ account }) => account,
    },
    {
      title: 'Note',
      id: 'note',
      disableSorting: true,
      data: ({ note }) => note,
    },
    {
      title: 'Amount',
      id: 'amount',
      data: ({ amount }) => formatPrice(amount),
    },
    {
      title: 'Total Amount',
      id: 'total',
      data: ({ total }) => formatPrice(total),
    },
  ], []);

  return (
    <>
      <RowWithOffset>
        <TopLevelTitle>Invoice</TopLevelTitle>
        <ExportButton onClick={onExport} isExporting={isExporting} data-test="invoice-modal-export-btn"/>
      </RowWithOffset>

      <GarageNameRow>
        <BigText>{invoice.tenantName}</BigText>
        <TopLevelTitle>
          {/* {formatTimeStampToString(date, 'MMMM, yyyy')} */}
          {invoiceSummaryLoading ? (
            <Skeleton variant="text" width="100px" />
          ) : invoiceSummaryData?.date}
        </TopLevelTitle>
      </GarageNameRow>

      {invoiceSummaryLoading ? (
        <SummarySectionSkeleton width="100%" height="310px" variant="rect" />
      ) : (
        <SectionsGrid>
          <SummarySection data-test="invoice-modal-summary">
            <RowWithOffset>
              <MediumLevelTitle>Invoice Summary</MediumLevelTitle>
            </RowWithOffset>
            <RowWithOffset>
              <BigText>
                {formatPrice(invoiceSummaryData!.totalAmount)}
              </BigText>
            </RowWithOffset>
            <TableHiddenScroll>
              {invoiceSummaryLoading ? (
                <Skeleton variant="rect" width={330} height={120} />
              ) : (
                <StyledSimpleTable
                  rowsMap={summaryRowsMap}
                  item={invoiceSummaryData!}
                />
              )}
            </TableHiddenScroll>
          </SummarySection>
          <SummarySection data-test="invoice-modal-next-payment">
            <RowWithOffset>
              <MediumLevelTitle>Next Payment</MediumLevelTitle>
            </RowWithOffset>
            <RowWithOffset>
              <BigText>{invoiceSummaryData!.paymentDetails.date}</BigText>
            </RowWithOffset>
            <TableHiddenScroll>
              <StyledSimpleTable
                rowsMap={billingRowsMap}
                item={invoiceSummaryData!}
              />
            </TableHiddenScroll>
          </SummarySection>
        </SectionsGrid>
      )}

      <RowWithOffset>
        <TopLevelTitle>Details</TopLevelTitle>
      </RowWithOffset>

      {invoiceDetailsLoading ? (<LoadingIndicator />) : (
        <StyledTransactionsTable
          data={invoiceDetailsData!.detailedStats}
          rowsMap={invoiceDetailsRowsMap}
          page={invoiceDetailsPage}
          onPageChange={(e, page) => setInvoiceDetailsPage(page)}
          onRowsPerPageChange={(e) => setInvoiceDetailsRowsPerPage(Number(e.target.value))}
          count={invoiceDetailsData!.entriesCount}
          isLoading={invoiceDetailsFetching}
          minWidth="1080px"
          />
      )}

      <ButtonRow justifyContent="flex-end">
        <Button
            variant="contained"
            color="primary"
            onClick={onClose}
            data-test="invoice-modal-close-btn"
        >
          Close
        </Button>
      </ButtonRow>
    </>
  );
};

const ButtonRow = styled(Row)`
  margin-top: 30px;
`;

const SectionsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-bottom: 30px;

  @media (max-width: 770px) {
    grid-template-columns: 1fr;
  }
`;

const SummarySectionSkeleton = styled(Skeleton)`
  margin-bottom: 30px;
`;

const tableStyles = css`
  & th {
    text-align: left;
    font-weight: 400;
  }

  & td {
    text-align: right;
  }
`;

const StyledSimpleTable = styled(SimpleTable)`
  ${tableStyles}
` as typeof SimpleTable;

const StyledTransactionsTable = styled(TransactionsTable)`` as typeof Table;

export default InvoiceReport;
