import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import assertNever from 'assert-never';
import { useDispatch } from 'react-redux';

import { Button, Input, Link } from '@zero5/ui';
import schemas from '@zero5/ui/lib/utils/validation/schemas';
import { SignInStatus } from '@zero5/ui-utils';

import user from '@/api/user';

import { authActions } from '@/store/actions/auth';

import PasswordInfo from '@/components/PasswordInfo';

import { confirmPasswordSchema } from '@/utils/validation/schemas';
import { handleError } from '@/utils/handleError';
import { withUnauthorized } from '@/utils/hocs/withUnauthorized';

const validationSchema = yup.object({
  email: schemas.emailSchema,
  password: yup
    .string()
    .required('Password is required'),
});

const Login: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation<{ from?: string; }>();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isChallenge, setIsChallenge] = React.useState(false);

  const formikAuthorization = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async ({ email, password }) => {
      try {
        setIsLoading(true);

        const status = await user.signIn({ email, password: password.trim() });
        dispatch(authActions.signIn(status));
        switch (status) {
          case SignInStatus.SUCCESS:
            history.push(location.state?.from || '/');
            break;

          case SignInStatus.CHALLENGE_REQUIRED:
          case SignInStatus.NEW_PASSWORD_REQUIRED:
            setIsChallenge(true);
            setIsLoading(false);
            break;

          default:
            assertNever(status);
        }
      } catch (error) {
        handleError(error, 'Failed to sign in!');
      } finally {
        setIsLoading(false);
      }
    },
  });

  const transformedEmailChangeHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => {
      formikAuthorization.setFieldValue(e.target.name.toLowerCase(), value.replace(' ', ''));
    }, [formikAuthorization],
  );

  const formikChallenge = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: confirmPasswordSchema,
    onSubmit: async ({ newPassword }) => {
      try {
        setIsLoading(true);

        await user.changePassword({
          email: formikAuthorization.values.email,
          oldPassword: formikAuthorization.values.password.trim(),
          password: newPassword.trim(),
        });
        dispatch(authActions.signIn(SignInStatus.SUCCESS));

        history.push(location.state?.from || '/');
      } catch (error) {
        handleError(error, 'Failed to change password!');
      } finally {
        setIsLoading(false);
      }
    },
  });

  if (isChallenge) {
    return (
      <Page>
        <LoginForm onSubmit={formikChallenge.handleSubmit}>
          <MainHeading align="center">
            Change Password
          </MainHeading>
          <StyledInput
            placeholder="New Password"
            name="newPassword"
            type="password"
            onBlur={formikChallenge.handleBlur}
            onChange={formikChallenge.handleChange}
            value={formikChallenge.values.newPassword}
            error={Boolean(formikChallenge.touched.newPassword && formikChallenge.errors.newPassword)}
            helperText={formikChallenge.errors.newPassword}
          />
          <StyledInput
            placeholder="Confirm Password"
            name="confirmPassword"
            type="password"
            onChange={formikChallenge.handleChange}
            value={formikChallenge.values.confirmPassword}
            error={Boolean(formikChallenge.touched.confirmPassword && formikChallenge.errors.confirmPassword)}
            helperText={formikChallenge.errors.confirmPassword}
          />
          <PasswordInfo />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            loading={isLoading}
          >
            Change Password
          </Button>
        </LoginForm>
      </Page>
    );
  }

  return (
    <Page>
      <LoginForm onSubmit={formikAuthorization.handleSubmit}>
        <MainHeading align="center">
          Sign In
        </MainHeading>
        <StyledInput
          placeholder="Email"
          name="email"
          transform="lowercase"
          autoComplete="username"
          onChange={transformedEmailChangeHandler}
          value={formikAuthorization.values.email}
          error={Boolean(formikAuthorization.touched.email && formikAuthorization.errors.email)}
          helperText={formikAuthorization.errors.email}
          data-test="signin_email"
        />
        <StyledInput
          placeholder="Password"
          type="password"
          name="password"
          autoComplete="current-password"
          onChange={formikAuthorization.handleChange}
          value={formikAuthorization.values.password}
          error={Boolean(formikAuthorization.touched.password && formikAuthorization.errors.password)}
          helperText={formikAuthorization.errors.password}
          data-test="signin_password"
        />
        <BottomWrapper>
          <Link to="/reset-password">Forgot password?</Link>
        </BottomWrapper>
        <SignInButton
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          loading={isLoading}
          data-test="signin_button"
        >
          Sign In
        </SignInButton>
        <Typography>
          By signing in, you acknowledge that you have read and
          understood our
          {' '}
          <StyledLink href="https://zero5.co/policies/privacy-policy">privacy notice</StyledLink>
          .
        </Typography>
      </LoginForm>
      <Typography variant="body1">
        Don’t have an account?
        {' '}
        <StyledLink href="mailto:support@zero5.co">Contact us</StyledLink>
      </Typography>
    </Page>
  );
};

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f8f9fa;
`;

const StyledLink = styled.a`
  color:#30cd9a;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }  
`;

const LoginForm = styled.form`
  width: 400px;
  padding: 40px;
  margin-bottom: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 3%);
  background-color: #ffffff;
  border-radius: 4px;
`;

const MainHeading = styled(Typography)`
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 300;
`;

const StyledInput = styled(Input)`
  margin-bottom: 20px;
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
`;

const SignInButton = styled(Button)`
  margin-bottom: 20px;
`;

export default withUnauthorized(Login);
