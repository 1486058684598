/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getVehicleByLpNum } from '@zero5/garage-api';
import { GetVehicleByLpNumResponseType } from '@zero5/garage-api/dist';

type Response = GetVehicleByLpNumResponseType;

export const queryKey: QueryKey = 'vehicleByLpNumQuery';

const useVehicleByLpNumQuery = (
  lpNum: string,
  options?: UseQueryOptions<Response, unknown, Response, [typeof queryKey, string]>,
) => {
  return useQuery<Response, unknown, Response, [typeof queryKey, string]>(
    [queryKey, lpNum],
    async () => {
      const response = await getVehicleByLpNum({ lpNum });
      return response;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useVehicleByLpNumQuery;
