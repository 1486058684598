import styled from 'styled-components';

import { Button, DataCard, SimpleTable } from '@zero5/ui';

export const Span = styled.span<{ color: string; }>`
  color: ${({ color }) => color};
`;

export const StyledDataCard = styled(DataCard)`
  padding-bottom: 0;
`;

export const CardContent = styled.div``;

export const StyledSimpleTable = styled(SimpleTable)`
  width: 100%;

  th {
    width: 50%;
    font-weight: 400;
  }
  tr:not(:last-child) {
    td, th {
      padding-bottom: 12px;
    }
  }
  td {
    font-size: 14px;
    font-weight: 300;
  }
` as typeof SimpleTable;

export const ApplyButton = styled(Button)`
  margin: 15px 0 15px auto;
  width: 100px;
  display: block;
  text-transform: none;
  &:disabled {
    background-color: #E7E5E5;
    color: white;
  }
`;