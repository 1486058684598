/* eslint-disable max-len */
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getTenantTypeList } from '@zero5/user-api';

import { mapTypes } from '../mappings/user';

type Response = ReturnType<typeof mapTypes>;

const queryKey: QueryKey = 'tenantTypesQuery';

const useTenantTypeListQuery = (
  options?: UseQueryOptions <Response, unknown, Response, typeof queryKey>,
) => {
  return useQuery<Response, unknown, Response, typeof queryKey>(
    queryKey,
    async () => {
      const response = await getTenantTypeList();
      return mapTypes(response.types);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      ...options,
    },
  );
};

export default useTenantTypeListQuery;
