import { useMutation, UseMutationOptions } from 'react-query';

import { updateGarageOptions, UpdateGarageOptionsArgs } from '@zero5/garage-api';

type Response = unknown;

const useUpdateGarageOptionsMutation = (
  options?: UseMutationOptions<Response, Error, UpdateGarageOptionsArgs, string>,
) => {
  return useMutation(
    'updateGarageOptions',
    async (params) => {
      const response = await updateGarageOptions(params);
      return response;
    },
    options,
  );
};

export default useUpdateGarageOptionsMutation;
