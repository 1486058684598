/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  AudioVideoObserver,
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
} from 'amazon-chime-sdk-js';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';

import {
  Buttons, Label, Image, Button, Input, ConfirmationModal,
} from '@zero5/ui';
import useToggle from '@zero5/ui/lib/utils/hooks/useToggle';
import {
  createEntryActivity,
  openGate,
  PaymentFeatures,
  PaymentFeatureType,
  updateEntryForActivity,
  updateExitActivity,
} from '@zero5/client-admin-api';
import toast from '@zero5/ui/lib/utils/toast';
import { getSuggestedEntryActivitiesByLicensePlate } from '@zero5/activity-api';

import { NonNullableObject, Optional } from '@/utils/types';
import { handleError } from '@/utils/handleError';

import { CurrentCall } from '@controlCenter/store/reducers/call';
import { callActions, endCall, handleEndedCall } from '@controlCenter/store/actions/call';
import { selectIsEnding } from '@controlCenter/store/selectors/call';
import GarageTitle from '@controlCenter/components/common/GarageTitle';
import { Activity } from '@/controlCenter/api/activity/models';
import {
  extractSuggestedActivities,
  hasPaymentCalculationResult,
  hasSuggestedActivities,
} from '@/controlCenter/utils/callData';
import { mapCallData } from '@/controlCenter/api/call/mappings';
import {
  SMALL_TABLET_MEDIA_QUERY,
  SMALL_TABLET_ONLY_MEDIA_QUERY,
  TABLET_ONLY_MEDIA_QUERY,
  TABLET_SIZE_PX,
} from '@/controlCenter/utils/styles/media';

import Timer from './Timer';
import SuggestedActivities from './SuggestedActivities';
import ActivityAccessInfo from './ActivityAccessInfo';
import ActivityPaymentInfo from './ActivityPaymentInfo';

interface Props {
  call: CurrentCall;
}

type DiscountOption = {
  label: string;
  value: number | null;
};

const discountOptions: Array<DiscountOption> = [{
  label: 'No discount',
  value: null,
}];

for (let i = 0.5; i <= 12 ; i += 0.5) {
  const hours = `${i} hr`;
  discountOptions.push({
    label: `${hours}`,
    value: i * 60,
  });
}

const Call: React.FC<Props> = ({ call }) => {
  const dispatch = useDispatch();
  const isImageFullWidth = useMediaQuery('(max-width: 760px)');

  const videoOutputElement = React.useRef<HTMLVideoElement>(null);
  const audioOutputElement = React.useRef<HTMLAudioElement>(null);
  const meetingSessionRef = React.useRef<DefaultMeetingSession>();

  const [isActivityUpdating, setIsActivityUpdating] = useState(false);
  const [isGateOpening, setIsGateOpening] = useState(false);

  const [isConfirmOpenGateModalOpened, closeConfirmOpenGateModal, openConfirmOpenGateModal] = useToggle(false);
  const [isConfirmEndCallModalOpened, closeConfirmEndCallModal, openConfirmEndCallModal] = useToggle(false);
  const [showWebCam, , , toggleShowWebCam] = useToggle(true);

  const entryActivity = call.incomingCall.callData?.payload.activity.entryActivity;
  const exitActivity = call.incomingCall.callData?.payload.activity.exitActivity;

  const [imageType, setImageType] = useState(exitActivity?.frontImg ? 'front' : 'rear');

  const isEnding = useSelector(selectIsEnding);

  const endCallHandler = React.useCallback(() => {
    dispatch(endCall(call.incomingCall.requestId));
  }, [call.incomingCall.requestId, dispatch]);

  React.useEffect(() => {
    const start = async () => {
      try {
        const logger = new ConsoleLogger('MyLogger', LogLevel.ERROR);
        const deviceController = new DefaultDeviceController(logger);

        const configuration = new MeetingSessionConfiguration(
          {
            Meeting: call.meetingCredentials.meeting,
          },
          {
            Attendee: call.meetingCredentials.attendee,
          },
        );

        const meetingSession = new DefaultMeetingSession(
          configuration,
          logger,
          deviceController,
        );
        meetingSessionRef.current = meetingSession;

        const audioInputs = await meetingSession.audioVideo.listAudioInputDevices();

        await meetingSession.audioVideo.chooseAudioInputDevice(
          audioInputs[0].deviceId,
        );

        const observer: AudioVideoObserver = {
          videoTileDidUpdate: (tileState) => {
            if (
              !tileState.boundAttendeeId
              || tileState.localTile
              || tileState.isContent
              || !tileState.tileId
              || !videoOutputElement.current
            ) {
              return;
            }

            meetingSession.audioVideo.bindVideoElement(
              tileState.tileId,
              videoOutputElement.current,
            );
          },
        };

        if (audioOutputElement.current) {
          meetingSession.audioVideo.bindAudioElement(
            audioOutputElement.current,
          );
        }

        meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(
          (_presentAttendeeId, present) => {
            if (!present) {
              dispatch(handleEndedCall());
            }
          },
        );

        meetingSession.audioVideo.addObserver(observer);
        meetingSession.audioVideo.start();
      } catch (error) {
        console.error(error);
      }
    };

    start();

    return () => {
      meetingSessionRef.current?.audioVideo.stop();
    };
  }, [call, dispatch]);

  React.useEffect(() => {
    const confirmFunction = () => '';

    window.onbeforeunload = confirmFunction;

    return () => {
      window.window.onbeforeunload = null;
    };
  }, []);

  const onAccessInfoChange = async ({ exitLp }: { exitLp?: string | null; }) => {
    if (!exitActivity?.activityId || !exitLp) return;

    setIsActivityUpdating(true);

    try {
      const updatedActivity = await updateExitActivity({
        activityId: exitActivity?.activityId,
        data: { mainLp: exitLp },
      });

      if (updatedActivity.activity.entryActivity) {
        dispatch(callActions.updateCurrentCallActivity(updatedActivity.activity));
      } else {
        const suggestedActivities = await getSuggestedEntryActivitiesByLicensePlate({ licensePlate: exitLp });

        dispatch(callActions.updateCurrentCallActivity(updatedActivity.activity));
        dispatch(
          callActions.updateCurrentCallSuggestedActivities(
            suggestedActivities.activities.map(({ data }) => data),
          ),
        );
      }
    } catch (error) {
      toast('error', error instanceof Error ? error.message : 'Something went wrong');
    }

    setIsActivityUpdating(false);

  };

  const onPaymentInfoChange = async ({
    entryDate,
    discount,
    fee,
    afterSuccessfullyUpdate,
  }: {
    entryDate: Date | null;
    fee: number | null;
    discount: number | null;
    afterSuccessfullyUpdate: () => void;
  }) => {
    if (!exitActivity) return;

    setIsActivityUpdating(true);

    try {
      let createdActivity;

      if (!entryActivity && entryDate) {
        const newActivity = Object.entries(exitActivity).reduce((acc, pair) => {
          if (pair[1] !== null && pair[1] !== undefined) {
            acc[pair[0]] = pair[1];
          }
          return acc;
        }, {} as Record<string, unknown>) as Optional<NonNullableObject<Activity>>;

        const newEntryTimestamp = entryDate?.getTime();
        const idParts: Array<string | number> = exitActivity.activityId.split('-');
        idParts[idParts.length - 1] = new Date().getTime();
        const newActivityId = idParts.join('-');

        createdActivity = await createEntryActivity({
          ...newActivity,
          garageId: exitActivity.garageId,
          deviceId: exitActivity.deviceId,
          status: exitActivity.status,
          eventTime: newEntryTimestamp,
          activityId: newActivityId,
        });
      }

      const entryActivityForUpdate = createdActivity?.entryActivity.data || entryActivity;

      if (!entryActivityForUpdate) {
        toast('error', 'Please, select an entry time!');
      } else if (call.incomingCall.deviceId) {
        let paymentFeatures: PaymentFeatures | undefined;

        if (fee) {
          paymentFeatures = {
            type: PaymentFeatureType.SpecialPrice,
            priceCents: fee * 100,
          };
        } else if (discount) {
          paymentFeatures = {
            type: PaymentFeatureType.DiscountMinutes,
            minutes: discount,
          };
        }

        const updatedActivity = await updateEntryForActivity({
          deviceId: call.incomingCall.deviceId,
          exitActivityId: exitActivity.activityId,
          entryActivityId: entryActivityForUpdate.activityId,
          tickets: hasPaymentCalculationResult(call.incomingCall.callData)
            ? call.incomingCall.callData.payload.paymentCalculationResult.tickets || undefined
            : undefined,
          paymentFeatures,
        });

        dispatch(callActions.updateCurrentCallCallData(mapCallData(updatedActivity)));
        afterSuccessfullyUpdate();
        toast('success', 'Entry activity was updated!');
      }
    } catch (error) {
      toast('error', error instanceof Error ? error.message : 'Something went wrong');
    }
    setIsActivityUpdating(false);
  };

  const onOpenGate = async () => {
    if (!call.incomingCall.deviceId) return;
    setIsGateOpening(true);

    try {
      await openGate({ deviceId: call.incomingCall.deviceId });

      toast('success', 'Gate opened!');
    } catch (error) {
      handleError(error);
    } finally {
      setIsGateOpening(false);
      closeConfirmOpenGateModal();
    }
  };

  const onSelectSuggestedActivity = async (newEntry: Activity) => {
    if (call.incomingCall.deviceId && exitActivity && newEntry) {
      setIsActivityUpdating(true);

      try {
        const updatedActivity = await updateEntryForActivity({
          deviceId: call.incomingCall.deviceId,
          exitActivityId: exitActivity?.activityId,
          entryActivityId: newEntry.activityId,
        });

        dispatch(callActions.updateCurrentCallCallData(mapCallData(updatedActivity)));

        toast('success', 'Entry activity was updated!');
      } catch (error) {
        handleError(error);
      } finally {
        setIsActivityUpdating(false);
      }
    }
  };

  // @ts-ignore
  return (
    <>
      <Wrapper>
        <Titles>
          <GarageTitle
            name="Location"
            value={call.incomingCall.deviceId.split('-')[1]?.toUpperCase() || ''}
          />
          <GarageTitle
            name="ACTIVITY ID"
            value={exitActivity?.activityId || ''}
          />
        </Titles>
        <Content thirdColumn={Boolean(extractSuggestedActivities(call.incomingCall.callData)?.length)}>
          <Gate>
            <VisualContentWrapper>
              <ImgControls>
                <Button variant="text" color="primary" onClick={toggleShowWebCam}>
                  {showWebCam ? 'Activity photos' : 'Webcam View'}
                </Button>
                <Button variant="contained" color="primary" disabled>Take a Pic</Button>
              </ImgControls>
              <audio ref={audioOutputElement} />
              <VideoWrapper show={showWebCam}>
                <StyledVideo ref={videoOutputElement}/>
              </VideoWrapper>
              <StyledButtons
                show={!showWebCam}
                onBack={() => setImageType('front')}
                onForward={() => setImageType('rear')}
                disableBack={imageType === 'front' || Boolean(exitActivity?.frontImg)}
                disableForward={imageType === 'rear' || Boolean(exitActivity?.rearImg)}
              >
                <Label text="Exit_Front">
                  <Image
                    width={isImageFullWidth ? '100%' : 405}
                    height={245}
                    src={imageType === 'front'
                      ? exitActivity?.frontImg || ''
                      : exitActivity?.rearImg || ''
                  } />
                </Label>
              </StyledButtons>
              <OpenButton
                variant="contained"
                color="secondary"
                fullWidth
                onClick={openConfirmOpenGateModal}
                loading={isGateOpening}
              >
                Open Gate
              </OpenButton>
            </VisualContentWrapper>
            <NoteWrapper>
              <AddNote>ADD NOTE</AddNote>
              <Note inputProps={{ style: { height: 100 } }} multiline={true} disabled />
              <ApplyButton loading={isActivityUpdating} variant="contained" color="primary" disabled>
                Add Note
              </ApplyButton>
            </NoteWrapper>
          </Gate>
          <Information>
            <StyledActivityAccessInfo
              info={{
                entryActivity,
                exitActivity,
              }}
              activityUpdating={isActivityUpdating}
              onApply={onAccessInfoChange}
              columnFrom={TABLET_SIZE_PX}
            />
            <StyledActivityPaymentInfo
              info={{
                entryActivity,
                exitActivity,
              }}
              activityUpdating={isActivityUpdating}
              fee={
                hasPaymentCalculationResult(call.incomingCall.callData)
                  ? call.incomingCall.callData.payload.paymentCalculationResult.fee
                  : undefined
              }
              paymentFeatures={
                hasPaymentCalculationResult(call.incomingCall.callData)
                  ? call.incomingCall.callData?.payload.paymentCalculationResult.paymentFeatures
                  : null
              }
              onApply={onPaymentInfoChange}
              columnFrom={TABLET_SIZE_PX}
            />
          </Information>
          {hasSuggestedActivities(call.incomingCall.callData) && (
            <StyledSuggestedActivities
              activities={call.incomingCall.callData.payload.suggestedEntryActivities}
              entryActivityId={entryActivity?.activityId}
              activityUpdating={isActivityUpdating}
              onSelectActivity={(activity) => onSelectSuggestedActivity(activity)}
            />
          )}
        </Content>
      </Wrapper>
      <CallControl>
        <Timer />
        <CallControlText>You are on a call</CallControlText>
        <DangerButton
          color="secondary"
          variant="contained"
          onClick={openConfirmEndCallModal}
          disabled={isEnding}
        >
          End call
        </DangerButton>
      </CallControl>
      <ConfirmationModal
        style={{ zIndex: 1600 }}
        open={isConfirmOpenGateModalOpened}
        onAccept={onOpenGate}
        onReject={closeConfirmOpenGateModal}
        text="Do you want to open the gate?"
        acceptText="Yes"
        rejectText="No"
        loading={isGateOpening}
      />
        <ConfirmationModal
        style={{ zIndex: 1500 }}
        open={isConfirmEndCallModalOpened}
        onAccept={endCallHandler}
        onReject={closeConfirmEndCallModal}
        text={(
          <div>
            Does the driver require further assistance with validation or parking fee update?
            <br/>If not, press YES to end the call.
            <br/>Press NO to further assist the driver.
          </div>)
        }
        align="center"
        acceptText="Yes"
        rejectText="No"
        loading={isEnding}
      />
    </>
  );
};

const Wrapper = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const Titles = styled.div`
  @media (max-width: 1150px) {
    margin-top: 45px;
  }
`;

const Content = styled.div<{ thirdColumn?: boolean; }>`
  display: grid;
  grid-template-columns: 405px 450px ${({ thirdColumn }) => thirdColumn ? '435px' : ''};
  grid-gap: 15px;
  flex-grow: 1;
  margin-top: 10px;

  @media ${TABLET_ONLY_MEDIA_QUERY} {
    grid-template-columns: 405px 1fr;
    grid-template-rows: min-content 260px;
  }

  @media ${SMALL_TABLET_MEDIA_QUERY} {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
`;

const Gate = styled.div`
  @media ${TABLET_ONLY_MEDIA_QUERY} {
    grid-row: 1 / 3;
  }

  @media ${SMALL_TABLET_ONLY_MEDIA_QUERY} {
    display: flex;
    gap: 20px;
  }
`;

const VisualContentWrapper = styled.div`
  @media ${SMALL_TABLET_ONLY_MEDIA_QUERY} {
    width: calc(50% - 10px);
  }
`;

const NoteWrapper = styled.div`
  margin-top: 30px;

  @media ${SMALL_TABLET_ONLY_MEDIA_QUERY} {
    align-self: center;
    margin-top: 0;
    width: calc(50% - 10px);
  }
`;

const VideoWrapper = styled.div<{ show: boolean; }>`
  width: 100%;
  height: 245px;
  background: black;
  ${({ show }) => show ? '' : 'display: none;'}
`;

const StyledButtons = styled(Buttons)<{ show: boolean; }>`
  ${({ show }) => show ? '' : 'display: none;'}
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
`;

const Information = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;

  @media ${TABLET_ONLY_MEDIA_QUERY} {
    flex-direction: row;
    grid-row: 1 / 2;
  }

  @media ${SMALL_TABLET_ONLY_MEDIA_QUERY} {
    flex-direction: row;
  }
`;

const StyledActivityAccessInfo = styled(ActivityAccessInfo)`
  @media ${TABLET_ONLY_MEDIA_QUERY} {
    padding: 15px 15px 0 15px;
    width: calc(50% - 5px);
  }

  @media ${SMALL_TABLET_ONLY_MEDIA_QUERY} {
    width: calc(50% - 5px);
  }
`;

const StyledActivityPaymentInfo = styled(ActivityPaymentInfo)`
  @media ${TABLET_ONLY_MEDIA_QUERY} {
    padding: 15px 15px 0 15px;
    width: calc(50% - 5px);
  }

  @media ${SMALL_TABLET_ONLY_MEDIA_QUERY} {
    width: calc(50% - 5px);
  }
`;

const StyledSuggestedActivities = styled(SuggestedActivities)`
  @media ${TABLET_ONLY_MEDIA_QUERY} {
    grid-row: 2 / 3;
  }

  @media (min-width: 850px) and ${SMALL_TABLET_MEDIA_QUERY} {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 20px;

    & > * {
      width: calc(50% - 10px);
      flex-shrink: 0;
    }
  }
`;

const ApplyButton = styled(Button)`
  margin: 15px 0 0 auto;
  width: 100px;
  display: block;
  text-transform: none;
  &:disabled {
    background-color: #E7E5E5;
    color: white;
  }
`;

const CallControl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  padding: 10px 25px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  background-color: #30CD9A;
  color: #ffffff;
`;

const CallControlText = styled.div`
  
`;

const DangerButton = styled(Button)`
  background-color: rgba(255,0,0,0.69);
  &:hover, &:focus {
    background-color: rgba(255,0,0,1);
  }
`;

const OpenButton = styled(DangerButton)`
  margin-top: 30px;

  @media ${SMALL_TABLET_MEDIA_QUERY} {
    margin-top: 15px;
  }
`;

const AddNote = styled.div`
  margin-bottom: 13px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
`;

const Note = styled(Input)`

`;

const ImgControls = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px;
  & button {
    text-transform: none;
  }
`;
export default Call;
