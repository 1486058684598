import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { deleteSystemUser } from '@zero5/user-api';

import { mapUser } from '@/api/mappings/user';
import { User } from '@/api/models/users';
import { queryKey as companyUsersQueryKey } from '@/api/queries/useCompanyUsersQuery';

type Response = User;

const useDeleteUserMutation = (
  options?: UseMutationOptions<Response, Error, {
    userId: number;
  }, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'deleteUser',
    async ({ userId }) => {
      const response = await deleteSystemUser(userId);
      return mapUser(response.user);
    },
    {
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries(companyUsersQueryKey);

        options?.onSuccess?.(...params);
      },
    },
  );
};

export default useDeleteUserMutation;
