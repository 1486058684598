import { useMutation, UseMutationOptions } from 'react-query';

import { updateValidationType, UpdateValidationTypeArgs, ValidationType } from '@zero5/garage-api';

import { handleError } from '@/utils/handleError';
import queryClient from '@/utils/queryClient';

import { queryKey as validationTypeQuery } from './useGetValidationTypesQuery';

type Response = ValidationType;

type Args = UpdateValidationTypeArgs & {
  id: number;
};

const queryKey = 'updateValidationType';

const useUpdateValidationTypeMutation = (
  options?: UseMutationOptions<Response, Error, Args, string>,
) => {

  return useMutation(
    queryKey,
    async (params) => {
      const { id, ...updatingFields } = params;
      const response = await updateValidationType( id, updatingFields);

      return response.validationType;
    },
    {
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries(validationTypeQuery);

        options?.onSuccess?.(...params);
      },
      onError: (error) => handleError(error),
    },
  );
};

export default useUpdateValidationTypeMutation;
