import { z5DateModule } from '@zero5/time-module';

import { Garage } from '@/api/models/garages';

import store from '@/store';
import { workspaceActions } from '@/store/actions/workspace';

export const setGarage = (garage: Garage) => {
  z5DateModule.setTimezone(garage.timezone);
  store.dispatch(workspaceActions.setCurrentGarage(garage));
};
