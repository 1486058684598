import { EventParkingFeeType } from '@/api/models/garages';

export const feeOptions = [
  {
    value: EventParkingFeeType.HOURLY_RATE,
    label: 'Hourly Rate',
  },
  {
    value: EventParkingFeeType.FLAT_FEE,
    label: 'Flat Fee',
  },
];

export const hoursOptions = [
  {
    value: '0.5',
    label: '0.5 hour',
  },
  {
    value: '1',
    label: '1 hour',
  },
  {
    value: '1.5',
    label: '1.5 hour',
  },
  {
    value: '2',
    label: '2 hours',
  },
  {
    value: '2.5',
    label: '2.5 hours',
  },
  {
    value: '3',
    label: '3 hours',
  },
  {
    value: '3.5',
    label: '3.5 hours',
  },
  {
    value: '4',
    label: '4 hours',
  },
];

export const GracePeriodOptions = [
  // TODO:
  {
    value: 30,
    label: '30 min',
  },
  // {
  //   value: 30,
  //   label: '30 min',
  // },
  // {
  //   value: 60,
  //   label: '1 hour',
  // },
  // {
  //   value: 180,
  //   label: '3 hours',
  // },
  // {
  //   value: 300,
  //   label: '5 hours',
  // },
  // {
  //   value: 420,
  //   label: '7 hours',
  // },    
];
